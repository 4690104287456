function calendarController(
  $state,
  $scope,
  WS,
  $window,
  AppSettings,
  $compile,
  $uibModal
) {
  "ngInject";
  const vm = this;
  //uiCalendarConfig
  vm.echeances = [];
  vm.evenements = [];
  $(function() {
         // console.log($("#containercal"));
      //$('#containercal').fullCalendar('destroy');
      $("#containercal").fullCalendar({
        titleRangeSeparator: " \u2013 ",
        monthYearFormat: "MMMM YYYY",
        defaultTimedEventDuration: "02:00:00",
        defaultAllDayEventDuration: { days: 1 },
        forceEventDuration: false,
        nextDayThreshold: "09:00:00",
        columnHeader: true,
        defaultView: "month",
        aspectRatio: 2,
        // header: {
        //   left: 'title',
        //   center: '',
        //   right: 'today prev,next'
        // },
        header: {
          left: "prev,next today",
          center: "title",
          right: "month,agendaWeek,agendaDay,listWeek",
        },
        weekends: true,
        weekNumbers: true,
        weekNumberTitle: "W",
        weekNumberCalculation: "local",
        scrollTime: "06:00:00",
        minTime: "00:00:00",
        maxTime: "24:00:00",
        showNonCurrentDates: true,
        lazyFetching: true,
        startParam: "start",
        endParam: "end",
        timezoneParam: "timezone",
        timezone: false,
        locale: null,
        isRTL: false,
        buttonText: {
          prev: "prev",
          next: "next",
          prevYear: "prev year",
          nextYear: "next year",
          year: "year",
          today: "today",
          month: "month",
          week: "week",
          day: "day",
        },
        allDayText: "all-day",
        agendaEventMinHeight: 0,
        theme: true,
        dragOpacity: 0.75,
        dragRevertDuration: 500,
        dragScroll: true,
        unselectAuto: true,
        dropAccept: "*",
        eventOrder: "title",
        eventLimitText: "more",
        eventLimitClick: "popover",
        dayPopoverFormat: "LL",
        handleWindowResize: true,
        windowResizeDelay: 100,
        longPressDelay: 1000,

        defaultDate: moment().format('YYYY-MM-DD'),
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        eventLimit: true, // allow "more" link when too many events
        // events: [
        //   {
        //     title: "All Day Event",
        //     start: "2021-07-20",
        //     color: 'red'
        //   },
        //   {
        //     title: "Long Event",
        //     start: "2021-07-07",
        //     end: "2021-07-10",
        //     color: 'green'
        //   },
        //   {
        //     id: 999,
        //     title: "Repeating Event",
        //     start: "2021-07-09T16:00:00",
        //     color: 'purple'
        //   },
        //   {
        //     id: 999,
        //     title: "Repeating Event",
        //     start: "2021-07-16T16:00:00",
        //   },
        //   {
        //     title: "Conference",
        //     start: "2021-07-11",
        //     end: "2021-07-13",
        //   },
        //   {
        //     title: "Meeting",
        //     start: "2021-07-12T10:30:00",
        //     end: "2021-07-12T12:30:00",
        //   },
        //   {
        //     title: "Lunch",
        //     start: "2021-07-12T12:00:00",
        //   },
        //   {
        //     title: "Meeting",
        //     start: "2021-07-12T14:30:00",
        //   },
        //   {
        //     title: "Happy Hour",
        //     start: "2021-07-12T17:30:00",
        //   },
        //   {
        //     title: "Dinner",
        //     start: "2021-07-12T20:00:00",
        //   },
        //   {
        //     title: "Birthday Party",
        //     start: "2021-07-13T07:00:00",
        //   },
        //   {
        //     title: "Click for Google",
        //     url: "http://google.com/",
        //     start: "2021-07-28",
        //   }
        //   ,
        //   {
        //     googleCalendarId: 'fr.tn#holiday@group.v.calendar.google.com',
        //     className: 'gcal-event' // an option!
        //   }
        // ]
        // ,
        viewRender: function(view, element) {

          var date_debut = $('#containercal').fullCalendar('getView').start.format('YYYY-MM-DD')
          var date_fin = $('#containercal').fullCalendar('getView').end.format('YYYY-MM-DD')
          //console.log('start end', date_debut, date_fin)
          vm.getAllInfoCalendar(date_debut, date_fin); 
        },
        eventRender: function(event, eventElement) {
            if (event.imageurl) {
                eventElement.find("div.fc-content").prepend("<img src='" + event.imageurl +"' width='40' height='40'>");
            }
        },
        eventClick: function(info) {
          console.log(info)
          vm.details_event = $uibModal.open({
            animation: false,
            templateUrl: 'panelevent',
            size: 'md',
            backdrop: true,
            controller: function($scope, $translate) {
                $scope.title = info.title;
                $scope.imageurl = info.imageurl;
                $scope.details_event = info.details;
                $scope.closeEvent = () => {
                  vm.details_event.close()
                }
            }
        });

        },
        googleCalendarApiKey: 'AIzaSyDfElHIKOYUmsXnoHFSfVxiI01n60A7Tcg',
       events: 'fr.tn#holiday@group.v.calendar.google.com'
       

      });

      
      
  });

  vm.getAllInfoCalendar = (date_debut, date_fin) => {
    WS.getData('calendar/getAllInfoCalendar', {date_debut, date_fin}).then(
        function (response) {
          vm.echeances = response.data.echeances;
          vm.evenements = response.data.evenements;
          console.log('echeance', vm.echeances);
          console.log('evenements', vm.evenements);
            $(function() {
              $('#containercal').fullCalendar('removeEvents');

              _.each(vm.echeances, (element, index) => {
                  var obj = {
                    id: element.id,
                    title: element.commercial +' -> '+ element.client+ ' ('+element.cheque+')',
                    start: element.date_echeance_cheque,
                    color: 'green',
                    details : {
                      commercial : element.commercial,
                      client : element.client,
                      cheque : element.cheque,
                      date_echeance_cheque : element.date_echeance_cheque,
                      imageurl:element.path,
                      date : element.date,
                      evenement : null
                    }
                  };
                //  $('#containercal').fullCalendar('removeEvents', obj.id);
                  $('#containercal').fullCalendar('renderEvent', obj);
                })



                _.each(vm.evenements, (element, index) => {
                  var obj = {
                    id: element.id,
                    title: element.commercial +' -> '+ element.client+ ' ('+element.time+')',
                    start:  new Date(element.date+' '+element.time),
                    color: 'red',
                    imageurl:element.path,
                    details : {
                      commercial : element.commercial,
                      client : element.client,
                      cheque : element.cheque,
                      date_echeance_cheque : element.date_echeance_cheque,
                      imageurl:element.path,
                      date : element.date,
                      time : element.time,
                      evenement : element.evenement
                    }
                  };
                //  $('#containercal').fullCalendar('removeEvents', obj.id);
                  $('#containercal').fullCalendar('renderEvent', obj);
                })
              
            });
        },
        function (error) {
            console.log(error);
        }
    );

  };


 
}
export default {
  name: "calendarController",
  fn: calendarController,
};
