function gestion_cmdController($log, $uibModal, $scope, WS, $window, $translate, $compile) {
    'ngInject';
    const vm = this;
    vm.languageKey = $translate.proposedLanguage() || $translate.use();
    console.log('vm.languageKey', vm.languageKey);
    vm.newbl = {};
    vm.current_date = moment().format('DD/MM/YYYY');
    vm.current_datetime = moment().format('YYYYMMDDhmmss');

    vm.date = moment().format('YYYY-MM-DD');

    vm.models = {
        selected: null,
        lists: { "cmd": [] },
        current_day: {}
    };



    vm.cmd_dep = [];
    vm.getCommandes = function () {
        vm.commandes = {};
        vm.models = {
            selected: null,
            lists: { "cmd": [] },
            current_day: {}
        };
        WS.getData('gestion_commandes', { date: vm.date })
            .then(function (response) {
                if (response.status == 200) {

                    vm.commandes = response.data;
                    vm.selectusers = response.data.users;
                    //console.log(vm.commandes);
                    vm.total_montant = 0;
                    vm.total_carton = 0;
                    _.each(vm.commandes.cmd, (element, index) => {
                        vm.models.lists["cmd"].push(element);
                        vm.total_montant += element.montant;
                        vm.total_carton += element.nbr_carton;
                    });
                    vm.sortie_camion = [];
                    _.each(response.data.sortie_camion, (element, index) => {
                        vm.sortie_camion[element.depot_code] = element;
                    });

                    console.log('init', vm.models.lists["cmd"]);
                    $scope.$watch('vm.models.lists["cmd"]', function (newModel, oldModel) {

                        vm.total_montant =  _.reduce(newModel, function(memo, num){ return memo + num.montant; }, 0);
                        vm.total_carton =  _.reduce(newModel, function(memo, num){ return memo + num.nbr_carton; }, 0);
                        
                        if (newModel.length > oldModel.length) {

                             //console.log(oldModel,newModel);
                            var value = newModel.filter(item1 =>
                                !oldModel.some(item2 => (item2.id === item1.id && item2.name === item1.name)))
                            if (value.length > 0) {
                                vm.updateDepotCommande(value[0], 'cmd');
                            }
                            //console.log(value[0]);
                        }
                    }, true);
                    /**
                     * initialise getdepots
                     */
                    vm.getDepot();
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getDepot = function () {
        WS.getData('gestion_commandes/selectDepotWithUser')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectdepots = response.data;
                    _.each(response.data, (element, index) => {

                        vm.models.lists[element.code] = [];
                        vm.models.current_day[element.code] = [];

                        vm.carton = {};
                        vm.valeur = {};
                        vm.sum_qte = {};
                        vm.count_cmd = {};
                        vm.sum_poids = {};

                        vm.sum_qte_day = {};
                        vm.carton_day = {};
                        vm.valeur_day = {};
                        vm.count_cmd_day = {};
                        vm.countPreparation = {};
                        vm.sum_poids_day = {};

                        $scope.$watch('vm.models.lists["' + element.code + '"]', function (newModel, oldModel) {
                            //console.log('oldModel', oldModel);
                            //console.log('newModel', newModel);
                            if (newModel != undefined && oldModel != undefined) {

                                if (newModel.length > 0 && oldModel.length > newModel.length) {
                                    WS.post('gestion_commandes/update_order', { model: newModel })
                                        .then(function (response) {
                                            console.log('new ordre : ', response.data.tabs);
                                        })
                                        .then(null, function (error) {
                                            console.log(error);
                                        });
                                }
                                if (newModel.length > oldModel.length) {
                                    var value = newModel.filter(item1 =>
                                        !oldModel.some(item2 => (item2.id === item1.id && item2.name === item1.name)))

                                    if (value.length > 0) {
                                        vm.updateDepotCommande(value[0], element.code);
                                        vm.sum_qte[element.code] += value[0].sum_qte;
                                        vm.sum_poids[element.code] += value[0].sum_poids;
                                        vm.carton[element.code] += value[0].nbr_carton;
                                        vm.valeur[element.code] += value[0].montant;
                                        vm.count_cmd[element.code]++;
                                        vm.recalculatePoidsByUnite(element.code);
                                        //update preparation :
                                        vm.countPreparation[element.code] = 0;
                                        _.each(vm.models.lists[element.code], (item, ind_item) => {
                                            if (value[0].num_cmd == item.num_cmd) {
                                                vm.models.lists[element.code][ind_item].status = 'Preparation';
                                            }
                                            if (item.status == 'Preparation') {
                                                vm.countPreparation[element.code]++;
                                            }
                                        });

                                    }
                                }

                                if (newModel.length < oldModel.length) {
                                    var value = oldModel.filter(item1 =>
                                        !newModel.some(item2 => (item2.id === item1.id && item2.name === item1.name)))
                                    if (value[0]) {
                                        vm.sum_qte[element.code] += - value[0].sum_qte;
                                        vm.sum_poids[element.code] += - value[0].sum_poids;
                                        vm.carton[element.code] = vm.carton[element.code] - value[0].nbr_carton;
                                        vm.valeur[element.code] = vm.valeur[element.code] - value[0].montant;
                                        vm.count_cmd[element.code] = vm.count_cmd[element.code] - 1;
                                        vm.recalculatePoidsByUnite(element.code);
                                    }
                                }
                            }
                        }, true);
                    });

                    _.each(vm.selectdepots, (depot, ind) => {

                        vm.sum_qte[depot.code] = 0;
                        vm.sum_poids[depot.code] = 0;
                        vm.carton[depot.code] = 0;
                        vm.valeur[depot.code] = 0;
                        vm.count_cmd[depot.code] = 0;

                        vm.sum_qte_day[depot.code] = 0;
                        vm.sum_poids_day[depot.code] = 0;
                        vm.carton_day[depot.code] = 0;
                        vm.valeur_day[depot.code] = 0;
                        vm.count_cmd_day[depot.code] = 0;
                        vm.countPreparation[depot.code] = 0;

                        _.each(vm.commandes.cmd_current_day, (element, index) => {

                            if (depot.code == element.depot_code) {
                                // console.log(depot.code, element)
                                vm.models.current_day[depot.code].push(element);
                                vm.sum_qte_day[depot.code] += element.sum_qte;
                                vm.sum_poids_day[depot.code] += element.sum_poids;
                                vm.carton_day[depot.code] += element.nbr_carton;
                                vm.valeur_day[depot.code] += element.montant;
                                vm.count_cmd_day[depot.code]++;
                                vm.recalculatePoidsDayByUnite(depot.code);
                            }
                        });


                        _.each(vm.commandes.cmd_depots, (element, index) => {
                            //console.log(element)

                            if (depot.code == element.depot_code) {
                                vm.models.lists[depot.code].push(element);

                                vm.sum_qte[depot.code] += element.sum_qte;
                                vm.sum_poids[depot.code] += element.sum_poids;
                                vm.carton[depot.code] += element.nbr_carton;
                                vm.valeur[depot.code] += element.montant;
                                vm.count_cmd[depot.code]++;
                                vm.recalculatePoidsByUnite(depot.code);
                            }
                        });

                        _.each(vm.models.lists[depot.code], (item, ind_item) => {
                            if (item.status == 'Preparation') {
                                vm.countPreparation[depot.code]++;
                            }
                        });
                        //orderby ordre 
                        vm.models.lists[depot.code] = _.sortBy(vm.models.lists[depot.code], 'ordre');
                        vm.models.current_day[depot.code] = _.sortBy(vm.models.current_day[depot.code], 'ordre');
                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateDepotCommande = function (cmd, depot_code) {
        //console.log('updateDepotCommande', cmd, depot_code);

        var data = { cmd, depot_code };

        WS.post('gestion_commandes/update_depot', data)
            .then(function (response) {

            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectCommandesByDepot = function (depot_code) {
        vm.selected_depot_code = depot_code;
        vm.listOfCmd = _.pluck(vm.models.lists[depot_code], 'num_bl');

        var data = {
            depot_code: depot_code,
            cmds: vm.listOfCmd
        };
        WS.post('gestion_commandes/selectCommandesByDepot', data).then(
            function (response) {
                vm.selected_cmd_by_depot = response.data;
                vm.calculTotalBox = 0;
                vm.calculTotalUnite = 0;
                _.each(vm.selected_cmd_by_depot, (element, index) => {
                    vm.calculTotalBox += Math.round(element.carton);
                    vm.calculTotalUnite += Math.round(element.unite);
                });
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };

    vm.getDepotUser = function (depot_code) {

        WS.getData('gestion_commandes/getDepotUser/' + depot_code).then(
            function (response) {
                vm.infouserdepot = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }

    vm.getListAvoirs = function () {

        WS.getData('entetecommercial/listavoirsnonaffecte').then(
            function (response) {
                vm.liste_avoirs = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    }



    vm.getFirstSociete = function () {
        WS.get('societes/getFirstSociete').then(
            function (response) {
                vm.societe = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };



    vm.getFirstpays = function () {
        WS.get('pays/getFirstpays').then(
            function (response) {
                vm.pays = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };


    vm.printCommande = function (elem) {
        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> Bon de Chargement </title>
                                    <style>.noprint{display: none;}.text-center{text-align:center}</style>
                                    <style>
                                        th{border:1px solid #ddd!important; font-size:10px;}
                                        td{border:1px solid #ddd!important; font-size:10px;}
                                        h4,h5{margin: 4px;}
                                        
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write('<h1>' + document.title  + '</h1>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10 */

        mywindow.print();
        mywindow.close();

        return true;
    }


    vm.onMoved = function (depot_code) {
        vm.models.lists[depot_code] = vm.models.lists[depot_code].filter(function (item) { return !item.selected; });
    };


    vm.validerCommandesDepots = function (depot_code, depot_fix) {
        //console.log(depot_code, vm.models.lists[depot_code]);
        var depot_fix = (depot_fix != undefined && depot_fix != '') ? depot_fix : '';
        // console.log({
        //     depot_code,
        //     depot_fix,
        //     commandes : vm.models.lists[depot_code]
        // });
        swal({
            title: $translate.instant('Valider'),
            text: $translate.instant("voulez vous vraiment Valider les Commandes de") + ' ' + depot_code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            var data = {
                depot_code,
                depot_fix,
                addToCamion: false,
                commandes: vm.models.lists[depot_code]
            };
            // console.log(data);
            WS.post('gestion_commandes/validerCommandesDepots', data).then(
                function (response) {
                    if (response.data.success) {

                        _.each(vm.models.lists[depot_code], (element, index) => {
                            vm.models.lists[depot_code][index].valider = 1;
                            vm.models.lists[depot_code][index].status = 'Chargé';
                        });
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Commandes validée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });

                    } else if (response.data.success == false && response.data.stat_code == 'BL_LIVRE') {
                        var cmds_old_list = '';
                        var cmds_new_list = '';
                        _.each(response.data.cmds, element => {
                            cmds_old_list += `<a ui-sref="app.bl.details({id:element.num_bl})" target="_blank">
                                                 <span title="${element.date_livraison}">${element.num_bl}</span>
                                              </a> , `;
                        });
                        _.each(vm.models.lists[depot_code], (element, index) => {
                            cmds_new_list += `<a ui-sref="app.bl.details({id:element.num_bl})" target="_blank">
                                                <span title="${element.date_livraison}">${element.num_bl}</span>
                                              </a> , `;
                        });
                        swal({
                            title: $translate.instant('Valider'),
                            html: '<h3>' + $translate.instant(`Vous avez`) + ' ' + response.data.nbr_cmd + ' ' +
                                $translate.instant('commandes en mode sortie non livré') +
                                '</h3>' +
                                `<h5 style="color:blue">${cmds_old_list}</h5>` +
                                `<h4>${$translate.instant(`Voulez vous Rattraper ces commandes au camion`)} : ${depot_code}</h4>` +
                                `<h5 style="color:blue">${cmds_new_list}</h5>`
                            ,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: $translate.instant('Oui'),
                            cancelButtonText: $translate.instant('Non'),
                            confirmButtonClass: 'btn btn-success',
                            cancelButtonClass: 'btn btn-danger',
                            buttonsStyling: false
                        }).then(function () {
                            var data = {
                                depot_code,
                                depot_fix,
                                addToCamion: true,
                                commandes: vm.models.lists[depot_code]
                            };
                            // console.log(data);
                            WS.post('gestion_commandes/validerCommandesDepots', data).then(
                                function (response) {
                                    if (response.data.success) {

                                        _.each(vm.models.lists[depot_code], (element, index) => {
                                            vm.models.lists[depot_code][index].valider = 1;
                                            vm.models.lists[depot_code][index].status = 'Chargé';
                                        });
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Commandes validée avec Succès'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        });

                                    } else {
                                        swal(
                                            $translate.instant('Oups'),
                                            $translate.instant('Erreur de modification!!'),
                                            'warning'
                                        ).then(function () {
                                            $window.location.reload();
                                        });
                                    }
                                    $scope.$apply();
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention!'),
                                        $translate.instant('Erreur de modification!!'),
                                        'warning'
                                    );
                                }
                            );
                        });
                    } else {
                        swal(
                            $translate.instant('Attention!'),
                            $translate.instant('Erreur de modification!!'),
                            'warning'
                        );
                    }
                    $scope.$apply();
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Erreur de modification!!'),
                        'warning'
                    );
                }
            );
        });
    };

    vm.selectMapsByDepot = function (depot_code) {

        vm.listOfblselected = _.pluck(vm.models.lists[depot_code], 'num_bl');
        WS.post('gestion_commandes/selectMapsByDepot', { depot_code, bls: vm.listOfblselected })
            .then(function (response) {
                vm.list_position = angular.copy(response.data.positions);
                //vm.center_position = angular.copy(response.data.center.latitude + ', ' + response.data.center.longitude);
                //vm.zoomRouting = 7;
                $('#container_maps').empty();
                $('#container_maps').append('<div style="width: 100%; height: 580px" id="mapContainerCmds"></div>');

                if (response.data.center.lat != null && response.data.center.lng) {
                    var mymap = L.map('mapContainerCmds').setView([response.data.center.lat, response.data.center.lng], 9);
                } else {
                    var mymap = L.map('mapContainerCmds').setView([response.data.center.latitude, response.data.center.longitude], 9);
                }
                /*L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
                    attribution: 'Map data &copy; <a href="https://www.clediss.com/">Nomadis</a> ©',
                    maxZoom: 18,
                    id: 'mapbox.streets',
                    accessToken: AppSettings.mapbox.access_token
                }).addTo(mymap);*/

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                    foo: 'bar',
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                })
                    .addTo(mymap);
                /* L.tileLayer(`http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?
                     app_id=${AppSettings.herewego.app_id}&app_code=${AppSettings.herewego.app_code}`
                     , {
                     styleId: 997
                   }).addTo(mymap);*/

                vm.list_position.forEach(function (element, index) {
                    // Creates a red marker with the coffee icon
                    var redMarker = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                    var info = $compile(`
                                         <div id="bodyContent" style="width=400px;">
                                              <div class="text-center">
                                                <a ui-sref="app.commandes.details({id : '${element.num_cmd}' })" target="_blank">
                                                    CMD
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : '${element.client_code}'})" target="_blank">
                                                  Historique
                                                </a>
                                              </div>
                                              <div><b>Cmd N°</b> : ${element.num_cmd}</a></div>
                                              <div><b>Client</b> : ${element.client}</a></div>
                                              <div><b>Livreur</b> : ${element.user}</a></div>
                                              <div><b>Nbr Carton</b> : ${element.nbr_carton}</div>
                                              <div><b>Montant</b> : ${element.montant}</div>
                                            </div>
                                        `)($scope);
                    var popup = L.popup({
                        maxWidth: 600,
                        minWidth: 300
                    }).setContent(info[0])
                    marker.bindPopup(popup);

                });




                $scope.$apply();
                setTimeout(function () {
                    mymap.invalidateSize();
                }, 80);
            })
            .then(null, function (error) {
                console.log(error);
            });
    }



    vm.appendCmdToDepot = function () {
        // console.log(vm.cmd_commercial, vm.current_depot_code);
        //console.log(vm.models.lists["cmd"]);
        var cmds = [];
        _.each(vm.models.lists["cmd"], (element, index) => {
            if (element.user_code == vm.cmd_commercial) {
                cmds.push(element);
            }
        });

        if (cmds.length > 0) {
            var data = {
                depot_code: vm.current_depot_code,
                cmds: cmds
            };

            WS.post('gestion_commandes/appendCmdToDepot', data).then(
                function (response) {
                    if (response.data.success) {
                        var temp_cmd = [];
                        _.each(vm.models.lists["cmd"], (element, index) => {
                            if (element.user_code != vm.cmd_commercial) {
                                temp_cmd.push(element);
                            }
                        });

                        vm.models.lists["cmd"] = temp_cmd;

                        _.each(data.cmds, (element, index) => {
                            if (element.user_code == vm.cmd_commercial) {
                                element.status = 'Preparation';
                                vm.models.lists[vm.current_depot_code].push(element);
                            }
                        });

                        swal(
                            $translate.instant('Succés'),
                            data.cmds.length + $translate.instant(' commandes a été affectées au depot N° : ') + vm.current_depot_code,
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                }
            );
        }
    };



    vm.OutCmdFromDepot = function (depot_code) {

        swal({
            title: $translate.instant('Valider'),
            text: $translate.instant("Voulez-vous vraiment annuler les commandes de camion") + ' ' + depot_code,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            var cmds = [];
            _.each(vm.models.lists[depot_code], (element, index) => {
                if (element.valider == 0 && element.status == 'Preparation') {
                    cmds.push(element);
                }
            });

            if (cmds.length > 0) {
                var data = {
                    depot_code: depot_code,
                    cmds: cmds
                };

                WS.post('gestion_commandes/OutCmdFromDepot', data).then(
                    function (response) {
                        if (response.data.success) {

                            _.each(vm.models.lists[depot_code], (element, index) => {
                                if (element.valider == 0 && element.status == 'Preparation') {
                                    element.status = 'Non traité';
                                    vm.models.lists["cmd"].push(element);
                                }
                            });

                            //delete object from array
                            _.each(data.cmds, (element, index) => {
                                vm.models.lists[depot_code] = _.reject(vm.models.lists[depot_code], function (item) { return item.num_cmd == element.num_cmd; });
                            });
                            vm.countPreparation[depot_code] = 0;
                            _.each(vm.models.lists[depot_code], (item, ind_item) => {
                                if (item.status == 'Preparation') {
                                    vm.countPreparation[depot_code]++;
                                }
                            });

                            swal(
                                $translate.instant('Succés'),
                                data.cmds.length + $translate.instant(' commandes a été supprimé de depot N° : ') + depot_code,
                                'success'
                            ).then(function () {
                                $window.location.reload();
                            }).catch(function () {
                                $window.location.reload();
                            });
                        }
                        $scope.$apply();
                    },
                    function (error) {
                        console.log(error);
                    }
                );
            }
        });
    };

    vm.reinitialiserCommande = function (cmd, depot_code) {

        swal({
            title: $translate.instant('Réinitialisation'),
            html: $translate.instant("voulez vous vraiment Réinitialiser la Commande N°") + ' <br>' + cmd.num_cmd,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {

            WS.post('gestion_commandes/reinitialiserCommande', cmd).then(
                function (response) {
                    if (response.data.success) {
                        cmd.status = 'Non traité';
                        vm.models.lists["cmd"].push(cmd);
                        //delete object from array
                        vm.models.current_day[depot_code] = _.reject(vm.models.current_day[depot_code], function (item) { return item.num_cmd == cmd.num_cmd; });
                        swal(
                            $translate.instant('Succés'),
                            cmd.num_cmd + $translate.instant(' a été réinitialiser avec succée'),
                            'success'
                        );
                    }
                    $scope.$apply();
                },
                function (error) {
                    console.log(error);
                }
            );

        });

    };



    vm.getDepotsFix = () => {
        WS.get('gestion_commandes/getDepotsFix').then(
            function (response) {
                vm.list_depots_fix = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log('error : ->', error);
            }
        );
    };


    vm.sum_poids_affich = {};
    vm.sum_poids_day_affich = {};
    vm.recalculatePoidsByUnite = (depot_code) => {
        if (vm.sum_poids[depot_code] >= 1000 && vm.sum_poids[depot_code] < 1000000) {
            var new_number = vm.sum_poids[depot_code] / 1000;
            new_number = new_number.toFixed(2) + $translate.instant('Kg');
        } else if (vm.sum_poids[depot_code] >= 1000000) {
            var new_number = vm.sum_poids[depot_code] / 1000000;
            new_number = new_number.toFixed(2) + $translate.instant('T');
        } else if (vm.sum_poids[depot_code] < 1000) {
            var new_number = vm.sum_poids[depot_code];
            new_number = new_number.toFixed(2) + $translate.instant('g');
        }
        vm.sum_poids_affich[depot_code] = new_number;
        //console.log(vm.sum_poids[depot_code]);
    };

    vm.recalculatePoidsDayByUnite = (depot_code) => {
        if (vm.sum_poids_day[depot_code] >= 1000 && vm.sum_poids_day[depot_code] < 1000000) {
            var new_number = vm.sum_poids_day[depot_code] / 1000;
            new_number = new_number.toFixed(2) + $translate.instant('Kg');
        } else if (vm.sum_poids_day[depot_code] >= 1000000) {
            var new_number = vm.sum_poids_day[depot_code] / 1000000;
            new_number = new_number.toFixed(2) + $translate.instant('T');
        } else if (vm.sum_poids_day[depot_code] < 1000) {
            var new_number = vm.sum_poids_day[depot_code];
            new_number = new_number.toFixed(2) + $translate.instant('g');
        }
        vm.sum_poids_day_affich[depot_code] = new_number;
    };


    vm.showPanelActions = (cmd) => {
        vm.panel_action = $uibModal.open({
            animation: true,
            templateUrl: 'panel_actions',
            size: 'md',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.cmd = cmd;
                $scope.annulerBL = function (cmd) {
                    vm.annulerBL(cmd);
                };

                $scope.passerLivraison = function (cmd) {
                    //console.log('cmd passerLivraison', cmd);
                    vm.passerLivraison(cmd);
                };
            }
        });
    };

    vm.annulerBL = (cmd) => {
        vm.panel_annuler_bl = $uibModal.open({
            animation: true,
            templateUrl: 'panel_annuler_bl',
            size: 'md',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.SubmitAnnulerBL = function () {
                    $scope.cmd = cmd;
                    if ($scope.password != '' && $scope.password != undefined) {
                        var data = {
                            numero: cmd.num_bl,
                            password: $scope.password
                        };
                        //console.log(data);
                        WS.post('gestion_commandes/annulerBL', data).then(
                            function (response) {
                                if (response.data.success) {

                                    $scope.cmd.status = 'Livré';

                                    _.each(vm.models.current_day[$scope.cmd.depot_code], (element, index) => {
                                        if (element.id == $scope.cmd.id) {
                                            element.status = 'Annulé';
                                        }
                                    });

                                    swal(
                                        $translate.instant('Succès'),
                                        $translate.instant('Bl annulé avec succès'),
                                        'success'
                                    ).then(function () {
                                        //$window.location.reload();
                                        vm.panel_annuler_bl.close({});
                                        vm.panel_action.close({});
                                    }).catch(function () {
                                        vm.panel_annuler_bl.close({});
                                        vm.panel_action.close({});

                                        //$window.location.reload();
                                    });
                                } else {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant(response.data.message),
                                        'warning'
                                    );
                                }

                            },
                            function () {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant('erreur de chargement des données!!'),
                                    'warning'
                                );
                            }
                        );
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('Mot de passe Obligatoire'),
                            'warning'
                        );
                    }
                }
            }
        });
    };

    vm.passerLivraison = (cmd) => {
        //console.log('cmd passerLivraison func', cmd);
        vm.panel_paiement_bl = $uibModal.open({
            animation: true,
            templateUrl: 'panel_paiement_bl',
            size: 'lg',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.languageKey = $translate.proposedLanguage() || $translate.use();
                $scope.cmd = cmd;
                $scope.newbl = {};
                //console.log('cmd', 'livré', cmd)
                WS.getData('gestion_commandes/get_old_paiement_bl', { num_bl: cmd.num_bl }).then(
                    function (response) {
                        $scope.list_paiements = response.data;
                        console.log($scope.list_paiements);
                        $scope.$apply();
                    },
                    function (error) {
                        console.log('error : ->', error);
                    }
                );


                $scope.getTickets = () => {
                    WS.get('ticket_cadeaux').then(
                        (response) => {
                            $scope.ticket_cadeaux = response.data;
                            $scope.$apply();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                };
                $scope.getResultsTickets = () => {
                    $scope.newbl.ticket_cadeau_resultat = 0;
                    if ($scope.newbl.ticket_cadeau_type && $scope.newbl.ticket_cadeau_type != "") {
                        const current_ticket = _.find($scope.ticket_cadeaux, item => item.type == $scope.newbl.ticket_cadeau_type);
                        const montant = current_ticket.montant;
                        const pourcentage = current_ticket.pourcentage;
                        const quantite = $scope.newbl.ticket_cadeau_quantite;
                        const total = Number(quantite) * parseFloat(montant);
                        const commission = (total * parseFloat(pourcentage)) / 100;

                        $scope.newbl.ticket_cadeau_idTicket = current_ticket.id;
                        $scope.newbl.ticket_cadeau_resultat = total - commission;
                        //vm.newbl.espece = total - commission;
                        //console.log(vm.ticket_cadeaux, vm.newbl.ticket_cadeau_type, current_ticket, vm.newbl.ticket_cadeau_quantite);
                    }
                };

                $scope.updateEtatToLivree = function () {
                    console.log('livred', $scope.cmd);
                    WS.post('gestion_commandes/updateEtatToLivree', $scope.cmd).then(
                        function (response) {
                            if (response.data.success) {
                                $scope.cmd.status = 'Livré';
                                console.log('vm.models.current_day', vm.models.current_day);
                                _.each(vm.models.current_day[$scope.cmd.depot_code], (element, index) => {
                                    if (element.id == $scope.cmd.id) {
                                        element.status = 'Livré';
                                    }
                                });
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Bl livré avec succès'),
                                    'success'
                                )
                                    .then(function () {
                                        vm.panel_paiement_bl.close({});
                                        vm.panel_action.close({});
                                        //$window.location.reload();
                                    }).catch(function () {
                                        vm.panel_paiement_bl.close({});
                                        vm.panel_action.close({});
                                    });;
                            } else {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant('erreur d\'insertion!'),
                                    'warning'
                                );
                            }

                        },
                        function () {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('erreur de chargement des données!!'),
                                'warning'
                            );
                        }
                    );
                };

                $scope.updateEtatToLivreeWithPayement = function () {
                    console.log('cmd', $scope.cmd);
                    console.log('paiement', $scope.newbl);
                    if ($scope.newbl.cheque) {
                        if (!$scope.newbl.num_cheque || !$scope.newbl.date_echeance_cheque || !$scope.newbl.banque_cheque) {
                            swal(
                                $translate.instant('Oups'),
                                $translate.instant('veuillez bien remplir tout les chapms de cheque'),
                                'warning'
                            );
                            return;
                        }
                    }
                    if ($scope.newbl.traite) {
                        if (!$scope.newbl.num_traite || !$scope.newbl.date_echeance_traite || !$scope.newbl.banque_traite) {
                            swal(
                                $translate.instant('Oups'),
                                $translate.instant('veuillez bien remplir tout les chapms de traite'),
                                'warning'
                            );
                            return;
                        }
                    }
                    if ($scope.newbl.ticket_cadeau_type) {
                        if (!$scope.newbl.ticket_cadeau_quantite) {
                            swal(
                                $translate.instant('Oups'),
                                $translate.instant('veuillez bien saisir la quantité des tickets'),
                                'warning'
                            );
                            return;
                        }
                    }

                    $scope.passerPaiementwithPassword = function () {
                        vm.passerPaiementwithPassword($scope.cmd, $scope.newbl);
                    };

                    $scope.alertCreditOutOfPlafond = function () {
                        vm.alertCreditOutOfPlafond($scope.solde_client, $scope.plafond_client, $scope.solde_depot , $scope.plafond_depot);
                    };

                    WS.getData('gestion_commandes/getCreditAndPlafondForCmd', $scope.cmd).then(
                        (response) => {
                            //console.log('credit et plafond', response.data);
                            //console.log('cmd', $scope.cmd);
                            var montant = parseFloat($scope.cmd.montant);
                            //calculate credit

                            $scope.solde_client = Math.abs(response.data.solde_client.solde) + montant;
                            $scope.solde_depot = Math.abs(response.data.solde_depot.solde) + montant;

                            //soustraction des paiements
                            $scope.solde_client = ($scope.newbl.espece) ? $scope.solde_client - $scope.newbl.espece : $scope.solde_client;
                            $scope.solde_client = ($scope.newbl.cheque) ? $scope.solde_client - $scope.newbl.cheque : $scope.solde_client;
                            $scope.solde_client = ($scope.newbl.traite) ? $scope.solde_client - $scope.newbl.traite : $scope.solde_client;
                            $scope.solde_client = ($scope.newbl.ticket_cadeau_resultat) ? $scope.solde_client - $scope.newbl.ticket_cadeau_resultat : $scope.solde_client;
                            
                            $scope.solde_depot = ($scope.newbl.espece) ? $scope.solde_depot - $scope.newbl.espece : $scope.solde_depot;
                            $scope.solde_depot = ($scope.newbl.cheque) ? $scope.solde_depot - $scope.newbl.cheque : $scope.solde_depot;
                            $scope.solde_depot = ($scope.newbl.traite) ? $scope.solde_depot - $scope.newbl.traite : $scope.solde_depot;
                            $scope.solde_depot = ($scope.newbl.ticket_cadeau_resultat) ? $scope.solde_depot - $scope.newbl.ticket_cadeau_resultat : $scope.solde_depot;
                            

                            $scope.plafond_client = parseFloat(response.data.solde_client.plafond) ;
                            $scope.plafond_depot = parseFloat(response.data.solde_depot.plafond);

                            $scope.plafond_client = ($scope.plafond_client ) ? $scope.plafond_client : 0 ;
                            $scope.plafond_depot = ($scope.plafond_depot) ? $scope.plafond_depot : 0;
                            var total_paiement = 0;
                            total_paiement = ($scope.newbl.espece) ? parseFloat($scope.newbl.espece) + total_paiement : total_paiement;
                            total_paiement = ($scope.newbl.cheque) ? parseFloat($scope.newbl.cheque) + total_paiement : total_paiement;
                            total_paiement = ($scope.newbl.traite) ? parseFloat($scope.newbl.traite) + total_paiement : total_paiement;
                            total_paiement = ($scope.newbl.ticket_cadeau_resultat) ? parseFloat($scope.newbl.ticket_cadeau_resultat) + total_paiement : total_paiement;

                            if(total_paiement >= montant){
                                $scope.passerPaiementwithPassword();
                            }else{
                                if($scope.solde_client > $scope.plafond_client || $scope.solde_depot > $scope.plafond_depot){
                                    //console.log('etape 1')
                                    $scope.alertCreditOutOfPlafond();
                                }else{
                                    //console.log('etape 2')
                                    $scope.passerPaiementwithPassword();
                                    //mot de passe
                                }
                            }
                            
                            $scope.$apply();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                    

                    

                }
            }
        });
    };



    vm.alertCreditOutOfPlafond = (solde_client, plafond_client, solde_depot , plafond_depot) => {
        vm.panel_alert_credit = $uibModal.open({
            animation: true,
            templateUrl: 'panel_alert_credit',
            size: 'md',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.solde_client = solde_client;
                $scope.plafond_client = plafond_client;
                $scope.solde_depot = solde_depot;
                $scope.plafond_depot = plafond_depot;

                $scope.quiterBl = () => {
                    vm.panel_alert_credit.close({});
                    vm.panel_paiement_bl.close({});
                    vm.panel_action.close({});
                };

                $scope.closeThisPopPup = () => {
                    vm.panel_alert_credit.close({});
                };
            }
        });
    };


    vm.passerPaiementwithPassword = (cmd, newbl) => {
        vm.panel_submit_paiement_bl = $uibModal.open({
            animation: true,
            templateUrl: 'panel_submit_paiement_bl',
            size: 'md',
            backdrop: true,
            controller: function ($scope, $translate) {
                $scope.SubmitPaiementBL = function () {
                    if ($scope.password != '' && $scope.password != undefined) {
                        $scope.cmd = cmd;
                        newbl.num_bl = cmd.num_bl;
                        newbl.num_cmd = cmd.num_cmd;
                        newbl.depot_code = cmd.depot_code;
                        newbl.montant = cmd.montant;
                        newbl.password = $scope.password;
                        var data = newbl;
                        //console.log(data);
                        WS.post('gestion_commandes/passerPaiementwithPassword', data).then(
                            function (response) {
                                if (response.data.success) {
                                    $scope.cmd.status = 'Livré';

                                    _.each(vm.models.current_day[$scope.cmd.depot_code], (element, index) => {
                                        if (element.id == $scope.cmd.id) {
                                            element.status = 'Livré';
                                        }
                                    });
                                    swal(
                                        $translate.instant('Succès'),
                                        $translate.instant('Bl livré avec succès'),
                                        'success'
                                    ).then(function () {
                                        vm.panel_submit_paiement_bl.close({});
                                        vm.panel_paiement_bl.close({});
                                        vm.panel_action.close({});
                                    }).catch(function () {
                                        vm.panel_submit_paiement_bl.close({});
                                        vm.panel_paiement_bl.close({});
                                        vm.panel_action.close({});
                                    });;
                                } else {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant(response.data.message),
                                        'warning'
                                    );
                                }

                            },
                            function () {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant('erreur de chargement des données!!'),
                                    'warning'
                                );
                            }
                        );
                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('Mot de passe Obligatoire'),
                            'warning'
                        );
                    }
                }
            }
        });
    };

}
export default {
    name: 'gestion_cmdController',
    fn: gestion_cmdController
};