function banqueController(DatatableLang, $translate, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.current_date = moment().format('YYYY-MM-DD');

    vm.getBanques = function() {
        WS.get('banques')
            .then(function(response) {
                if (response.status == 200) {
                    vm.banques = response.data.banques;
                    vm.caisses = response.data.caisses;
                    vm.total_espece = 0;
                    _.each(vm.caisses, (el, index) =>{
                        vm.total_espece += el.check_espece_caisse;
                    });
                    $scope.$apply();
                    /*$('.table').DataTable({
                        "language": DatatableLang,
                        order : ['3', 'desc']
                    });*/
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectDepots = function() {
        WS.get('depots/selectDepot', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectdepots = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.storeBanque = function(){
        WS.post('banques', vm.banque)
            .then(function(response) {
                if(response.data.success== true){
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Versement Ajoutée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    }).catch(function() {
                        $window.location.reload();
                    });
                }else{
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('Probleme d\'insertion'),
                        'warning'
                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'banqueController',
    fn: banqueController
};