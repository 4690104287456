function authorizationController($state, WS, $scope, $translate) {
    'ngInject';
    const vm = this;
    vm.auths = {};

    vm.manageActions = function() {
        const data = {
            role_code: $state.params.role_code,
            new_actions: vm.auths
        };
        WS.post('roles/manage_actions_authorization', data).then(
            (response) => {
                //console.log(response);
                toastr.success($translate.instant('roles affectée avec succés'));
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * initial design input
     */
    WS.get('roles/change_authorization_roles/' + $state.params.role_code).then(
        (response) => {
            _.each(response.data, (element, index) => {
                vm.auths[element] = true;
            });
            $scope.$apply();
            $(function() {

                $('.button-checkbox-roles').each(function() {

                    // Settings
                    var $widget = $(this),
                        $button = $widget.find('button'),
                        $checkbox = $widget.find('input:checkbox'),
                        color = $button.data('color'),
                        settings = {
                            on: {
                                icon: 'glyphicon glyphicon-check'
                            },
                            off: {
                                icon: 'glyphicon glyphicon-unchecked'
                            }
                        };

                    // Event Handlers
                    $button.on('click', function(event) {
                        $checkbox.prop('checked', !$checkbox.is(':checked'));
                        $checkbox.triggerHandler('change');

                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });
                    $checkbox.on('change', function() {
                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });

                    // Actions
                    function updateDisplay(id) {
                        var isChecked = $checkbox.is(':checked');
                        if (id != undefined) {
                            if (isChecked) {
                                vm.auths[id] = true;
                            } else {
                                vm.auths[id] = false;
                            }
                            //console.log(vm.auths[id]);
                            //console.log(vm.auths);
                        }
                        // Set the button's state
                        $button.data('state', (isChecked) ? "on" : "off");

                        // Set the button's icon
                        $button.find('.state-icon')
                            .removeClass()
                            .addClass('state-icon ' + settings[$button.data('state')].icon);

                        // Update the button's color
                        if (isChecked) {
                            $button
                                .removeClass('btn-default')
                                .addClass('btn-' + color + ' active');
                        } else {
                            $button
                                .removeClass('btn-' + color + ' active')
                                .addClass('btn-default');
                        }
                    }


                    // Initialization
                    function init() {

                        updateDisplay();

                        // Inject the icon if applicable
                        if ($button.find('.state-icon').length == 0) {
                            $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                        }
                    }
                    init();
                });
            });
        },
        (error) => {
            console.log(error);
        }
    );



    /**
     * fin design input
     */
}
export default {
    name: 'authorizationController',
    fn: authorizationController
};