function commisionsController(DatatableLang, $state, $scope, WS, $window, AppSettings) {
    'ngInject';
    const vm = this;

   vm.getCommisionMeilleurVendeur = function() {
        WS.get('commissions/commission_meilleur_vendeur')
            .then(function(response) {
                if (response.status == 200) {
                    vm.commission_meilleur_vendeur = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getCommisionPlacement = function() {
        WS.get('commissions/commission_placement')
            .then(function(response) {
                if (response.status == 200) {
                    vm.commission_placement = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getCommisionVisite = function() {
        WS.get('commissions/commission_visite')
            .then(function(response) {
                if (response.status == 200) {
                    vm.commission_visite = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getCommisionProduit = function() {
        WS.get('commissions/commission_produit')
            .then(function(response) {
                if (response.status == 200) {
                    vm.commission_produit = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getCommisionVente = function() {
        WS.get('commissions/commission_vente')
            .then(function(response) {
                if (response.status == 200) {
                    vm.commission_vente = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };



    vm.addCommisionMeilleurVendeur = function(commission) {
        WS.post('commissions/commission_meilleur_vendeur', commission)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'Commission ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.addCommisionPlacement = function(commission) {
        WS.post('commissions/commission_placement', commission)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'Commission ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.addCommisionVisite = function(commission) {
        WS.post('commissions/commission_visite', commission)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'Commission ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.addCommisionProduit = function(commission) {
        WS.post('commissions/commission_produit', commission)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'Commission ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.addCommisionVente = function(commission) {
        WS.post('commissions/commission_vente', commission)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'Commission ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
}
export default {
    name: 'commisionsController',
    fn: commisionsController
};