$(document).ready(function() {

    // CounterUp Plugin
  

    // setTimeout(function() {
    //     toastr.options = {
    //         closeButton: true,
    //         progressBar: true,
    //         showMethod: 'fadeIn',
    //         hideMethod: 'fadeOut',
    //         timeOut: 5000
    //     };
    //     toastr.success('Checkout settings menu on left!', 'Welcome to Modern!');
    // }, 1800);



    // $(".live-tile").liveTile();

});
