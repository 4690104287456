function routingsController(DatatableLang, $translate, $state, $scope, $rootScope, WS, $window, AppSettings, NgMap, $timeout, $compile, $parse) {
    'ngInject';
    const vm = this;
    vm.routings = {};
    vm.users = {};
    vm.clients = {};
    vm.regions = {};
    vm.pointeurs = [];
    vm.weeks = [];
    vm.tournee = [];
    vm.zoomRouting = 7;

    //vm.centeralgerie = AppSettings.mapsalgerie;
    vm.keymaps = AppSettings.keymaps;

    NgMap.getMap({ id: 'routes' }).then(function (response) {
        google.maps.event.trigger(response, 'resize');
        vm.mapsroutings = response;
    });

    vm.showInfoWindow = function (event, client) {
        vm.detaisclient = client;
        var id = client.code;
        id = id.toString();
        vm.mapsroutings.showInfoWindow("inforoutings", id);
    }

    vm.selectMapsByRoute = function (routing_code = null) {
        if (routing_code != null) {
            var url = 'clients/selectMapsByRoute/' + routing_code;
        } else {
            var url = 'clients/selectMapsByRoute';
        }
        WS.get(url)
            .then(function (response) {
                vm.selectmapsclients = angular.copy(response.data.clients);
                vm.centerroutes = angular.copy(response.data.center.lat + ', ' + response.data.center.lng);
                vm.zoomRouting = 7;
                /*vm.polyline = [];
                angular.forEach(vm.selectmapsclients, (value,key)=>{
                    var pos = [];
                    pos.push(value.latitude);
                    pos.push(value.longitude);
                    vm.polyline.push(pos);
                });
                console.log(vm.polyline);*/
                /* if(mymap){
                     mymap.off();
                     mymap.remove();
                 }*/
                $('#con-route').empty();
                $('#con-route').html('<div style="width: 100%; height: 480px" id="mapContainerRoutes"></div>');

                var mymap = L.map('mapContainerRoutes').setView([response.data.center.lat, response.data.center.lng], 9);
                /* L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
                     attribution: 'Map data &copy; <a href="https://www.clediss.com/">Nomadis</a> ©',
                     maxZoom: 18,
                     id: 'mapbox.streets',
                     accessToken: AppSettings.mapbox.access_token
                 }).addTo(mymap);*/

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                    foo: 'bar',
                    attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                })
                    .addTo(mymap);
                /* L.tileLayer(`http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?
                     app_id=${AppSettings.herewego.app_id}&app_code=${AppSettings.herewego.app_code}`
                     , {
                     styleId: 997
                   }).addTo(mymap);*/

                vm.selectmapsclients.forEach(function (element, index) {
                    // Creates a red marker with the coffee icon
                    var redMarker = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: element.color,
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                    var info = $compile(`
                                         <div id="bodyContent" style="width=400px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <p><b>Client</b> : ${element.client}</a></p>
                                              <p><b>Code</b> : ${element.code}</a></p>
                                              <p><b>Code_a_barre</b> : ${element.code_a_barre}</p>
                                              <p><b>Route</b> : ${element.route}</p>
                                              <p><b>Mobile</b> : ${element.mobile}</p>
                                              <p><b>Commercial</b> : ${element.commercial}</p>
                                            </div>
                                        `)($scope);
                    var popup = L.popup({
                        maxWidth: 600,
                        minWidth: 300
                    }).setContent(info[0])
                    marker.bindPopup(popup);

                });



                /*
            angular.extend($scope, {
                center: {
                    lat: 41.85,
                    lng: -87.65,
                    zoom: 8
                },
                defaults: {
                    lat: 41.85,
                    lng: -87.65,
                    zoom: 8
                },
                markers: {
                    m1: {
                        lat: 41.85,
                        lng: -87.65,
                        message: "I'm a static marker with defaultIcon",
                        focus: false,
                        icon: {},
                    },
                },
                defaultIcon: {},
                leafIcon: {
                    iconUrl: 'img/leaf-green.png',
                    shadowUrl: 'img/leaf-shadow.png',
                    iconSize:     [38, 95], // size of the icon
                    shadowSize:   [50, 64], // size of the shadow
                    iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
                    shadowAnchor: [4, 62],  // the same for the shadow
                    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                },
                orangeLeafIcon: {
                    iconUrl: 'img/leaf-orange.png',
                    shadowUrl: 'img/leaf-shadow.png',
                    iconSize:     [38, 95],
                    shadowSize:   [50, 64],
                    iconAnchor:   [22, 94],
                    shadowAnchor: [4, 62],
                    popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
                },
                divIcon: {
                    type: 'div',
                    iconSize: [200, 0],
                    popupAnchor:  [0, 0],
                    html: 'Using <strong>Bold text as an icon</strong>:'
                },
                awesomeMarkerIcon: {
                    type: 'awesomeMarker',
                    icon: 'tag',
                    markerColor: 'red'
                },
                vectorMarkerIcon: {
                    type: 'vectorMarker',
                    icon: 'tag',
                    markerColor: 'red'
                },
                makiMarkerIcon: {
                    type: 'makiMarker',
                    icon: 'beer',
                    color: '#f00',
                    size: "l"
                },
                extraMarkerIcon: {
                    type: 'extraMarker',
                    icon: 'fa-star',
                    markerColor: '#f00',
                    prefix: 'fa',
                    shape: 'circle'
                }
            });*/
                $scope.$apply();

            })
            .then(null, function (error) {
                console.log(error);
            });



    };

    WS.getScache('pays').then(function (response) {
        vm.getpays = response.data;
        vm.center = vm.getpays.longitude + ', ' + vm.getpays.latitude;
        $scope.$apply();
    }, function (error) {
        console.log(error);
    });

    vm.mapForRoutes = function () {
        $timeout(function () {
            NgMap.getMap({ id: 'routings' }).then(function (response) {
                //response.setCenter(vm.center);
                google.maps.event.trigger(response, 'resize');
                vm.mapsroutings = response;
                //$scope.$apply();

            });
        }, 3000);
    };

    vm.mapForTournees = function () {
        $timeout(function () {
            NgMap.getMap({ id: 'tournees' }).then(function (response) {
                google.maps.event.trigger(response, 'resize');
            });
        }, 3000);
    };


    vm.semaine = moment().startOf('week').format('YYYY-MM-DD') + "  " + moment().endOf('week').format('YYYY-MM-DD');
    for (var i = 1; i <= moment().weeksInYear(); i++) {
        vm.weeks.push(moment().week(i).startOf('week').format('YYYY-MM-DD') + "  " + moment().week(i).endOf('week').format('YYYY-MM-DD'));
    }
    vm.get = function () {
        WS.get('routing')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.update = function (value) {
        console.log(value.code);
        WS.put('tournees/' + value.code, value)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Tournée modifiée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    }).catch(function () {
                        $window.location.reload();
                    })
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getSecteurBycode = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['code'] == code) {
                return liste[i];
            }

        }
    }


    vm.getRegions = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {

                    vm.regions = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCategorie = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {

                    vm.categoriescommerciale = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getActivites = function () {
        WS.get('activites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDistributeurs = function () {
        WS.get('distributeurs/selectedSousSocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.distributeurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getTourneesClient = function (page = 1, per_page = 10) {
        var data = {
            page,
            per_page,
            routing_code: vm.filter_route,
            user_code: vm.filter_user,
            code_jour: vm.filter_code_jour,
            actif: vm.filter_actif
        };
        WS.getData('tournees', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tourneesClient = response.data;
                    vm.tourneesClient.next = vm.tourneesClient.current_page + 1;
                    vm.tourneesClient.previous = vm.tourneesClient.current_page - 1;
                    vm.tournee_paginate = [];
                    for (i = 1; i <= vm.tourneesClient.last_page; i++) {
                        vm.tournee_paginate.push(i);
                    }
                    $scope.$apply();
                    /*$('.tabletournees').DataTable({
                        "language": DatatableLang
                    });*/
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.checkpaginate = function (current, page) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }


    vm.getDistinctTournees = function () {

        WS.get('tournees/distinct')
            .then(function (response) {
                if (response.status == 200) {
                    vm.DistinctTournees = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getEtatTournne = function (value) {

        WS.get('tournees/etat/' + value.route_code + '/' + value.tournee_code + '/' + value.date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.EtatTournne = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getVisiteHorsTournee = function (value) {
        WS.get('tournees/alerte/' + value.route_code + '/' + value.tournee_code + '/' + value.date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.visiteHorsTournee = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getTourneesWithClient = function () {

        WS.get('tournees/withclient')
            .then(function (response) {
                if (response.status == 200) {
                    vm.TourneesWithClient = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getClientByTournees = function (value) {


        WS.getScache('tournees/clients/' + value)
            .then(function (response) {
                if (response.status == 200) {
                    vm.getClientTournees = response.data.clients;
                    vm.centertournees = response.data.center.center;
                    NgMap.getMap({ id: 'tournees' }).then(function (response) {
                        //response.setCenter(vm.centertournees);
                        google.maps.event.trigger(response, 'resize');
                    });



                    /**
                     * leafletJS maps 
                     */

                    $('#panel-maps-tournee').empty();
                    $('#panel-maps-tournee').html('<div style="width: 100%; height: 480px" id="mapContainerTournees"></div>');

                    var mymap = L.map('mapContainerTournees').setView([response.data.center.lat, response.data.center.lng], 9);

                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}', {
                        foo: 'bar',
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    })
                        .addTo(mymap);

                    vm.getClientTournees.forEach(function (element, index) {
                        // Creates a red marker with the coffee icon
                        var redMarker = L.ExtraMarkers.icon({
                            icon: 'fa-home',
                            markerColor: element.color,
                            shape: 'circle', //'circle', 'square', 'star', or 'penta'
                            prefix: 'fa'
                        });

                        var marker = L.marker([element.latitude, element.longitude], { icon: redMarker }).addTo(mymap);
                        var info = $compile(`
                                         <div id="bodyContent" style="width=400px;">
                                              <p class="text-center">
                                                <a ui-sref="app.clients.details({id : ${element.id} })" target="_blank">
                                                    Modifier
                                                </a>
                                                <b> | </b>
                                                <a ui-sref="app.clients.historiques({code : ${element.code}})" target="_blank">
                                                  Historique
                                                </a>
                                              </p>
                                              <p><b>Client</b> : ${element.nom} ${element.prenom}</a></p>
                                              <p><b>Code</b> : ${element.code}</a></p>
                                              <p><b>Code_a_barre</b> : ${element.code_a_barre}</p>
                                              <p><b>Route</b> : ${element.routing_code}</p>
                                              <p><b>Mobile</b> : ${element.mobile}</p>
                                              <p><b>Adresse</b> : ${element.adresse_facturation}</p>
                                            </div>
                                        `)($scope);
                        var popup = L.popup({
                            maxWidth: 600,
                            minWidth: 300
                        }).setContent(info[0])
                        marker.bindPopup(popup);

                    });
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getClientsTournees = function (value) {


        WS.getScache('tournees/clientstournees/' + value)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsTournees = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getDetailsTournees = function () {
        const url = 'tournees/' + $state.params.route_code + '/' + $state.params.tournee_code + '/' + $state.params.depot_code + '/' + $state.params.code_jour;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.detailTournees = response.data.tournes;
                    vm.centertournees = response.data.center.center;
                    vm.client.code = vm.detailTournees[0]['code'];
                    vm.client.code_jour = vm.detailTournees[0]['code_jour'];
                    vm.client.date_debut = vm.detailTournees[0]['date_debut'];
                    vm.client.date_fin = vm.detailTournees[0]['date_fin'];
                    vm.client.routing_code = vm.detailTournees[0]['route'];
                    vm.client.frequence = vm.detailTournees[0]['frequence'];
                    vm.client.actif = vm.detailTournees[0]['actif'];
                    $scope.$apply();
                    
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getObjByTournee = function (code, liste, objet) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][objet] == code) {
                return liste[i];
            }

        }
    }

    vm.getObjByCode = function (code, liste, objet) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][objet] == code) {
                return liste[i];
            }

        }
    }


    vm.getclientByTournee = function (code, liste) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['client_code'] == code) {
                return liste[i];
            }

        }
    }


    vm.getCheckedByTournee = function (code, liste, jour) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['client_code'] == code && liste[i]['code_jour'] == jour) {
                return liste[i];
            }

        }
    }

    vm.getTournees = function () {
        WS.get('routing')
            .then(function (response) {
                if (response.status == 200) {
                    vm.tourneesavecclients = response.data;
                    $scope.$apply();
                    /*$('.tabletournees').DataTable({
                        "language": DatatableLang
                    });*/
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getZones = function () {
        WS.get('zones')
            .then(function (response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getSecteur = function () {
        WS.get('secteurs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.secteurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.delete = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer ce tournee!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('tournee/' + id).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('tournee Supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer ce tournee'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };

    vm.deleteClientFromTournee = function (tournee_id, tournee_code, code_client) {
        //alert(tournee_id + '__' + tournee_code + '__' + code_client);
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer ce Client!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('tournees/deleteClientFromTournee/' + tournee_id + '/' + tournee_code + '/' + code_client).then(
                function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Client Supprimée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            swal(
                                $translate.instant('Attention!'),
                                $translate.instant('Vous ne pouvez pas supprimer ce Client'),
                                'warning'
                            );
                        });
                    } else {
                        swal(
                            $translate.instant('Attention!'),
                            $translate.instant('Vous ne pouvez pas supprimer ce Client'),
                            'warning'
                        );
                    }
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer ce Client'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };


    vm.getCAMoyenByTournee = function (liste) {
        if (!liste) return 0;
        var nbr = 0;
        for (var i = 0; i < liste.length; i++) {
            if(liste[i]['moyca'] != null){
                nbr = nbr + parseInt(liste[i]['moyca']);
            }  
        }
        return nbr;
    }


    vm.deletetournne = function (tournee_code, depot_code) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer ce Tournée!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('tournees/code/' + tournee_code + '/' + depot_code).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Tournée Supprimée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer ce Tournée'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    };

    vm.getcommercial = function () {
        WS.get('users/commprev', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercial = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }





    vm.getUsers = function () {
        WS.get('users')
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectClients = function () {
        WS.get('clients/selectClients').then(
            function (response) {
                vm.selectclients = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };
    vm.selectClientsWithCode = function () {
        WS.get('clients/selectClientsWithCode').then(
            function (response) {
                vm.selectclients = response.data;
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };

    vm.selectClientForTournee = function (route_code) {
        var data = {
            tournee_code: $state.params.tournee_code,
            routing_code: route_code
        };
        console.log(data);
        WS.getData('tournees/selectClientForTournee', data).then(
            function (response) {
                vm.selectclients = response.data;
                console.log(response.data);

                $scope.$apply();
            },
            function (error) {
                console.log(error);
            }
        );
    };


    vm.addNewClientTournee = function ($code, $depot) {
        // 
        //   vm.client.code = $state.params.id;
        vm.client.code = $code;
        vm.client.depot = $depot;
        vm.client.depot_code = $state.params.depot_code;
        console.log(vm.client);
        if (vm.client.client_code == '' || vm.client.client_code == undefined) {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Client Obligatoire!'),
                'warning'
            );
        } else {
            WS.post('tournees/storeNewClientTournee', vm.client).then(
                function (response) {
                    if (response.data.status == true) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Client Ajouté avec succé!'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });

                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('Client Obligatoire!'),
                            'warning'
                        );
                    }
                },
                function (error) {
                    console.log(error);
                });
        }

    };
    
    vm.getClients = function () {
        var url = 'clients';
        if (vm.region) {
            url += '/secteur/' + vm.region;
        }
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    /*
        vm.getSecteur = function(id){
            WS.get('secteurs/'+id).then(
                function(response){
                    return response.data.libelle;
                },
                function(error){
                    console.log(error);
                }
            );
        };*/
    vm.getClientsFiltred = function () {

        var url = 'clients/secteur';

        url += '/' + vm.secteur;
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getRouting = function () {
        /*let url  ='routing/analyses';
        if( vm.commercial ){
            url += '/'+vm.commercial;
        }
        if( vm.semaine ){
            if( !vm.commercial ){
                url += '/0';
            }
            url += '/'+vm.semaine;
        }
        WS.get( url )
            .then(function(response) {
                if (response.status == 200) {
                    var t = {};
                    $.each(response.data, function(index, val) {
                        if(!t[val.client_code]){
                            t[val.client_code] = {items:[], client : val.client};
                        }
                        delete val.client;
                        t[val.client_code].items[val.code_jour]=val;
                    });
                    vm.routings = t;
                    setTimeout(function() {
                        $.each($('table tbody tr'), function(index, tr) {
                            var nbVisites = $(tr).find('.bg-success').length;
                            $(tr).find('.nbVisites').html(nbVisites);
                        });
                    }, 100);
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });*/
    }
    /* vm.enregistrerTrounee = function(tournee) {
          console.log(tournee);

         //WS.post('routing', { tournee : Object.assign({}, tournee) })
         WS.post('routing', tournee)
             .then(function(response) {
                 if (response.status == 200) {
                     console.log( response );
                     $window.location.reload();
                     
                 } else {}
             })
             .then(null, function(error) {
                 console.log(error);
             });
     }*/

    vm.enregistrerTrounee = function () {
        var data = {
            date_debut: vm.date_debut,
            date_fin: vm.date_fin,
            code_jour: vm.code_jour,
            frequence: vm.frequence,
            routing_code: vm.routing_code,
            depot_code: vm.depot_code,
            tournee: Object.assign({}, vm.tournee)
        };
        if (data.depot_code != undefined && data.routing_code != undefined && data.code_jour != undefined) {
            WS.post('routing', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succés'),
                            $translate.instant('Tournée Ajoutée avec succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });

                    } else {
                        swal(
                            $translate.instant('Attention'),
                            $translate.instant('erreur d\'insertion!'),
                            'warning'
                        );
                    }
                    //$window.location.reload();
                })
                .then(null, function (error) {
                    console.log(error);
                });
        } else {
            swal('Oups!', 'Veuillez bien verifier les champs route,depot et codejour', 'warning')
        }
    }

    $scope.$watch('vm.commercial', function (newval, oldval) {
        if (newval == oldval) return;
        vm.getRouting();
    }, true);
    $scope.$watch('vm.semaine', function (newval, oldval) {
        if (newval == oldval) return;
        vm.getRouting();
    }, true);



    vm.getClientforUpdateRoutes = function () {
        var data = {
            activite_code: vm.activite_code,
            region_code: vm.region,
            zone_code: vm.zone,
            routing_code: vm.routing_code,
           
          
        };


        WS.getData('clients/getClientforUpdateRoutes', data)
            .then(function (response) {
                vm.clients = response.data;
                vm.group_clients = [];
                _.each(vm.listclients, (element, index) => {
                    vm.group_clients.push(element.code);
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
    vm.centerMapsforUpdateRoutings = function (page = 1, perpage = 15) {
        var url = `clients/centerMapsforUpdateRoutings?` +
            `&activite_code=${vm.activite_code}` +
            `&date=${vm.date}` +
            `&region_code=${vm.region_code}` +
            `&gouvernorat_code=${vm.gouvernorat_code}` +
            `&routing_code=${vm.routing_code}` +
            `&user_code=${vm.user_code}` +
            `&adv_search=${vm.adv_search}` +
            `&credit=${vm.credit}`;
        WS.get(url)
            .then(function (response) {
                vm.centermaps = response.data.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    $(document).ready(function () {
        $(document).on('change', 'tr .chekboxed', function () {
            var tr = $(this).parents('tr');
            tr.removeClass('selected');

            var nbVisites = tr.find('.chekboxed:checked').length;
            tr.find('.nbVisites').html(nbVisites);

            if (nbVisites) {
                vm.center = tr.data('latitude') + "," + tr.data('longitude');
                $('.pin-' + tr.data('id')).addClass('active');
            } else {
                $('.pin-' + tr.data('id')).removeClass('active');
            }
            $scope.$apply();
            $('tfoot .lun').html($('tbody').find('.lun:checked').length);
            $('tfoot .mar').html($('tbody').find('.mar:checked').length);
            $('tfoot .mer').html($('tbody').find('.mer:checked').length);
            $('tfoot .jeu').html($('tbody').find('.jeu:checked').length);
            $('tfoot .ven').html($('tbody').find('.ven:checked').length);
            $('tfoot .sam').html($('tbody').find('.sam:checked').length);
            $('tfoot .dim').html($('tbody').find('.dim:checked').length);
        });
        $(document).on('click', '.pin', function () {
            $(this).siblings().removeClass('selected');
            $(this).addClass('selected');
            var id = $(this).find('.pin').data('id');
            $('tbody tr').removeClass('selected');
            $('tr[data-id=' + id + ']').addClass('selected');

            /* $('html, body').animate({
                 scrollTop: $('tr[data-id='+ id +']').offset().top - 100
             }, 1000);*/
        });
    });


    vm.getRouteWithDepot = function () {
        WS.get('routing/getRouteWithDepot')
            .then(function (response) {
                vm.routeswithdepot = response.data;
                $scope.$apply();
                /*$('.table_routes').DataTable({
                        "language": DatatableLang
                    });*/
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.showClientByRoute = function (codedepot) {
        WS.get('routing/showClientByRoute/' + codedepot)
            .then(function (response) {
                vm.showclientbyroute = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.changeRouteClients = function (client_id, routing_code) {
        var client = { routing_code: routing_code };
        WS.put('clients/' + client_id, client)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Client modifiée avec Succès'),
                        'success'
                    );
                } else if (response.data.success == false) {
                    swal(
                        $translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectMapsClients = function () {
        WS.get('clients/selectMaps')
            .then(function (response) {
                vm.selectmapsclients = response.data.clients;
                vm.centerroutes = response.data.center.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectDepot = function () {
        WS.get('depots/selectDepot')
            .then(function (response) {
                vm.selectdepot = response.data;
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.storeRouting = function (route) {
        WS.post('routing/storeRouting', route)
            .then(function (response) {
                $window.location.reload();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /*******
     * routing tab 1 
     */
    vm.checkAllClients = () => {
        console.log(vm.cochetout)
        _.each(vm.clients , (cls,index) => {

            vm.tournee[index] = vm.tournee[index] ? vm.tournee[index] : {};
            vm.tournee[index]['checked'] = vm.cochetout ? true : false;
            //vm.getCheckedByTournee( cls.code,  vm.tourneesClient ,1).code_jour
        })
    }
    vm.csvfile = {}
    vm.filterRegionByRoute = function () {
        var region = (vm.region == "" || vm.region == undefined) ? "null" : vm.region;
        var zone = (vm.zone == "" || vm.zone == undefined) ? "null" : vm.zone;
        var secteur = (vm.secteur == "" || vm.secteur == undefined) ? "null" : vm.secteur;
        var activite = (vm.activite_code == "" || vm.activite_code == undefined) ? "null" : vm.activite_code;
        vm.csvfile.filetxt = vm.files ? vm.files[0] : null;

        WS.postwithimg('routing/filterRegionByRoute',
            {
                region,zone,secteur,activite, route:vm.routing_code,
                filetxt: vm.csvfile.filetxt
            }, "POST")
            .then(function (response) {
                vm.regions = response.data.regions;
                vm.clients = response.data.clients;
                vm.centerroutings = response.data.center.center;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    $scope.moveCSV = function (element) {
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.csvfile.filetxt = event.target.result;
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };
    

    /***
     **/
    vm.updateFrequance = function (idtourne, code_client, data) {

        WS.put('tournees/updateFrequance/' + idtourne + '/' + code_client, data)
            .then(function (response) {
                swal(
                    $translate.instant('Succès'),
                    $translate.instant('Frequence modifiée avec Succès'),
                    'success'
                ).then(function () {
                    $window.location.reload();
                }).catch(function () {
                    $window.location.reload();
                })
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.changeColorRoute = function (route_code, data) {
        WS.put('routing/updateRouteColor/' + route_code, data)
            .then(function (response) {
                swal(
                    $translate.instant('Succès'),
                    $translate.instant('couleur modifiée avec Succès'),
                    'success'
                ).then(function () {
                    $window.location.reload();
                })
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateRoute = function (route) {
        WS.put('routing/updateRoute/' + route.id, route)
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('route modifiée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('route non modifiée'),
                        'warning'
                    );
                }

            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('route contient des clients!'),
                    'warning'
                ).then(function () {
                    $window.location.reload();
                });
            });

    };

    /**
     * Dev App
     * get all client distinct in tournees
     */
    vm.clsFromTournees = function () {
        WS.get('tournees/clsFromTournees')
            .then(function (response) {
                vm.clsfromtournees = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    /**
     * Dev App
     * get ALL distinct route with tournee with all clients inside tournee
     */
    vm.getRouteTourneeClient = function () {
        WS.get('tournees/getRouteTourneeClient')
            .then(function (response) {
                vm.getroutetourneeclient = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /**
     * Dev App
     * get All visites and compare with tourne client where date
     */
    vm.getAllVisitesforTourne = function () {
        WS.get('tournees/getAllVisitesforTourne')
            .then(function (response) {
                vm.getvisitesfortourne = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /*
    vm.checkifvalueexist = function(code,list){
        var info = 0;
        if(list.length==0){
            return info;
        }
        angular.forEach(list, function(element, index){
            if(code == element.client_code){
                 info = info + 1;
            }
        });
        return info;
    };*/

    vm.checkifvalueexist = function (client_code, user_code, list) {
        var info = 0;
        if (list.length == 0) {
            return info;
        }
        angular.forEach(list, function (element, index) {
            if (client_code == element.client_code && user_code == element.user_code) {
                info = info + 1;
            }
        });
        return info;
    };


    vm.searhCommande = function (filter) {
        if (filter == undefined) {
            vm.traitf = !vm.traitf;
        }
        vm.traitf = !vm.traitf;
        WS.post('tournees/filterCommandeByJourDate', filter).then(
            function (response) {
                if (response.data.success == true) {
                    vm.getroutetourneeclient = response.data.tournee;
                    vm.getvisitesfortourne = response.data.visites;
                    vm.traitf = !vm.traitf;
                    $scope.$apply();
                } else {
                    vm.traitf = !vm.traitf;
                    vm.messageerror = response.data.message;
                    vm.showerror = true;
                    $scope.$apply();
                    setTimeout(function () {
                        $scope.$apply(function () {
                            vm.showerror = false;
                        });
                    }, 3000);
                }

            },
            function (error) {
                console.log(error);
            });
    }
    $("#alert-danger").hide();

    vm.getRoutesByDepot = (depot_code) => {
        WS.get('tournees/getroutesByDepot/' + depot_code).then(
            (response) => {
                vm.routesbydepot = response.data;
                $scope.$apply();
            }, (error) => {
                console.log(error);
            }
        );
    };

    vm.cloneTournee = function (tournee_code, depot_code, route, code_jour) {
        vm.clone_tournee_code = tournee_code;
        vm.clone_depot_code = depot_code;
        vm.clone_route = route;
        vm.code_jour = code_jour;
    };

    vm.submitCloneTournee = function (new_depot_code, new_route_code, new_code_jour, libelle) {
        if (vm.clone_depot_code == new_depot_code && vm.clone_route == new_route_code && vm.code_jour == new_code_jour) {
            swal(
                $translate.instant('Attention'),
                $translate.instant('Tournee exist deja!!'),
                'warning'
            );
        } else {
            if (
                new_route_code == undefined || new_route_code == '' ||
                new_code_jour == undefined || new_code_jour == '' ||
                new_depot_code == undefined || new_depot_code == ''
            ) {
                swal(
                    $translate.instant('Attention'),
                    $translate.instant('Veuillez bien remplir les champs'),
                    'warning'
                );
            } else {
                var data = {
                    old_tournee_code: vm.clone_tournee_code,
                    old_depot_code: vm.clone_depot_code,
                    old_route_code: vm.clone_route,
                    old_code_jour: vm.code_jour,
                    new_route_code: new_route_code,
                    new_code_jour: new_code_jour,
                    new_depot_code: new_depot_code,
                    new_libelle: libelle,
                };
                console.log(data);
                WS.getData('tournees/CloneTournee', data).then(
                    (response) => {
                        vm.tourneesClient.push(response.data);
                        $("#clone_tournee").removeClass("in");
                        $("#clone_tournee").css("display", "none");
                        $(".modal-backdrop.fade.in").remove();

                        $scope.$apply();
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('tournee') + ' : ' + response.data.code + ' ' + $translate.instant(response.data.code_jour) + ' ' + $translate.instant('a ete ajouté avec succé'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    }, (error) => {
                        console.log(error);
                    }
                );
            }

        }


    };

    vm.deleteRoute = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cette route!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.post('routing/deleteRoute', { id: id }).then(
                function (response) {
                    if (response.data.success == true) {
                        swal(
                            $translate.instant('Succès'),
                            $translate.instant('Route Supprimée avec Succès'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant('Erreur de suppression'),
                            'warning'
                        );
                    }

                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cette route'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }
    /*
        vm.check_filter_route = function() {
            if (vm.filter_route == "") {
                vm.filter_route = undefined;
            }
        }

        vm.check_filter_user = function() {
            if (vm.filter_user == "") {
                vm.filter_user = undefined;
            }
        }

        vm.check_filter_code_jour = function() {
            if (vm.filter_code_jour == "") {
                vm.filter_code_jour = undefined;
            }
        }
    */
    vm.findClientByMaps = function (id) {

        /*background-color: #baf7e3 !important;   ver*/
        angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
        $timeout(function () {
            angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');
            $timeout(function () {
                angular.element(document.getElementById('client-' + id)).addClass('markerGreen');
                $timeout(function () {
                    angular.element(document.getElementById('client-' + id)).removeClass('markerGreen');

                }, 300);
            }, 300);
        }, 200);
    }


    vm.callPanelZonning = () => {
        // $emit ('method name', 'params')
        $rootScope.$emit("methodOpenModalZonning", {});
    }


}
export default {
    name: 'routingsController',
    fn: routingsController
};