function CommaTwo() {

    return function(value){

	   return value.toFixed(2);

	}


}

export default {
  name: 'CommaTwo',
  fn: CommaTwo
};
