function clotureBlsController(DatatableLang, $state, $scope, WS, $window, AppSettings, $translate, $compile, Flash, $http, $uibModal) {
    'ngInject';
    const vm = this;


    vm.bldate={};
    vm.bldate.date_debut = '';
    vm.bldate.date_fin = '';
    vm.listBls = () => {

        WS.getData('bl/listBls', vm.bldate)
            .then((response) => {
                vm.list_users = response.data;

                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };

    vm.selectUsers = function () {
        WS.get('users/selectUsers')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getListBlsByUser = (user_code) => {
        vm.bls = [];
        const data = {
            user_code,
            cloture: vm.type_cloture,
            date_debut: vm.bldate.date_debut,
            date_fin: vm.bldate.date_fin,
        }

        WS.getData('bl/getListBlsByUser', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.bls = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.tab_to_cloture = [];
    vm.tab_remove_cloture = [];
    vm.total_net_cloture = 0;

    vm.sum_espece = 0;
    vm.sum_cheque = 0;
    vm.sum_traite = 0;
    vm.sum_credit = 0;
    vm.sum_montantTicket = 0;
    vm.reinitialise_data = () => {
        vm.tab_to_cloture = [];
        vm.tab_remove_cloture = [];
        vm.total_net_cloture = 0;
        vm.sum_espece = 0;
        vm.sum_cheque = 0;
        vm.sum_traite = 0;
        vm.sum_credit = 0;
        vm.sum_montantTicket = 0;
    };
    vm.manage_checks = (bl) => {
        if (vm.type_cloture == 'non') {
            if (vm.tab_to_cloture.includes(bl.code)) {
                const index = vm.tab_to_cloture.indexOf(bl.code);
                if (index > -1) {
                    vm.tab_to_cloture.splice(index, 1);
                    vm.total_net_cloture -= bl.net_a_payer;
                    vm.sum_espece -= bl.sum_espece;
                    vm.sum_cheque -= bl.sum_cheque;
                    vm.sum_traite -= bl.sum_traite;
                    vm.sum_credit -= bl.sum_credit;
                    vm.sum_montantTicket -= bl.sum_montantTicket;

                }
            } else {
                vm.tab_to_cloture = [...vm.tab_to_cloture, bl.code];
                vm.total_net_cloture += bl.net_a_payer;

                vm.sum_espece += bl.sum_espece;
                vm.sum_cheque += bl.sum_cheque;
                vm.sum_traite += bl.sum_traite;
                vm.sum_credit += bl.sum_credit;
                vm.sum_montantTicket += bl.sum_montantTicket;
            }
        } else {
            if (vm.tab_remove_cloture.includes(bl.code)) {
                const index = vm.tab_remove_cloture.indexOf(bl.code);
                if (index > -1) {
                    vm.tab_remove_cloture.splice(index, 1);
                    vm.total_net_cloture -= bl.net_a_payer;

                    vm.sum_espece -= bl.sum_espece;
                    vm.sum_cheque -= bl.sum_cheque;
                    vm.sum_traite -= bl.sum_traite;
                    vm.sum_credit -= bl.sum_credit;
                    vm.sum_montantTicket -= bl.sum_montantTicket;
                }
            } else {
                vm.tab_remove_cloture = [...vm.tab_remove_cloture, bl.code];
                vm.total_net_cloture += bl.net_a_payer;
                vm.sum_espece += bl.sum_espece;
                vm.sum_cheque += bl.sum_cheque;
                vm.sum_traite += bl.sum_traite;
                vm.sum_credit += bl.sum_credit;
                vm.sum_montantTicket += bl.sum_montantTicket;
            }
        }

        console.log(vm.tab_to_cloture, vm.total_net_cloture);
    };

    vm.check_box_all = () => {
        if (vm.type_cloture == 'non') {
            if (vm.check_all == false) {
                vm.tab_to_cloture = [];
                vm.total_net_cloture = 0;
                vm.sum_espece = 0;
                vm.sum_cheque = 0;
                vm.sum_traite = 0;
                vm.sum_credit = 0;
                vm.sum_montantTicket = 0;
            } else {
                vm.tab_to_cloture = _.pluck(vm.bls, 'code');
                vm.total_net_cloture = _.reduce(vm.bls, function (memo, num) { return memo + num.net_a_payer; }, 0);
                vm.sum_espece = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_espece; }, 0);
                vm.sum_cheque = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_cheque; }, 0);
                vm.sum_traite = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_traite; }, 0);
                vm.sum_credit = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_credit; }, 0);
                vm.sum_montantTicket = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_montantTicket; }, 0);
            }
        } else {
            if (vm.check_all == false) {
                vm.tab_remove_cloture = [];
                vm.total_net_cloture = 0;
                vm.sum_espece = 0;
                vm.sum_cheque = 0;
                vm.sum_traite = 0;
                vm.sum_credit = 0;
                vm.sum_montantTicket = 0;
            } else {
                vm.tab_remove_cloture = _.pluck(vm.bls, 'code');
                vm.total_net_cloture = _.reduce(vm.bls, function (memo, num) { return memo + num.net_a_payer; }, 0);
                vm.sum_espece = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_espece; }, 0);
                vm.sum_cheque = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_cheque; }, 0);
                vm.sum_traite = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_traite; }, 0);
                vm.sum_credit = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_credit; }, 0);
                vm.sum_montantTicket = _.reduce(vm.bls, function (memo, num) { return memo + num.sum_montantTicket; }, 0);
            }
        }
    }

    vm.executeSelectedCloture = () => {
        if (vm.type_cloture == 'non') {
            var data = {
                data: vm.tab_to_cloture,
                cloture: vm.type_cloture
            }
        } else {
            var data = {
                data: vm.tab_remove_cloture,
                cloture: vm.type_cloture
            }
        }

        if (data.data.length > 0) {
            swal({
                title: '',
                text: $translate.instant("Voulez-vous vraiment clôturer ces bls"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonClass: 'btn btn-success',
                cancelButtonClass: 'btn btn-danger',
                buttonsStyling: false
            }).then(function () {
                WS.post('bl/executeSelectedCloture', data).then(
                    function (response) {
                        if (response.data.success) {
                            swal(
                                $translate.instant('Succès'),
                                $translate.instant("action effectué avec succés"),
                                'success'
                            );

                            vm.listBls();
                                var filtredData = [];
                            _.each(vm.bls, (element, index) => {
                                if (data.data.includes(element.code) == false) {
                                    filtredData.push(element);
                                }
                            })
                            vm.reinitialise_data();

                            vm.bls= filtredData;
                            $scope.$apply();
                        }

                    },
                    function () {
                        swal(
                            'Oups',
                            $translate.instant('Erreur de traitement!'),
                            'warning'
                        );
                    }
                );
            }, function (error) {
                console.log(error);
            });
        } else {
            swal(
                $translate.instant('Oups'),
                $translate.instant('Veuillez saisir au moins une bl'),
                'warning'
            )
        }
    }


    vm.exportListBls = function(tableId) {

        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function(s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var interval = (vm.bldate.date_debut && vm.bldate.date_fin) ? '__' + vm.bldate.date_debut + '__' + vm.bldate.date_fin : '-Tous-';
        var title = vm.current_user + interval +'-cloture-'+vm.type_cloture;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

}
export default {
    name: 'clotureBlsController',
    fn: clotureBlsController
};