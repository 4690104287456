import { ar_AR } from "../lang/ar";

function mapVisionController($compile, $translate, $scope, WS, $location, $http, Flash) {
    'ngInject';
    const vm = this;
    vm.findAllPosition = function () {
        WS.getData('mapvision/findAllPosition')
            .then(function (response) {
                if (response.status == 200) {
                    vm.pays = response.data.pays;
                    vm.users_position = response.data.users;

                    $('#container_maps').empty();
                    $('#container_maps').append('<div style="width: 100%; height: 550px" id="mapContainerCmds"></div>');

                    var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    var osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    var osm = L.tileLayer(osmUrl, {
                        maxZoom: 18,
                        attribution: osmAttrib
                    });
                    //console.log('from maps : ', vm.getpays)
                    var map = new L.Map('mapContainerCmds', {
                        center: new L.LatLng(vm.pays.latitude, vm.pays.longitude),
                        zoom: 8,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });

                    osm.addTo(map);
                    var MarkerUserIcon = L.ExtraMarkers.icon({
                        icon: 'fa-user',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    var MarkerClIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'green',
                        shape: 'square', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    var MarkerClHIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'red',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    _.each(vm.users_position, (user, indexUser) => {
                        // position d'utilisateur
                        /**
                         * users
                         */
                        if (user.latitude && user.latitude) {

                            var MarkerUser = L.marker([user.latitude, user.longitude], { icon: MarkerUserIcon }).addTo(map);

                            var infoUserMarker = $compile(`
                                                             <div id="bodyContent" style="width=400px;">
                                                                  <h3 class="text-center"> ${$translate.instant('Commercial')}</h3>
                                                                  <div><b>Code</b> : ${user.code}</div>
                                                                  <div><b>Comercial</b> : ${user.user}</div>
                                                                  <div><b>Dépot</b> : ${user.adresse}  ${user.libelle}</div>
                                                            </div>
                                                            `)($scope);
                            var popupInfoUser = L.popup({
                                maxWidth: 600,
                                minWidth: 300
                            }).setContent(infoUserMarker[0])
                            MarkerUser.bindPopup(popupInfoUser);
                        }
                        /**
                         * layers
                         */
                        /**
                         * add polygon and panel of draw
                         */
                        var geojsonMarkerOptions = {
                            radius: user.raduis
                        };
                        if (user.layer) {
                            if (user.raduis) {
                                var paramsdrawitem = {
                                    pointToLayer: function (feature, latlng) {
                                        return L.circle(latlng, geojsonMarkerOptions);
                                    }
                                }
                            } else {
                                var paramsdrawitem = {};
                            }
                            L.geoJson(JSON.parse(user.layer), paramsdrawitem).addTo(map);

                        }

                        /**
                         * client hors tournee
                         */
                        _.each(user.client_hors_tourne, (client, indexClient) => {


                            if (client.latitude && client.longitude) {
                                var MarkerUser = L.marker([client.latitude, client.longitude], { icon: MarkerClHIcon }).addTo(map);

                                var infoUserMarker = $compile(`
                                                                <div id="bodyContent" style="width=400px;">
                                                                    <div><b> ${$translate.instant('Code')}</b> : ${client.code}</div>
                                                                    <div><b> ${$translate.instant('Client')}</b> : ${client.client} ${client.magasin}</div>
                                                                    <div><b> ${$translate.instant('Crédit')}</b> : ${client.encours}</div>
                                                                    <div><b> ${$translate.instant('Dernier visite')}</b> : ${client.last_visite}</div>
                                                            </div>
                                                            `)($scope);
                                var popupInfoUser = L.popup({
                                    maxWidth: 600,
                                    minWidth: 300
                                }).setContent(infoUserMarker[0])
                                MarkerUser.bindPopup(popupInfoUser);
                            }
                        });

                        _.each(user.client_tourne, (client, indexClient) => {

                            if (client.latitude && client.longitude) {
                                var MarkerUser = L.marker([client.latitude, client.longitude], { icon: MarkerClIcon }).addTo(map);

                                var infoUserMarker = $compile(`
                                                            <div id="bodyContent" style="width=400px;">
                                                                <div><b> ${$translate.instant('Code')}</b> : ${client.code}</div>
                                                                <div><b> ${$translate.instant('Client')}</b> : ${client.client} ${client.magasin}</div>
                                                                <div><b> ${$translate.instant('Crédit')}</b> : ${client.encours}</div>
                                                                <div><b> ${$translate.instant('Dernier visite')}</b> : ${client.last_visite}</div>
                                                                <div><b> ${$translate.instant('Fréquence')}</b> : ${client.frequence}</div>
                                                                <div><b> ${$translate.instant('Jour')}</b> : ${client.code_jour}</div>
                                                        </div>
                                                        `)($scope);
                                var popupInfoUser = L.popup({
                                    maxWidth: 600,
                                    minWidth: 300
                                }).setContent(infoUserMarker[0])
                                MarkerUser.bindPopup(popupInfoUser);
                            }
                        });

                        /**
                         * client tournee
                         */
                    });

                    $scope.$apply();

                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
}
export default {
    name: 'mapVisionController',
    fn: mapVisionController
};