import { ar_AR } from "../lang/ar";

 function familleController(DatatableLang, $state, $scope, WS, $window, $translate) {
     'ngInject';
     const vm = this;
     vm.familles = {};
     vm.gammes = {};
     vm.famille={};
     vm.files = [];
     vm.filesupdate = [];
     vm.detail = {};
     vm.cas_0 = "se calcule en chargement et en bilan journalier (stat-camion)";
     vm.cas_1 = "ne se calcule pas en chargement et en bilan journalier (stat-camion)";
     vm.cas_2 = "ne se calcule pas en chargement et se calcule en bilan journalier (stat-camion)";
     vm.get = function() {
         WS.get('familles', vm.access)
             .then(function(response) {
                 if (response.status == 200) {
                     vm.familles = response.data;
                      _.each(vm.familles, (element, index) => {
                        vm.familles[index].hors_box = element.hors_box.toString();
                      })
                     $scope.$apply();
                     $('.table').DataTable({
                         "language": DatatableLang
                     });
                 } else {}
             })
             .then(null, function(error) {
                 console.log(error);
             });
     }

     vm.getGammes = function() {
         WS.get('gammes', vm.access)
             .then(function(response) {
                 if (response.status == 200) {
                     vm.gammes = response.data;
                     $scope.$apply();
                 } else {}
             })
             .then(null, function(error) {
                 console.log(error);
             });
     }

     vm.update = function(famille) {

        vm.detail.image = vm.filesupdate[0];

         WS.postwithimg('familles/' + famille.id, vm.detail, 'PUT')
             .then(function(response) {
                 if (response.status == 200) {
                     swal(
                         'Succès',
                         'Famille modifiée avec Succès',
                         'success'
                     ).then(function() {
                         $window.location.reload();
                     });
                 } else {}
             })
             .then(null, function(error) {
                 console.log(error);
             });
     }

     vm.delete = function(id) {

         swal({
             title: $translate.instant('famille'),
             text: $translate.instant("voulez vous vraiment supprimer cette famille!"),
             type: 'warning',
             showCancelButton: true,
             confirmButtonColor: '#3085d6',
             cancelButtonColor: '#d33',
             confirmButtonText: $translate.instant('Oui'),
             cancelButtonText: $translate.instant('Non'),
             confirmButtonClass: 'btn btn-success',
             cancelButtonClass: 'btn btn-danger',
             buttonsStyling: false
         }).then(function() {
             WS.delete('familles/' + id).then(
                 function() {
                     swal(
                        $translate.instant('Succès'),
                        $translate.instant('Famille supprimée avec Succès.'),
                         'success'
                     ).then(function() {
                         $window.location.reload();
                     });
                 },
                 function() {
                     swal(
                        $translate.instant('Attention!'),
                        $translate.instant('vous ne pouvez pas supprimer cette famille!!'),
                         'warning'
                     );
                 }
             );
         });
     }

     vm.ajouter = function(famille) {

        vm.famille.image = vm.files[0];

        WS.postwithimg('familles', vm.famille, 'POST')
        .then(function (response) {
            if (response.data.success == true) {

                swal(
                    $translate.instant('Succès'),
                    $translate.instant('Famille ajoutée avec Succès'),
                     'success'
                 ).then(function() {
                     $window.location.reload();
                 });
            } else {
                swal(
                    $translate.instant('Error'), 
                    $translate.instant(response.data.message),
                    'error'
                 );
            }
        }, function (error) {
            console.log(error);
        });
     }

     $scope.uploadedFile = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.famille.image = event.target.result
            $scope.$apply(function () {
                vm.files = element.files;

            });
        }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };

    $scope.uploadedupdateFile = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.detail.image = event.target.result
            $scope.$apply(function () {
                vm.filesupdate = element.files;

            });
        }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };

    
 }
 export default {
     name: 'familleController',
     fn: familleController
 };