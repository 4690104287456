function biController($sce, $state, $scope, WS, $location, $http, Flash) {
    'ngInject';
    const vm = this;


    vm.trustSrc = function(src) {
        return $sce.trustAsResourceUrl(src);
    };

}
export default {
    name: 'biController',
    fn: biController
};