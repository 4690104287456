function gammeController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.gammes = {};

    vm.get = function() {
        WS.get('gammes', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                    $('.table_gamme').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(gamme) {
        WS.put('gammes/' + gamme.id, gamme)
            .then(function(response) {
                if (response.status == 200) {

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Gamme',
            text: "voulez vous vraiment supprimer cette Gamme!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('gammes/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Gamme supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette Gamme!!',
                        'warning'
                    );
                }
            );
        });
    }

    vm.importfromExcel = function() {

        WS.get('gammes/import').then(
            function(response) {

                alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
            },
            function(error) {
                console.log(error);
            }
        );
    };

    vm.ajouter = function(gamme) {
        WS.post('gammes', gamme)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Gamme ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.store = function() {
        vm.detail.fichier = vm.files[0];
        console.log(vm.detail.fichier);
        WS.postwithimg('gammes/import', vm.detail, 'POST')
            .then(function(data) {
                if (data.status == 200) {
                    swal(
                        'Succée',
                        'Societe ' + msg + ' avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            }, function(error) {
                console.log(error);
            });
    };

    $scope.uploadedFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.detail.fichier = event.target.result

            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    }

}
export default {
    name: 'gammeController',
    fn: gammeController
};