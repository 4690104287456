function chargeController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
    vm.charges = {};
    vm.types = {};
    vm.centre_cout = {};
    vm.analyses = {};

    vm.get = function() {
        WS.get('charges', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.charges = response.data;
                    $scope.$apply();
                    $('.table_charges').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getAnalyse = function(date) {
        WS.get('charges/analyses/'+date, vm.access)
            .then(function(response) {
                if (response.status == 200) {
                   
                    vm.commerciaux = response.data;
                    vm.analyses = response.data.allCharges;
                    
                   // console.log(vm.commerciaux);
                    //console.log(vm.analyses);
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getTypes = function() {
        WS.get('charges/types', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.types = response.data;
                    $scope.$apply();
                    $('.table_charges_types').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getCentreCout = function() {
        WS.get('charges/centre_cout', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.centre_cout = response.data;
                    $scope.$apply();
                    $('.table_charges_centre').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getdepots = function() {
        WS.get('depots/all', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.depots = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

 
    vm.ajouterCharge = function(charge) {
        WS.post('charges', charge)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge ajoutée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.ajouterTypeCharge = function(type) {
        WS.post('charges/types', type)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Type charge ajoutée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.ajouterCentreCout = function(type) {
        WS.post('charges/centre_cout', type)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Centre de cout ajouté avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.updateTypeCharge = function(type) {
        WS.put('charges/types', type)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Type charge modifié avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.updateCentreCout = function(type) {
        WS.put('charges/centre_cout', type)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Centre de cout modifié avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Error'),
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.initMonth = function() {
        $(document).ready(function(){
            vm.month = moment().format('YYYY-MM');
            $(".datepicker-dashb").datepicker({
                autoClose: true,
                onSelect: function(date) {
                    vm.month = date;
                    $scope.$apply();
                }
            });   
        })
      
    }
   
    vm.deleteTypeCharge = function(id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cet type!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('charges/types/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('type Supprimé avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cet type'),
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.deleteCentreCout = function(id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cet Centre de Cout!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('charges/centre_cout/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Centre de Cout Supprimé avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cet Centre de Cout'),
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }
}
export default {
    name: 'chargeController',
    fn: chargeController
};