function depencesController(DatatableLang, $translate, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.depences = {};
    vm.current_date = moment().format('YYYY-MM-DD');
  
    
    vm.get = function() {
        WS.get('depences/top50')
            .then(function(response) {
                if (response.status == 200) {
                    vm.depences = response.data;
                    $scope.$apply();
                    /*$('.table').DataTable({
                        "language": DatatableLang,
                        order : ['3', 'desc']
                    });*/
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getTotalBycolonne = function(list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['montant']);
        }
        return total;
    }
    vm.getDepenceByDate = function(date_debut = null, date_fin = null, commercial = null) {
        var url = 'depences/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        console.log(url);
        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.depences = response.data;
                    
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.getCoutVisite = (date_debut = null, date_fin = null, code) => {
        WS.get('depences/cumul/' + date_debut + '/' + date_fin + '/' + code)
            .then(function(response) {
                vm.cumul = response.data;
                jQuery.noConflict();
                $('#cumulChargeModal').modal('show');
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportDepenceByDate = function(date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'depences/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function(response) {
            var filename = "List Dépences -" + date_debut + '-' + date_fin;

            var file = {
                sheetid: "List Dépences By commercial-" + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: "List Dépences By commercial-" + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [

                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'date', title: 'Date' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'type', title: 'Type' },
                    { columnid: 'commentaire', title: 'Commentaire' },
                   
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function(error) {
            console.log(error);
        });
    };



    vm.getCumulDepence = (date_debut = null, date_fin = null, code) => {
        WS.get('depences/cumul/' + code + "/" + date_debut + "/" + date_fin)
            .then(function(response) {
                vm.cumul = response.data;

                jQuery.noConflict();
                $('#cumulModal').modal('show');
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

}
export default {
    name: 'depencesController',
    fn: depencesController
};