function affectationCommercialCamionController($translate, $state, $scope, WS, $window, DatatableLang) {
    'ngInject';
    const vm = this;
    vm.affectations = {};


    vm.get = function() {
        WS.get('affectation_commercial_depot/list', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.affectations = response.data;
                    $scope.$apply();
                    $('.table_affectations').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    

    vm.getdepots = function() {
        WS.get('depots/all', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.depots = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

 
    vm.ajouterAffectation = function(aff) {
        WS.post('affectation_commercial_depot', aff)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('charge ajoutée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    
    vm.updateAffectations = function(type) {
        WS.put('affectation_commercial_depot/'+type.id, type)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Affectation modifié avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
   
    vm.deleteAffectation = function(id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cet Affectation!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('affectation_commercial_depot/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Affectation Supprimé avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cet Affectation'),
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    
}
export default {
    name: 'affectationCommercialCamionController',
    fn: affectationCommercialCamionController
};