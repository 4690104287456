function delegationController($localStorage, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.delegations = {};
    vm.files = [];

    vm.searchbox = '';
    vm.get = function(page=1) {
      var url = 'delegations?page='+page;
        if(vm.searchbox != ''){
           url +='&search='+vm.searchbox;
        }
        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.delegations = response.data.data;
                    vm.current_page = response.data.current_page;
                    vm.next = vm.current_page + 1;
                    vm.from     = response.data.form;
                    vm.last_page = Number(response.data.last_page);
                    vm.next_page_url = response.data.next_page_url;
                    vm.per_page = Number(response.data.per_page);
                    vm.prev_page_url = response.data.prev_page_url;
                    vm.to = response.data.to;
                    vm.total = response.data.total;
                    vm.paginate = [];
                    for(var i=1; i <= vm.last_page; i++){
                       vm.paginate.push(i);
                     }
                     $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getGouvernorats = function() {
        WS.get('gouvernorats')
            .then(function(response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.update = function(delegation) {
        WS.put('delegations/' + delegation.id, delegation)
            .then(function(response) {
                if (response.status == 200) {
                    //vm.getOnedelegation(delegation.id);
                    swal(
                      'Succès',
                      'delegation modifiée avec Succès',
                      'Succès'
                    ).then(function(){
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id){
          swal({
              title: 'Supprimer',
              text: "Voullez vous vraiment supprimer cette delegation!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Oui',
              cancelButtonText: 'Non',
              confirmButtonClass: 'btn btn-success',
              cancelButtonClass: 'btn btn-danger',
              buttonsStyling: false
          }).then(function () {
              WS.delete('delegations/' + id).then(
                  function(){
                      swal(
                              'Supprimée!',
                              'Delegation Supprimée.',
                              'success'
                      ).then(function(){
                          $window.location.reload();
                      });
                  },
                  function(){
                      swal(
                          'Attention!',
                          'Vous ne pouvez pas supprimer cette delegation',
                          'warning'
                      );
                  }
              );
          });
    };

    vm.ajouter = function(delegation) {
        WS.post('delegations', delegation)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                      'Succès',
                      'delegation ajoutée avec Succès',
                      'success'
                    ).then(function(){
                        $window.location.reload();
                    });
                    
                } else if(response.data.success == false) {
                    swal('Error', response.data.message,'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    $scope.moveCSV = function(element) {
        var reader = new FileReader();
            reader.onload = function(event) {
              vm.csvfile.file = event.target.result;
              $scope.$apply(function() {
                vm.files = element.files;
              });
            }
        reader.readAsDataURL(element.files[0]);
    };

    vm.savecsv = function(){
        vm.csvfile.file = vm.files[0];
        WS.postwithimg('delegations/saveCsv' , vm.csvfile , "POST")
            .then(function(response){
                if (response.data.success==true) {
                    vm.traitform=false;
                    vm.csvfile={}
                    $scope.$apply();
                    swal(
                      'Succès',
                      response.data.message,
                      'success'
                    ).then(function(){
                      WS.getScache('delegations')
                          .then(function(response) {
                              if (response.status == 200) {
                                  vm.delegations = response.data;
                                  $scope.$apply();
                              } else {}
                          })
                          .then(null, function(error) {
                              console.log(error);
                          });
                    });  
                } else {
                    vm.traitform=false;
                    vm.csvfile={}
                    $scope.$apply();
                    swal(
                      'Warning',
                      response.data.message,
                      'warning'
                    ); 
                }
           },function(error){
                console.log(error);
           });
    };

    vm.downloadCsvModel = function(){
        var filename = 'Delegation';
        alasql('SELECT * INTO XLS("'+ filename +'.xls",?) FROM ?',[csvModel, []]); 
    };

    var csvModel = {
              sheetid: 'Delegation',
              headers: true,
              column: {
                  style:'font-size:20px;background:#d1d1d1;'
              },
              columns: [
                  {columnid:'code', title: 'Code'},
                  {columnid:'libelle',title: 'Libelle'},
                  {columnid:'gouvernorat', title: 'gouvernorat'}
              ],
              rows: {
                  style: function(sheet,row,rowidx){
                      return 'text-align:center;background:'+(rowidx%2?'#fff':'#f1f1f1')+';height:100px!important;';
                  }
              }
    };

}
export default {
    name: 'delegationController',
    fn: delegationController
};
