

import constants from '../constants';


function ConvertNumber() {

  return function (value, type='quantite') {

    if (type === 'poids') {
      // Apply the POids filter
      value = value ? Number(value) : 0;

      var new_number = 0;
      var unite = '';
      if (value >= 1000 && value < 1000000) {
        var new_number = value / 1000;
        new_number = new_number.toFixed(2);
        unite = 'kg';
      } else if (value >= 1000000) {
        var new_number = value / 1000000;
        new_number = new_number.toFixed(2);
        unite = 'T';
      } else if (value < 1000) {
        var new_number = value;
        new_number = new_number.toFixed(2);
        unite = 'g';
      }
  
      if (Number(new_number) == 0) {
        return '';
      } else {
        return new_number + ' '+ unite;
      }
    } else if (type === 'chiffre') {
      // Apply the decimal filter with 2 decimal places
      var chiffre = value.toFixed(constants.decimal ? constants.decimal: 3);
          chiffre =  chiffre.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") ; //? parseFloat(value).toFixed(3).toString().replace('.', ',') : null;
       // console.log('second_value', value)
        return chiffre;

    } else {
      // Default case, no additional filter
      return value;
    }
  };


}

export default {
  name: 'ConvertNumber',
  fn: ConvertNumber
};

