function morrischartDirective() {
  
    return {
        restrict: 'EAC',
        scope:{
            gamme: '=',
            gammeyear: '='
        },
        link: function(scope, elem, attrs) {
            console.log('for directiiiive morrischart');
            console.log(attrs);
            console.log(attrs.gamme);
            //console.log(attrs.gammeyear);
            console.log({
                    element: 'morris4',
                    data:  attrs.gamme ,
                    resize: true,
                    colors: ["#31C0BE", "#c7254e", "#98a0d3"],
                });
             Morris.Donut({
                    element: 'morris4',
                    data:  attrs.gamme ,
                    resize: true,
                    colors: ["#31C0BE", "#c7254e", "#98a0d3"],
                });

            /* Morris.Donut({
                    element: 'morris5',
                    data:  attrs.gammeyear  ,
                    resize: true,
                    colors: ["#31C0BE", "#c7254e", "#98a0d3"],
                });
*/
 
            elem.append('<div class="morris4');
            
        }
    };
}

export default {
    name: 'morrischart',
    fn: morrischartDirective
};
