function noteController($sce, DatatableLang, $state, $scope, WS, $window) {
  "ngInject";
  const vm = this;
  vm.regions = {};


    vm.trustSrc = function(src) {
        return $sce.trustAsResourceUrl(src);
    };

  vm.getAllNotesEnvoye = function () {
    WS.getScache("notes/getAllNotesEnvoye")
      .then(function (response) {
        vm.notes_envoyes = response.data;
        $scope.$apply();
        $(".table-notes_envoye").DataTable({
          language: DatatableLang,
          paging: true,
          searching: true,
          order: [],
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };
  vm.getAllNotesRecu = function () {
    WS.getScache("notes/getAllNotesRecu")
      .then(function (response) {
        vm.notes_recu = response.data;
        $scope.$apply();
        $(".table-notes_recu").DataTable({
          language: DatatableLang,
          paging: true,
          searching: true,
          order: [],
        });
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getNoteVocale = (code, vocale) => {
    console.log('note_code', code)

    var buffer = new Buffer( vocale );
    var bufferBase64 = atob(buffer);

    console.log('bufferBase64', bufferBase64)

  }
  vm.playAudio = function (path) {
    console.log(path);
    var audio = new Audio(path);
    audio.play();
  };

  vm.getTrace = function () {
    WS.get("traces/coresspondance")
      .then(function (response) {
        if (response.status == 200) {
          vm.traces = response.data;
          $scope.$apply();
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getOne = function () {
    WS.getScache("notes/getOne/" + $state.params.id)
      .then(function (response) {
        vm.note = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.changeEtat = function (etat) {
    WS.put("notes/changeEtat/" + $state.params.id, { etat: etat })
      .then(function (response) {
        console.log(response.data);
        swal("Succès", response.data.message, "success");
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.update = function (region) {
    WS.put("regions/" + region.id, region)
      .then(function (response) {
        if (response.status == 200) {
          swal("Succès", "Region modifiée avec Succès", "success");
        } else {
        }
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.delete = function (id) {
    swal({
      title: "Supprimer",
      text: "Voulez vous vraiment supprimer cette Note!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    }).then(
      function () {
        WS.delete("notes/" + id).then(
          function () {
            swal("Succès", "Note Supprimée avec Succès", "success").then(
              function () {
                $window.location.reload();
              }
            );
          },
          function () {
            swal(
              "Attention!",
              "Vous ne pouvez pas supprimer cette Note",
              "warning"
            );
          }
        );
      },
      function (error) {
        console.log(error);
      }
    );
  };

  vm.storeNote = function (note) {
      console.log(note, vm.blob)
        note.vocale = vm.blob;
        //note.vocale_64 = vm.bufferBase64;
        //note.decoded = atob(vm.bufferBase64)
        //console.log(note, vm.blob['tmp_name'])

    WS.postwithimg("notes/storeNote", note)
      .then(function (response) {
        swal("Succé", "note ajoutée avec succées", "success").then(function () {
          $window.location.reload();
        });
        //$window.location.reload();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.depots = function () {
    WS.get("depots/selectDepot")
      .then(function (response) {
        vm.depots = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.clients = function () {
    WS.get("clients/selectClients")
      .then(function (response) {
        vm.clients = response.data;
        $scope.$apply();
      })
      .then(null, function (error) {
        console.log(error);
      });
  };

  vm.getCommercialByDepot = function (code, liste) {
    if (!liste) return;
    for (var i = 0; i < liste.length; i++) {
      if (liste[i]["depot_code"] == code) {
        return liste[i];
      }
    }
  };

  vm.resultBlob = [];
  vm.startAudio = () => {
    var device = navigator.mediaDevices.getUserMedia({ audio: true });
    vm.items = [];
    vm.blob = new Blob();
    device.then((stream) => {
      vm.recorder = new MediaRecorder(stream);
      vm.recorder.ondataavailable = (e) => {
        vm.items.push(e.data);
        if (vm.recorder.state == "inactive") {
          // vm.blob = new Blob(vm.items, {type: 'audio/webm'})
          // vm.resultBlob.push(vm.blob)
          // var audio = document.getElementById('audio')
          // audio.innerHTML = '';
          // var mainaudio=document.createElement('audio')
          // mainaudio.setAttribute('controls', 'controls')
          // audio.append(mainaudio)
          // mainaudio.innerHTML = `
          //     <source src="${URL.createObjectURL(vm.blob)}" type="video/webm" />
          // `;
        }
      };

      vm.recorder.onstop = function (e) {
        console.log("data available after MediaRecorder.stop() called.");

        vm.blob = new Blob(vm.items, { type: "audio/webm" });
        vm.resultBlob.push(vm.blob);
        var audio = document.getElementById("audio");
        audio.innerHTML = "";
        var mainaudio = document.createElement("audio");
        mainaudio.setAttribute("controls", "controls");
        audio.append(mainaudio);
        mainaudio.innerHTML = `
                        <source src="${URL.createObjectURL(
                          vm.blob
                        )}" type="video/webm;" />
                    `;
        console.log("recorder stopped", vm.items, vm.blob);

        var buffer = new Buffer( [vm.blob] );
        vm.bufferBase64 = buffer.toString('base64');
      };

      vm.recorder.start();
    });
  };

  vm.stopAudio = () => {
    vm.recorder.stop();

    vm.blob = new Blob(vm.items, { type: "audio/webm" });
    console.log("items", vm.items);

    console.log("resultBlob 1", vm.blob);
    console.log("resultBlob 2", vm.resultBlob);
  };
}
export default {
  name: "noteController",
  fn: noteController,
};
