function societeController(DatatableLang, $state, $scope, WS, $filter, $window) {
    'ngInject';
    const vm = this;
    vm.societes = {};
    vm.detail = {};
    vm.files = [];

    WS.get('societes')
        .then(function(response) {
            if (response.status == 200) {
                vm.socs = response.data;
                $scope.$apply();
            } else {}
        })
        .then(null, function(error) {
            console.log(error);
        });



    vm.get = function() {
        WS.get('societes')
            .then(function(response) {
                if (response.status == 200) {
                    vm.societes = response.data;
                    $scope.$apply();
                    $('.table_societes').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getDetails = function(id) {
        WS.get('societes/' + $state.params.id)
            .then(function(response) {
                if (response.status == 200) {
                    vm.detail = response.data;
                    vm.detail.kilometrage       = vm.detail.kilometrage.toString();
                    vm.detail.commande_stock    = vm.detail.commande_stock.toString();
                    vm.detail.bloquage_commande = vm.detail.bloquage_commande.toString();
                    vm.detail.remise_espece     = vm.detail.remise_espece.toString();
                    vm.detail.authorization_stock = vm.detail.authorization_stock.toString();
                    vm.detail.ajout_gratuite = vm.detail.ajout_gratuite.toString();
                    vm.detail.jour_repos = vm.detail.jour_repos.toString();
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.store = function() {
        vm.detail.logo = vm.files[0];
        var route;
        var method;
        var msg;
        if ($state.params.id) {
            route = 'societes/' + $state.params.id;
            method = 'PUT';
            msg = 'modifiée';
        } else {
            route = "societes";
            method = "POST";
            msg = 'ajoutée';
        }

        WS.postwithimg(route, vm.detail, method)
            .then(function(data) {
                if (data.status == 200) {
                    swal(
                        'Succès',
                        'Societe ' + msg + ' avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            }, function(error) {
                console.log(error);
            });
    };

    $scope.uploadedFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.detail.logo = event.target.result
            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    }

    vm.save = function(detail) {
        WS.post('societes', vm.detail)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'societe ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
    vm.update = function(detail) {

        WS.put('societes/' + $state.params.id, vm.detail)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'societe mis à jour avec Succès',
                        'success'
                    )
                    window.location.reload();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette societe!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('societes/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'societe Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette societe',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }
}
export default {
    name: 'societeController',
    fn: societeController
};