function rapportvisiteController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.gammes = {};
    vm.filter = {};
    vm.get = function() {
        WS.get('rapportsvisite/all', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.rapportsvisite = response.data;
                    $scope.$apply();
                    $('.table_rapportsvisite').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.mapForRapporttournee = function() {
        $timeout(function() {
            NgMap.getMap({ id: 'mapforrapporttournee' }).then(function(response) {
                google.maps.event.trigger(response, 'resize');
                response.setCenter({ lat: Number(vm.getpays.latitude), lng: Number(vm.getpays.longitude) });
            });
        }, 7000);

    };

    vm.getcommercial = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.commercial = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.gettaches = function() {
        WS.get('rapportsvisite/getAlltaches', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.taches = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getRapportJournalier = function(date, code) {
        var url = 'rapportsvisite/rapportvisitedetails' + '/' + date + '/' + code;
        WS.get(url, vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.rapportjournalier = response.data.visites;
                    vm.centerrapvisite = response.data.center.center;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getDetailsVisite = (user_code,date, client_code) => {
        WS.getData('rapportsvisite/getDetailsVisite', {user_code,date, client_code})
            .then(function(response) {
                if (response.status == 200) {
                    vm.details = response.data;
                    console.log(vm.details);
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.delete = function(id) {
        swal({
            title: 'Element',
            text: "voulez vous vraiment supprimer cet Element!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('rapportsvisite/' + id).then(
                function() {
                    swal(
                        'Succé',
                        'Element supprimé avec succées.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cet Element!!',
                        'warning'
                    );
                }
            );
        });
    }

    vm.ajouter = function(rapport) {
        WS.post('rapportsvisite', rapport)
            .then(function(response) {
                if (response.data.status == true) {
                    $window.location.reload();

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.store = function() {
        vm.detail.fichier = vm.files[0];
        console.log(vm.detail.fichier);
        WS.postwithimg('gammes/import', vm.detail, 'POST')
            .then(function(data) {
                if (data.status == 200) {
                    swal(
                        'Succée',
                        'Societe ' + msg + ' avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            }, function(error) {
                console.log(error);
            });
    };



}
export default {
    name: 'rapportvisiteController',
    fn: rapportvisiteController
};