function realisationController($timeout, $state, $scope, WS, $location, $http, Flash) {
    'ngInject';
    const vm = this;
    vm.search_month = moment().format("YYYY-MM");

    vm.colspan = 6;
    vm.timeout_gauge = 2000;
    vm.b_valeur = true;
    vm.b_quantite = true;
    vm.b_dn = false;
    vm.b_client = false;
    vm.b_recouvrement = false;

    vm.recalculateColspan = function () {
        var total = 0;
        total += (vm.b_valeur) ? 3 : 0;
        total += (vm.b_quantite) ? 3 : 0;
        total += (vm.b_dn) ? 3 : 0;
        total += (vm.b_client) ? 3 : 0;
        total += (vm.b_recouvrement) ? 3 : 0;

        vm.colspan = total;
    };

    vm.initDatepicker = function () {
        $(document).ready(function () {
            $(".datepicker-dashb").datepicker({
                autoClose: true,
                onSelect: function (date) {
                    vm.search_month = date;
                    $scope.$apply();
                }
            });
        });
    };
    vm.initDatepicker();

    vm.getAllObjectifs = function (categorie = null) {
        var data = { month: vm.search_month };
        if(categorie){
            data.categorie = categorie.categorie;
            data.code = categorie.code;
            $('.'+categorie.categorie).css('background', 'green!important');
            $('.'+categorie.categorie).css('background-color', 'green!important');
        };
        WS.getData('realisation/getAllObjectifs', data)
            .then(function (response) {
                vm.multi_objectifs = response.data;

                /**
                 * total des objectifs
                */
                vm.total_obj_valeur_month = 0;
                vm.total_obj_valeur_year = 0;
                vm.total_obj_quantite_month = 0;
                vm.total_obj_quantite_year = 0;
                vm.total_obj_dn_month = 0;
                vm.total_obj_dn_year = 0;
                vm.total_obj_nv_client_month = 0;
                vm.total_obj_nv_client_year = 0;
                vm.total_obj_recouvrement_month = 0;
                vm.total_obj_recouvrement_year = 0;

                /**
                 * total des objectifs réaliser
                */
                vm.total_valeur_month = 0;
                vm.total_valeur_year = 0;
                vm.total_quantite_month = 0;
                vm.total_quantite_year = 0;
                vm.total_dn_month = 0;
                vm.total_dn_year = 0;
                vm.total_nv_client_month = 0;
                vm.total_nv_client_year = 0;
                vm.total_recouvrement_month = 0;
                vm.total_recouvrement_year = 0;

                _.each(vm.multi_objectifs, (element, index) => {
                    /**
                     * total des objectifs
                    */
                    vm.total_obj_valeur_month += (element.obj_valeur_month != null) ? Number(element.obj_valeur_month) : 0;
                    vm.total_obj_valeur_year += (element.obj_valeur_year != null) ? Number(element.obj_valeur_year) : 0;
                    vm.total_obj_quantite_month += (element.obj_quantite_month != null) ? Number(element.obj_quantite_month) : 0;
                    vm.total_obj_quantite_year += (element.obj_quantite_year != null) ? Number(element.obj_quantite_year) : 0;
                    vm.total_obj_dn_month += (element.obj_dn_month != null) ? Number(element.obj_dn_month) : 0;
                    vm.total_obj_dn_year += (element.obj_dn_year != null) ? Number(element.obj_dn_year) : 0;
                    vm.total_obj_nv_client_month += (element.obj_nv_client_month != null) ? Number(element.obj_nv_client_month) : 0;
                    vm.total_obj_nv_client_year += (element.obj_nv_client_year != null) ? Number(element.obj_nv_client_year) : 0;
                    vm.total_obj_recouvrement_month += (element.obj_recouvrement_month != null) ? Number(element.obj_recouvrement_month) : 0;
                    vm.total_obj_recouvrement_year += (element.obj_recouvrement_year != null) ? Number(element.obj_recouvrement_year) : 0;

                    /**
                     * total des objectifs réaliser
                    */
                    vm.total_valeur_month += (element.valeur_month != null) ? Number(element.valeur_month) : 0;
                    vm.total_valeur_year += (element.valeur_year != null) ? Number(element.valeur_year) : 0;
                    vm.total_quantite_month += (element.quantite_month != null) ? Number(element.quantite_month) : 0;
                    vm.total_quantite_year += (element.quantite_year != null) ? Number(element.quantite_year) : 0;
                    vm.total_dn_month += (element.dn_month != null) ? Number(element.dn_month) : 0;
                    vm.total_dn_year += (element.dn_year != null) ? Number(element.dn_year) : 0;
                    vm.total_nv_client_month += (element.nv_client_month != null) ? Number(element.nv_client_month) : 0;
                    vm.total_nv_client_year += (element.nv_client_year != null) ? Number(element.nv_client_year) : 0;
                    vm.total_recouvrement_month += (element.recouvrement_month != null) ? Number(element.recouvrement_month) : 0;
                    vm.total_recouvrement_year += (element.recouvrement_year != null) ? Number(element.recouvrement_year) : 0;
                })

                /**
                 * pourcentage  : total realiser DIVISER PAR total objectifs
                 */
                // console.log(vm.total_obj_dn_year)
                // console.log(vm.total_dn_year);
                //valeurs
                vm.percent_valeur_month = (vm.total_obj_valeur_month == 0) ? 0 : vm.total_valeur_month * 100 / vm.total_obj_valeur_month;
                vm.percent_valeur_year = (vm.total_obj_valeur_year == 0) ? 0 : vm.total_valeur_year * 100 / vm.total_obj_valeur_year;
                //quantite
                vm.percent_quantite_month = (vm.total_obj_quantite_month == 0) ? 0 : vm.total_quantite_month * 100 / vm.total_obj_quantite_month;
                vm.percent_quantite_year = (vm.total_obj_quantite_year == 0) ? 0 : vm.total_quantite_year * 100 / vm.total_obj_quantite_year;
                //dn
                vm.percent_dn_month = (vm.total_obj_dn_month == 0) ? 0 : vm.total_dn_month * 100 / vm.total_obj_dn_month;
                vm.percent_dn_year = (vm.total_obj_dn_year == 0) ? 0 : vm.total_dn_year * 100 / vm.total_obj_dn_year;
                //nv_client
                vm.percent_nv_client_month = (vm.total_obj_nv_client_month == 0) ? 0 : vm.total_nv_client_month * 100 / vm.total_obj_nv_client_month;
                vm.percent_nv_client_year = (vm.total_obj_nv_client_year == 0) ? 0 : vm.total_nv_client_year * 100 / vm.total_obj_nv_client_year;
                //Recouvrement
                vm.percent_recouvrement_month = (vm.total_obj_recouvrement_month == 0) ? 0 : vm.total_recouvrement_month * 100 / vm.total_obj_recouvrement_month;
                vm.percent_recouvrement_year = (vm.total_obj_recouvrement_year == 0) ? 0 : vm.total_recouvrement_year * 100 / vm.total_obj_recouvrement_year;


                /**
                 * convert to float
                 */
                vm.percent_valeur_month = vm.percent_valeur_month.toFixed(2);
                vm.percent_valeur_year = vm.percent_valeur_year.toFixed(2);
                //quantite
                vm.percent_quantite_month = vm.percent_quantite_month.toFixed(2);
                vm.percent_quantite_year = vm.percent_quantite_year.toFixed(2);
                //dn
                vm.percent_dn_month = vm.percent_dn_month.toFixed(2);
                vm.percent_dn_year = vm.percent_dn_year.toFixed(2);
                //nv_client
                vm.percent_nv_client_month = vm.percent_nv_client_month.toFixed(2);
                vm.percent_nv_client_year = vm.percent_nv_client_year.toFixed(2);
                //Recouvrement
                vm.percent_recouvrement_month = vm.percent_recouvrement_month.toFixed(2);
                vm.percent_recouvrement_year = vm.percent_recouvrement_year.toFixed(2);
                //init gauges : 
                vm.gauge_valeur_month();
                vm.gauge_valeur_year();

                vm.gauge_quantite_month();
                vm.gauge_quantite_year();

                vm.gauge_dn_month();
                vm.gauge_dn_year();

                vm.gauge_nv_client_month();
                vm.gauge_nv_client_year();

                vm.gauge_recouvrement_month();
                vm.gauge_recouvrement_year();

                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getCategories = function () {
        
        WS.getData('realisation/getCategoriesObj2', { month: vm.search_month })
            .then(function (response) {
                if (response.status == 200) {
                    var categories = response.data;
                    var data = [];
                    _.each(categories.prduits, element => { data.push(element); });
                    _.each(categories.gammes, element => { data.push(element); });
                    _.each(categories.familles, element => { data.push(element); });
                    _.each(categories.sousfamilles, element => { data.push(element); });
                    _.each(categories.marques, element => { data.push(element); });
                    _.each(categories.groupes, element => { data.push(element); });
                    vm.categories = data;
                    console.log(vm.categories);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /**
     * Valeurs gauge
     */
    vm.gauge_valeur_month = function () {
        var gaugeChart = AmCharts.makeChart("gauge_valeur_month", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_valeur_month);
                            gaugeChart.axes[0].setBottomText(vm.percent_valeur_month + " %");
                        }
                    }
                }
            }
        }
    };

    vm.gauge_valeur_year = function () {
        var gaugeChart = AmCharts.makeChart("gauge_valeur_year", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_valeur_year);
                            gaugeChart.axes[0].setBottomText(vm.percent_valeur_year + " %");
                        }
                    }
                }
            }
        }
    };

    /**
     * quantite gauge
     */
    vm.gauge_quantite_month = function () {
        var gaugeChart = AmCharts.makeChart("gauge_quantite_month", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_quantite_month);
                            gaugeChart.axes[0].setBottomText(vm.percent_quantite_month + " %");
                        }
                    }
                }
            }
        }
    };

    vm.gauge_quantite_year = function () {
        var gaugeChart = AmCharts.makeChart("gauge_quantite_year", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_quantite_year);
                            gaugeChart.axes[0].setBottomText(vm.percent_quantite_year + " %");
                        }
                    }
                }
            }
        }
    };

    /**
     * dn gauge
     */
    vm.gauge_dn_month = function () {
        var gaugeChart = AmCharts.makeChart("gauge_dn_month", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_dn_month);
                            gaugeChart.axes[0].setBottomText(vm.percent_dn_month + " %");
                        }
                    }
                }
            }
        }
    };

    vm.gauge_dn_year = function () {
        var gaugeChart = AmCharts.makeChart("gauge_dn_year", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_dn_year);
                            gaugeChart.axes[0].setBottomText(vm.percent_dn_year + " %");
                        }
                    }
                }
            }
        }
    };

    /**
     * nv_client gauge
     */
    vm.gauge_nv_client_month = function () {
        var gaugeChart = AmCharts.makeChart("gauge_nv_client_month", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_nv_client_month);
                            gaugeChart.axes[0].setBottomText(vm.percent_nv_client_month + " %");
                        }
                    }
                }
            }
        }
    };

    vm.gauge_nv_client_year = function () {
        var gaugeChart = AmCharts.makeChart("gauge_nv_client_year", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_nv_client_year);
                            gaugeChart.axes[0].setBottomText(vm.percent_nv_client_year + " %");
                        }
                    }
                }
            }
        }
    };


    /**
    * recouvrement gauge
    */
    vm.gauge_recouvrement_month = function () {
        var gaugeChart = AmCharts.makeChart("gauge_recouvrement_month", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_recouvrement_month);
                            gaugeChart.axes[0].setBottomText(vm.percent_recouvrement_month + " %");
                        }
                    }
                }
            }
        }
    };

    vm.gauge_recouvrement_year = function () {
        var gaugeChart = AmCharts.makeChart("gauge_recouvrement_year", {
            "type": "gauge",
            "theme": "none",
            "axes": [{
                "axisThickness": 1,
                "axisAlpha": 0.2,
                "tickAlpha": 0.2,
                "valueInterval": 10,
                "radius": "100%",
                "startAngle": -90,
                "endAngle": 90,
                "bands": [{
                    "color": "#cc4748",
                    "endValue": 40,
                    "startValue": 0
                }, {
                    "color": "#fdd400",
                    "endValue": 70,
                    "startValue": 40
                }, {
                    "endValue": 100,
                    "color": "#84b761",
                    "innerRadius": "95%",
                    "startValue": 70
                }],
                "bottomText": "0 km/h",
                "bottomTextYOffset": -20,
                "endValue": 100
            }],
            "arrows": [{}],
            "export": {
                "enabled": false
            }
        });
        $timeout(function () {
            randomValue();
        }, vm.timeout_gauge);
        function randomValue() {
            if (gaugeChart) {
                if (gaugeChart.arrows) {
                    if (gaugeChart.arrows[0]) {
                        if (gaugeChart.arrows[0].setValue) {
                            gaugeChart.arrows[0].setValue(vm.percent_recouvrement_year);
                            gaugeChart.axes[0].setBottomText(vm.percent_recouvrement_year + " %");
                        }
                    }
                }
            }
        }
    };
}
export default {
    name: 'realisationController',
    fn: realisationController
};