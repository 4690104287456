function zoneController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.zones = {};



    vm.get = function() {
        WS.get('zones', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getRegions = function() {
        WS.get('regions', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.regions = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getusers = function() {
        WS.get('users/query/grade="LIV"', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.users = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(zone) {
        WS.put('zones/' + zone.id, zone)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Zone modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette zone!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('zones/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'zone Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer ce zone',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.ajouter = function(zone) {
        WS.post('zones', zone)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Zone ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal(
                        'Error',
                        response.data.message,
                        'error'
                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'zoneController',
    fn: zoneController
};