function factureController(DatatableLang, $state, $scope, WS, $compile, $parse, Flash, $http, $translate) {
    'ngInject';
    const vm = this;
    vm.year = moment().format('YYYY');

    vm.factures = {};
    vm.center = "35.981789, 10.076292";
    vm.countblock = 1;
    vm.newfacture = {};
    vm.newfacture.totalRemise = 0;
    vm.newfacture.totalTtc = 0;
    vm.newfacture.totalNet = 0;


        /**
     * CHECK CONTROLE DE STOCK
     */
    WS.getScache('societes')
    .then(function (response) {
        vm.societes = response.data[0];
        var contrat_code = vm.societes.contrat;
        /*console.log(response);
        console.log(contrat_code);*/
        $http({
            method: 'GET',
            url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
            cache: false
        }).then(function successCallback(response) {
            vm.global_gestion_stock = response.data.gestion_stock;
            //vm.global_gestion_stock = 1;
            //console.log('vm.global_gestion_stock', vm.global_gestion_stock);
        }, function errorCallback(error) {
        });
        $scope.$apply();

    })
    .then(null, function (error) {
        console.log(error);
    });


    vm.searchByDate = function(bldate) {
        if (bldate == undefined || bldate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            var url = 'bl/getByDate/' + bldate.date_debut + '/' + bldate.date_fin + '/facture';
            if (bldate.commercial && bldate.commercial != "") {
                var url = url + '/' + bldate.commercial;
            }
            WS.get(url)
                .then(function(response) {
                    vm.factures = {};
                    vm.factures = response.data;
                    $scope.$apply();
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }
    };

    vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportExcel = function(bldate, withavoir = null) {
        if (bldate == undefined || bldate == {}) {
            swal('Error', 'Veuillez bien remplir les deux champs date', 'error');
            return;
        } else if (bldate.date_debut == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date debut', 'error');
            return;
        } else if (bldate.date_fin == undefined) {
            swal('Error', 'Veuillez bien remplir le champ date fin', 'error');
            return;
        } else {
            if (withavoir) {
                //withavoir = 'avoir';
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/facture/avoir';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/' + bldate.commercial;
                }
            } else {
                var url = 'bl/getByDateExport/' + bldate.date_debut + '/' + bldate.date_fin + '/facture';
                if (bldate.commercial && bldate.commercial != "") {
                    var url = url + '/null/' + bldate.commercial;
                }
            }
            WS.get(url)
                .then(function(response) {
                    var date_debut = response.data[response.data.length - 1].date_creation;
                    var date_fin = response.data[0].date_creation;
                    var filename = 'facture-' + date_debut + '-_-_-' + date_fin;
                    if (withavoir) {
                        filename = 'facture+avoir-' + date_debut + '-_-_-' + date_fin;
                    }
                    /*response.data.forEach(function(element,index){
                            response.data[index].total_net = (response.data[index].total_net.toString().replace('.', ','));
                            response.data[index].pu_ht = (response.data[index].pu_ht.toString().replace('.', ','));
                            response.data[index].pu_ttc = (response.data[index].pu_ttc.toString().replace('.', ','));
                            response.data[index].p_tva = (response.data[index].p_tva.toString().replace('.', ','));
                            response.data[index].total = (response.data[index].total.toString().replace('.', ','));
                    });
                    */
                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [
                            { columnid: 'code', title: 'Code' },
                            { columnid: 'produit', title: 'Produit' },
                            { columnid: 'marque', title: 'Marque' },
                            { columnid: 'gamme', title: 'Gamme' },
                            { columnid: 'famille', title: 'Famille' },
                            { columnid: 'sousfamille', title: 'Sous_Famille' },
                            { columnid: 'entetecommercial_code', title: 'BL_N°' },
                            { columnid: 'quantite', title: 'Quantite' },
                            { columnid: 'pu_ht', title: 'PU_HT' },
                            { columnid: 'pu_ttc', title: 'PU_TTC' },
                            { columnid: 'p_tva', title: 'p_tva' },
                            { columnid: 'taux_tva', title: 'TVA' },
                            { columnid: 'total', title: 'Total' },
                            { columnid: 'total_net', title: 'Total Net' },
                            { columnid: 'date_creation', title: 'DATE' },
                            { columnid: 'remise', title: 'Remise' },
                            { columnid: 'type', title: 'Type' },
                            { columnid: 'commercial', title: 'Commercial' },
                            { columnid: 'client', title: 'Client' },
                            { columnid: 'magasin', title: 'Magasin' },
                            { columnid: 'routing_code', title: 'Route' },
                            { columnid: 'localite', title: 'Localite' },
                            { columnid: 'region', title: 'Region' },
                            { columnid: 'gouvernorat', title: 'Gouvernorat' },
                            { columnid: 'secteur', title: 'Secteur' },
                            { columnid: 'zone', title: 'Zone' }
                        ],
                        row: {
                            style: function(sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }
    };
    vm.getFacturescleared = function(code) {
        var user_code = $state.params.user_code;
        if (user_code != '') {
            var url = 'entetecommercial/top50/facture/' + user_code;
        } else {
            var url = 'entetecommercial/top50/facture';
        }
        WS.get(url)
            .then(function(response) {
                vm.factures = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getFactures = function() {
        var user_code = $state.params.user_code;
        if (user_code != '') {
            var url = 'entetecommercial/top50/facture/' + user_code;
        } else {
            var url = 'entetecommercial/top50/facture';
        }
        WS.getScache(url)
            .then(function(response) {
                vm.factures = response.data;
                $scope.$apply();
                $('.table-bls').DataTable({
                    "language": DatatableLang,
                    "searching": false,
                    "paging": false,
                    "lengthChange": false,
                    "bInfo": false,
                    order: [
                        [9, 'DESC']
                    ]

                });
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getDetails = function() {
        WS.get('entetecommercial/' + $state.params.id)
            .then(function(response) {
                console.log(response);
                if (response.status == 200) {
                    vm.detailss = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getDetailsbyCode = function(code) {
        var url = 'entetecommercial/code/' + $state.params.id;
        WS.get(url)
            .then(function(response) {
                console.log(response);
                vm.details = response.data;
                vm.details.date = moment(vm.details.date).format('DD-MM-YYYY');

                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectDepots = function() {
        WS.get('depots/selectDepot').then(
            function(response) {
                vm.depots = response.data;
            },
            function(error) {
                console.log(error);
            });
    };

    vm.selectSoussocietes = function() {
        WS.get('soussocietes/selectSoussocietes').then(
            function(response) {
                vm.soussocietes = response.data;
            },
            function(error) {
                console.log(error);
            });
    };

    vm.selectClients = function() {
        WS.get('clients/selectInput').then(
            function(response) {
                vm.clients = response.data;
            },
            function(error) {
                console.log(error);
            });
    };

    vm.selectClientsWithCodeFacture = function() {
        WS.get('clients/selectClientsWithCodeFacture').then(
            function(response) {
                vm.clients = response.data;
            },
            function(error) {
                console.log(error);
            });
    };

    vm.selectProduitsWithCode = function() {
        WS.get('produits/selectProduitsWithCode').then(
            function(response) {
                vm.produits = response.data;
            },
            function(error) {
                console.log(error);
            });
    };

    vm.storeFacture = function() {
        if (vm.newfacture.client_code == undefined) {
            Flash.create('danger', "Veuillez choisir un client");
            return;
        }
        angular.forEach(vm.newfacture.lignes, function(value, key) {
            if (value.produit_code == undefined) {
                Flash.create('danger', "Veuillez bien remplir la liste des produits");
                return;
            }
            if (value.quantite == undefined) {
                Flash.create('danger', "Veuillez bien remplir la quantite dans la liste des produits");
                return;
            }
        });

        if ((!angular.isUndefined(vm.newfacture.cheque) && vm.newfacture.cheque != "") || (!angular.isUndefined(vm.newfacture.num_cheque) && vm.newfacture.num_cheque != "")) {
            if (typeof vm.newfacture.banque_cheque == "undefined" || vm.newfacture.banque_cheque == "" || typeof vm.newfacture.date_echeance_cheque == "undefined" || vm.newfacture.date_echeance_cheque == "") {
                Flash.create('danger', "Veuillez bien remplir les informations du cheque");
                return;
            }
        }
        if ((!angular.isUndefined(vm.newfacture.banque_cheque) && vm.newfacture.banque_cheque != "") || (!angular.isUndefined(vm.newfacture.date_echeance_cheque) && vm.newfacture.date_echeance_cheque != "")) {
            if (typeof vm.newfacture.cheque == "undefined" || vm.newfacture.cheque == "" || typeof vm.newfacture.num_cheque == "undefined" || vm.newfacture.num_cheque == "") {
                Flash.create('danger', "Veuillez bien remplir les informations du cheque");
                return;
            }
        }
        if ((!angular.isUndefined(vm.newfacture.traite) && vm.newfacture.traite != "") || (!angular.isUndefined(vm.newfacture.num_traite) && vm.newfacture.num_traite != "")) {
            if (typeof vm.newfacture.banque_traite == "undefined" || vm.newfacture.banque_traite == "" || typeof vm.newfacture.date_echeance_traite == "undefined" || vm.newfacture.date_echeance_traite == "") {
                Flash.create('danger', "Veuillez bien remplir les informations du traite");
                return;
            }
        }
        if ((!angular.isUndefined(vm.newfacture.banque_traite) && vm.newfacture.banque_traite != "") || (!angular.isUndefined(vm.newfacture.date_echeance_traite) && vm.newfacture.date_echeance_traite != "")) {
            if (typeof vm.newfacture.traite == "undefined" || vm.newfacture.traite == "" || typeof vm.newfacture.num_traite == "undefined" || vm.newfacture.num_traite == "") {
                Flash.create('danger', "Veuillez bien remplir les informations du traite");
                return;
            }
        }
        vm.newfacture.type = "facture";
        //console.log(vm.newfacture);
        if(vm.global_gestion_stock == 1){
            var authorize_call =0;
            _.each(vm.newfacture.lignes, (element, key) => {
                if (element.stock < element.quantite || element.quantite <= 0) {
                    authorize_call++;
                }
            });
            if (authorize_call == 0) {
                WS.post('entetecommercial/storeBl/facture', vm.newfacture).then(
                    function(response) {
                        vm.newfacture = {};
                        vm.newfacture.lignes = {};
                        vm.newfacture.totalRemise = 0;
                        vm.newfacture.totalTtc = 0;
                        vm.newfacture.totalNet = 0;
                        Flash.create('Succès', "Facture ajoutée avec Succès");
                        //vm.factures.unshift(response.data);
                        $('#block-0').nextAll().remove();
                        $state.go("app.facture.details", { id: response.data.code });
                        $('.modal-backdrop').remove();
                        $scope.$apply();
                    },
                    function(error) {
                        console.log(error);
                    });
            } else {
                swal(
                    $translate.instant('Attention!'),
                    $translate.instant('veuillez bien vérifier les quantités supérieures à stock ou les quantités négatives'),
                    'warning'
                )
            }
        }else{
            WS.post('entetecommercial/storeBl/facture', vm.newfacture).then(
                function(response) {
                    vm.newfacture = {};
                    vm.newfacture.lignes = {};
                    vm.newfacture.totalRemise = 0;
                    vm.newfacture.totalTtc = 0;
                    vm.newfacture.totalNet = 0;
                    Flash.create('Succès', "Facture ajoutée avec Succès");
                    //vm.factures.unshift(response.data);
                    $('#block-0').nextAll().remove();
                    $state.go("app.facture.details", { id: response.data.code });
                    $('.modal-backdrop').remove();
                    $scope.$apply();
                },
                function(error) {
                    console.log(error);
                });
        }
       
    };

    vm.addBlock = function(index) {
        var newBlock = $compile(`<div class="clearfix"></div>
                            <div id="block-` + index + `">
                            <div class="col-md-4">
                                <div class="form-group">
                                  <div ui-select name="produit-` + index + `" ng-model="vm.newfacture.lignes[` + index + `].produit_code" theme="bootstrap"
                                      ng-change="vm.getPuProduct(` + index + `, vm.newfacture.lignes[` + index + `].produit_code);
                                                vm.checkRemise(` + index + `, vm.newfacture.lignes[` + index + `].produit_code, vm.newfacture.lignes[` + index + `].quantite);" >
                                    <ui-select-match placeholder="Produit...">{{$select.selected.libelle}}</ui-select-match>
                                    <ui-select-choices repeat="item.code as item in vm.produits | filter: $select.search">
                                      <div ng-bind-html="item.libelle | highlight: $select.search"></div>
                                    </ui-select-choices>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-1 text-center"  ng-if="vm.global_gestion_stock == 1">
                                <input type="text" disabled class="form-control" ng-model="vm.newfacture.lignes[` + index + `].stock">
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].quantite" 
                                    ng-class="{ 'red_quantite' : (vm.newfacture.lignes[` + index + `].stock < vm.newfacture.lignes[` + index + `].quantite || vm.newfacture.lignes[` + index + `].quantite<=0) && vm.global_gestion_stock == 1 }"

                                        required
                                       ng-keyup="vm.checkRemise(` + index + `, vm.newfacture.lignes[` + index + `].produit_code, vm.newfacture.lignes[` + index + `].quantite);">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].prix_ht" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].prix_ttc" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].remise" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].ttc" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].net" disabled>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <i class="fa fa-minus iconred" ng-click="vm.removeBlock(` + index + `)"></i>
                            </div>
                            <div class="clearfix"></div>
                            <div class="row">
                                <div class="col-md-3 col-md-offset-1">
                                    <div class="form-group">
                                        <input type="text" class="form-control" ng-model="vm.newfacture.lignes[` + index + `].num_lot" placeholder="Numero de lot">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <datepicker date-format="yyyy-MM-dd" selector="input_date_` + index + `">
                                            <div class="input-group">
                                                <input class="form-control input_date_` + index + `" ng-model="vm.newfacture.lignes[` + index + `].date_peremption"
                                                    placeholder="{{'Date Peremption'| translate}}" />
                                                    <span class="input-group-addon" style="cursor:pointer;padding:3px;">
                                                        <i class="fa fa-lg fa-calendar"></i>
                                                    </span>
                                            </div>
                                        </datepicker>
                                    </div>
                                </div>
                            </div>
                        </div>`)($scope);
        vm.countblock = index + 1;
        $('#panelProduits').append(newBlock);
    };

    vm.removeBlock = function(index) {
        if (vm.newfacture.lignes != undefined && vm.newfacture.lignes[index]) {
            var mod = _.omit($parse("vm.newfacture.lignes")($scope), index);
            $parse("vm.newfacture.lignes").assign($scope, mod);
            // calcul total
            //console.log(vm.newfacture.lignes);
            vm.newfacture.totalRemise = 0;
            vm.newfacture.totalTtc = 0;
            vm.newfacture.totalNet = 0;
            angular.forEach(vm.newfacture.lignes, function(value, key) {
                vm.newfacture.totalTtc = roundToThree(vm.newfacture.totalTtc + value.ttc);
                vm.newfacture.totalNet = roundToThree(vm.newfacture.totalNet + value.net);
            });
            vm.newfacture.totalRemise = roundToThree(vm.newfacture.totalTtc - vm.newfacture.totalNet);
        }
        $('#block-' + index).remove();
    };

    vm.getPuProduct = function(index, produit_code) {
        WS.get('produits/getPuProduct/' + produit_code+'/' + vm.newfacture.client_code).then(
            function(response) {
                vm.newfacture.lignes[index].prix_ttc = response.data.prix_ttc;
                vm.newfacture.lignes[index].prix_ht = response.data.prix_ht;
                vm.newfacture.lignes[index].stock = response.data.stock;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.checkRemise = function(index, produit_code, quantite) {
        if (quantite > 0) {
            var url = 'produits/checkRemise/' + produit_code + '/' + quantite;
        } else {
            var url = 'produits/checkRemise/' + produit_code + '/' + 0;
        }
        WS.get(url).then(
            function(response) {
                vm.newfacture.lignes[index].remise = response.data.remise;
                vm.newfacture.lignes[index].ttc = (vm.newfacture.lignes[index].quantite) ? roundToThree(vm.newfacture.lignes[index].quantite * vm.newfacture.lignes[index].prix_ttc) : 0;
                vm.newfacture.lignes[index].net = roundToThree(vm.newfacture.lignes[index].ttc - ((vm.newfacture.lignes[index].remise * vm.newfacture.lignes[index].ttc) / 100));

                // calcul total
                vm.newfacture.totalRemise = 0;
                vm.newfacture.totalTtc = 0;
                vm.newfacture.totalNet = 0;
                angular.forEach(vm.newfacture.lignes, function(value, key) {
                    vm.newfacture.totalTtc = roundToThree(vm.newfacture.totalTtc + value.ttc);
                    vm.newfacture.totalNet = roundToThree(vm.newfacture.totalNet + value.net);
                });
                vm.newfacture.totalRemise = roundToThree(vm.newfacture.totalTtc - vm.newfacture.totalNet);
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };


    /*
    '*********** 
    ' Devise=0   aucune 
    '       =1   Dinar  
    '       =2   Dollar $ 
    ' Langue=0   Français 
    '       =1   Belgique 
    '       =2   Suisse 
    '*********** 
    ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99 
    ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales 
    */
    vm.ConvNumberLetter_fr = function(Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();
        if (isNaN(parseFloat(Nombre))) return "";
        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0);
            }
            return strLetter + 'Millimes';
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = " virgule";
                break;
            case 1:
                strDev = " Dinar";
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = " Dollar";
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }
        if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";

        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = "mille ";
                break;
            default:
                StrTmp = StrTmp + " mille ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " million ";
                break;
            default:
                StrTmp = StrTmp + " millions ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " milliard ";
                break;
            default:
                StrTmp = StrTmp + " milliards ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " billion ";
                break;
            default:
                StrTmp = StrTmp + " billions ";
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept",
            "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze",
            "seize", "dix-sept", "dix-huit", "dix-neuf");
        TabDiz = Array("", "", "vingt", "trente", "quarante", "cinquante",
            "soixante", "soixante", "quatre-vingt", "quatre-vingt");
        if (Langue == 1) {
            TabDiz[7] = "septante";
            TabDiz[9] = "nonante";
        } else if (Langue == 2) {
            TabDiz[7] = "septante";
            TabDiz[8] = "huitante";
            TabDiz[9] = "nonante";
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = " et ";
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }

    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix");

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    }


}
export default {
    name: 'factureController',
    fn: factureController
};