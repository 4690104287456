function BackDirective($window) {
    'ngInject';

    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            elem.bind('click', function() {
                $window.history.back();
            });
        }
    };
}

export default {
    name: 'back',
    fn: BackDirective
};
