function synchronisationController($translate, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.synchronisation = [];
    vm.new_synchronisation = {};
    vm.details = {};
    vm.getSynchronisation = function () {
        WS.get('synchronisation')
            .then(function (response) {
                vm.synchronisation = response.data;
                vm.synchronisation_filters = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectUsers = () => {
        WS.get('users/selectUsers')
            .then(function (response) {
                vm.selectusers = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getcategories = function () {
        WS.get('categoriescommerciale')
            .then(function (response) {
                if (response.status == 200) {
                    vm.categories = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getActivites = function () {
        WS.get('activites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;

                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectZones = () => {
        WS.get('zones/selectZones')
            .then(function (response) {
                vm.zones = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.update = function() {

         WS.post('synchronisation/update' , vm.details)
             .then(function(response) {
                 if (response.status == 200) {
                     swal(
                         'Succès',
                         'synchronisation modifiée avec Succès',
                         'success'
                     ).then(function() {
                         $window.location.reload();
                     });
                 } else {}
             })
             .then(null, function(error) {
                 console.log(error);
             });
     }

     vm.delete = function(id) {

         swal({
             title: $translate.instant('synchronisation'),
             text: $translate.instant("voulez vous vraiment supprimer cette synchronisation!"),
             type: 'warning',
             showCancelButton: true,
             confirmButtonColor: '#3085d6',
             cancelButtonColor: '#d33',
             confirmButtonText: $translate.instant('Oui'),
             cancelButtonText: $translate.instant('Non'),
             confirmButtonClass: 'btn btn-success',
             cancelButtonClass: 'btn btn-danger',
             buttonsStyling: false
         }).then(function() {
             WS.post('synchronisation/delete' , {id}).then(
                 function() {
                     swal(
                        $translate.instant('Succès'),
                        $translate.instant('synchronisation supprimée avec Succès.'),
                         'success'
                     ).then(function() {
                         $window.location.reload();
                     });
                 },
                 function() {
                     swal(
                        $translate.instant('Attention!'),
                        $translate.instant('vous ne pouvez pas supprimer cette synchronisation!!'),
                         'warning'
                     );
                 }
             );
         });
     }

     vm.ajouter = function() {

        WS.post('synchronisation/store', vm.new_synchronisation)
        .then(function (response) {
            if (response.data.success == true) {

                swal(
                    $translate.instant('Succès'),
                    $translate.instant('synchronisation ajoutée avec Succès'),
                     'success'
                 ).then(function() {
                     $window.location.reload();
                 });
            } else {
                swal(
                    $translate.instant('Error'), 
                    $translate.instant(response.data.message),
                    'error'
                 );
            }
        }, function (error) {
            console.log(error);
        });
     }




     vm.tabs_cats = [];
    vm.res_tabs_cats = [];

    vm.selectCategoriesWithActivites = () => {

        WS.get('plandecharges/selectCategoriesWithActivites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.categories = response.data;
                    vm.max_activites = 0;
                    _.each(vm.categories, element => {
                        var nbr_act = element.activites.length;
                        if (nbr_act > vm.max_activites) {
                            vm.max_activites = nbr_act;
                        }

                        vm.tabs_cats[element.code] = false;
                        _.each(element.activites, activite => {
                            vm.tabs_cats[element.code + '_' + activite.code] = false;
                        })
                    })
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.manage = (categorie_code, activite_code = null, type, activites) => {
        //console.log(categorie_code, activite_code);
        if (type == 'categorie') {
            vm.tabs_cats[categorie_code] = !vm.tabs_cats[categorie_code];
            if (vm.tabs_cats[categorie_code]) {
                if (activites.length > 0) {
                    _.each(activites, element => {
                        var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == element.code);
                        if (index == -1) {
                            vm.res_tabs_cats.push({
                                categorie_code: categorie_code,
                                activite_code: element.code
                            })
                            vm.tabs_cats[categorie_code + '_' + element.code] = true;
                            $(`#${categorie_code}_${element.code}>i`).removeClass('glyphicon-unchecked').addClass('glyphicon-check')

                        }
                    })
                    $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked');
                } else {
                    var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == null);
                    if (index == -1) {
                        vm.res_tabs_cats.push({
                            categorie_code: categorie_code,
                            activite_code: null
                        })
                        vm.tabs_cats[categorie_code] = true;
                        $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                    }
                }
            } else {
                if (activites.length > 0) {
                    _.each(activites, element => {
                        var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == element.code);
                        if (index > -1) {
                            vm.res_tabs_cats.splice(index, 1);
                            vm.tabs_cats[categorie_code + '_' + element.code] = false;
                            $(`#${categorie_code}_${element.code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                        }
                    })
                    $(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked');

                } else {
                    var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == null);
                    if (index > -1) {
                        vm.res_tabs_cats.splice(index, 1);
                        vm.tabs_cats[categorie_code] = false;
                        $(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                    }
                }
            }



        } else if (type == 'activite') {
            vm.tabs_cats[categorie_code + '_' + activite_code] = !vm.tabs_cats[categorie_code + '_' + activite_code];

            if (vm.tabs_cats[categorie_code + '_' + activite_code]) {
                var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == activite_code);
                //console.log(index)
                if (index == -1) {
                    vm.res_tabs_cats.push({
                        categorie_code: categorie_code,
                        activite_code: activite_code
                    })
                    vm.tabs_cats[categorie_code] = true;
                    $(`#${categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                    $(`#${categorie_code}_${activite_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
                }
            } else {
                var index = vm.res_tabs_cats.findIndex(x => x.categorie_code == categorie_code && x.activite_code == activite_code);
                //console.log(index)
                if (index > -1) {
                    vm.res_tabs_cats.splice(index, 1);
                    //vm.tabs_cats[categorie_code] =false;
                    //$(`#${categorie_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                    $(`#${categorie_code}_${activite_code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')
                }
            }

        }
        //console.log('clicked_cats', vm.res_tabs_cats)

    }

    vm.changeUser = ()=> {
        var resultat = vm.synchronisation_filters.filter(sync => sync.user_code == vm.new_synchronisation.user_code);
        var zones = _.pluck(resultat, 'zone_code');
        vm.new_synchronisation.zone_code  = _.uniq(zones);
        //console.log(zones, vm.new_synchronisation.zone_code);
        vm.res_tabs_cats = [];


        _.each(vm.categories, element => {
            var nbr_act = element.activites.length;
            if (nbr_act > vm.max_activites) {
                vm.max_activites = nbr_act;
            }
            vm.tabs_cats[element.code] = false;
            $(`#${element.code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')

            _.each(element.activites, activite => {
                vm.tabs_cats[element.code + '_' + activite.code] = false;
                $(`#${element.code}_${activite.code}>i`).removeClass('glyphicon-check').addClass('glyphicon-unchecked')

            })
        })



        _.each(resultat, (element, index) => {
            vm.tabs_cats[element.categorie_code] = true;
            vm.tabs_cats[element.categorie_code + '_' + element.activite_code] = true;

            vm.res_tabs_cats.push({
                categorie_code: element.categorie_code,
                activite_code: element.activite_code
            })
            $(`#${element.categorie_code}_${element.activite_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')
            $(`#${element.categorie_code}>i`).addClass('glyphicon-check').removeClass('glyphicon-unchecked')

        })
    }

    vm.addSynchronisation = () => {
        //console.log(vm.listProds);
        
        if (vm.user_code == '') {
            swal('Oups!', 'Veuillez bien remplir le champ libelle', 'warning');
        }
        else if (vm.res_tabs_cats.length == 0) {
            swal('Oups!', 'Veuillez bien selectionner au moins une catégorie', 'warning');
        } else {
            const data = {
                user_code: vm.new_synchronisation.user_code,
                categories: vm.res_tabs_cats,
                zones : vm.new_synchronisation.zone_code
            };

            console.log(data);


            WS.post('synchronisation/store', data)
                .then(function (response) {
                    if (response.status == 200) {
                        swal(
                            $translate.instant('Succé'),
                            $translate.instant('Plan de charge ajoutée avec succées'),
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        })
                    }
                })
                .then(null, function (error) {
                    console.log(error);
                });

        }

    }

}
export default {
    name: 'synchronisationController',
    fn: synchronisationController
};
