function dashboardcommandeController($localStorage, $state, $scope, WS, $timeout, $interval, NgMap, $http, AppSettings) {
    'ngInject';
    const vm = this;

    vm.produits = {};
    vm.dn = [];
    vm.keymaps = AppSettings.keymaps;
    vm.currentDate = moment().format("DD/MM/YYYY");
    vm.settings = AppSettings;
    vm.currentUser = {};
    WS.getScache('pays').then(function (response) {
        vm.getpays = response.data;
        $scope.$apply();
    }, function (error) {
        console.log(error);
    });

    vm.month = moment().format("MM");
    vm.year_month = moment().format("YYYY-MM");
    vm.year = moment().format("YYYY");

    vm.change_date = (date) => {
        vm.currentDate = moment(date).format("DD/MM/YYYY");
        vm.month = moment(date).format("MM");
        vm.year_month = moment(date).format("YYYY-MM");
        vm.year = moment(date).format("YYYY");
    }


    vm.getCashvanPrevendeur = () => {
        WS.get('users/cashvanprevendeur')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectedUsers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    WS.get('paiements/recouvrementByCommercial')
        .then(function (response) {
            if (response.status == 200) {
                vm.recouvrementByCommercial = response.data;
                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.tauxdevisite = function () {
        WS.get('entetecommercial/calcul/tauxdevisite')
            .then(function (response) {
                if (response.status == 200) {
                    vm.tauxdevisite = response.data[0];
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getGouvernorats = function () {
        WS.get('gouvernorats')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getGouvernorats();

    vm.getClients = function () {
        WS.get('clients/count')
            .then(function (response) {
                if (response.status == 200) {

                    vm.clients = response.data;
                    vm.Totclients = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getClients();


    vm.store = function () {
        for (var i = 0; i < 100; i++) {
            WS.post('marques', { 'code': 'code' + i, 'libelle': 'libelle test ' + i })
                .then(function (response) {
                    if (response.status == 200) {
                        console.log('success');
                    }
                })
                .then(null, function (error) {
                    console.error(error);
                });
        }
    }
    vm.showClientDetails = function (e, client) {
        vm.selectedClient = client;
    };

    vm.getByGouvernorateNumber = function (list, num) {
        if (!list) return;
        for (var i = 0; i < list.length; i++) {
            if (list[i].gouvernorat == num) {
                return list[i].dn;
            }
        }
        return 0;
    }

    /**
     * panel kpi global dashboard 
     */
    vm.getTopClientBl = function () {
        WS.get('bl/getTopClient/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byClient = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getTopCommercialBl = function () {
        WS.get('entetecommercial/top5commercial/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.top5commercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getBlByRegion = function () {
        WS.get('bl/byRegion/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byRegion = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getDn_bl_Gouv = function () {
        WS.get('bl/byGouvernorat/commande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byGouvernorat = response.data;
                    $.each(vm.bl_byGouvernorat, function (index, val) {
                        vm.dn.push({
                            'gouvernorat': val.gouvernorat,
                            'dn': val.montant_total_ttc
                        });
                    });

                    $scope.$apply();
                } else { console.log('response status is not 200 line 98 dashboard.js controller'); }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getCommandes = function () {
        /*WS.get('bl/byGouvernorat/commande')
            .then(function(response) {
                if (response.status == 200) {

                    vm.bl_byGouvernorat = response.data;
                    $.each(vm.bl_byGouvernorat, function(index, val) {
                        vm.dn.push({
                            'gouvernorat': val.gouvernorat,
                            'dn': val.montant_total_ttc
                        });
                    });

                    $scope.$apply();
                } else { console.log('response status is not 200 line 98 dashboard.js controller'); }
            })
            .then(null, function(error) {
                console.log(error);
            });
        */

        WS.get('dashboard/VenteAndRecetteForKpi/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByDay = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('dashboard/VenteAndRecetteForKpi/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('dashboard/VenteAndRecetteForKpi/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        // WS.get('clients/byCommercial/today')
        //     .then(function (response) {
        //         if (response.status == 200) {
        //             vm.clientsbyCommercialToday = response.data;
        //             $scope.$apply();
        //         } else { }
        //     })
        //     .then(null, function (error) {
        //         console.log(error);
        //     });


        // WS.get('clients/byCommercial/month')
        //     .then(function (response) {
        //         if (response.status == 200) {
        //             vm.clientsbyCommercialThisMonth = response.data;
        //             $scope.$apply();
        //         } else { }
        //     })
        //     .then(null, function (error) {
        //         console.log(error);
        //     });

        // WS.get('clients/byCommercial/year')
        //     .then(function (response) {
        //         if (response.status == 200) {
        //             vm.clientsbyCommercialThisYear = response.data;
        //             $scope.$apply();
        //         } else { }
        //     })
        //     .then(null, function (error) {
        //         console.log(error);
        //     });
        WS.getData('clients/getNbOfNewClient', { interval: 'day' })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialToday = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.getData('clients/getNbOfNewClient', { interval: 'month' })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.getData('clients/getNbOfNewClient', { interval: 'year' })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialThisYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('clients/byCommercial')
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        /*
        WS.get('bl/byRegion/commande')
            .then(function(response) {
                if (response.status == 200) {
                    vm.bl_byRegion = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
        */
        WS.get('bl/byRegion/commande/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byRegionThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byRegion/commande/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byRegionThisyear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byRegion/commande/moisprecedent')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byRegionMoisPrecedent = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        /*
        WS.get('bl/getTopClient/commande')
            .then(function(response) {
                if (response.status == 200) {
                    vm.bl_byClient = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });


        WS.get('entetecommercial/top5commercial/commande')
            .then(function(response) {
                if (response.status == 200) {
                    vm.top5commercial = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
        */
        WS.get('bl/byClient/commande/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byClientThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('bl/byClient/commande/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byClientThisYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('bl/byClient/commande/moisprecedent')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byClientMoisPrecedent = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byCommercial/commande/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byCommercial/commande/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byGouvernorat/commande/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byGouvernoratThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('bl/byGouvernorat/commande/visites')
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byGouvernoratThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('bl/byCommercial/commande/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('bl/byCommercial/commande/date/null')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('commandes/byCommercial/count/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('commandes/byCommercial/count/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('commandes/byCommercial/count/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountDate = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });



        WS.get('visites/byCommercial/count/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('visites/countVisiteDay')
            .then(function (response) {
                if (response.status == 200) {
                    vm.getAllvisiteThisDay = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('visites/countVisiteMonth')
            .then(function (response) {
                if (response.status == 200) {
                    vm.getAllvisiteThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('visites/byCommercial/count/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('visites/byCommercial/count/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountDate = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });





        WS.get('paiements/getEtatPaiementAujourdhui')
            .then(function (response) {
                if (response.status == 200) {
                    vm.especes = 0;
                    vm.cheques = 0;
                    vm.traite = 0;
                    vm.credit = 0;
                    vm.total = 0;
                    response.data.forEach(function (e, index) {
                        vm.total += parseFloat((e.especes + e.cheque + e.traite));
                        vm.especes += parseFloat(e.especes);
                        vm.cheques += parseFloat(e.cheque);
                        vm.traite += parseFloat(e.traite);
                        vm.credit += parseFloat(e.credit);

                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('paiements/getEtatPaiementAujourdhui/recouvrement')
            .then(function (response) {
                if (response.status == 200) {
                    vm.TotalRecouvrementespeces = 0;
                    vm.TotalRecouvrementcheques = 0;
                    vm.TotalRecouvrementtraite = 0;
                    vm.TotalRecouvrement = 0;
                    response.data.forEach(function (e, index) {
                        vm.TotalRecouvrement += parseFloat((e.especes + e.cheque + e.traite));
                        vm.TotalRecouvrementespeces += parseFloat(e.especes);
                        vm.TotalRecouvrementcheques += parseFloat(e.cheque);
                        vm.TotalRecouvrementtraite += parseFloat(e.traite);


                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('paiements/getEtatPaiement/recouvrement')
            .then(function (response) {
                if (response.status == 200) {
                    vm.TotalespecesAujourdhui = 0;
                    vm.TotalchequesAujourdhui = 0;
                    vm.TotaltraiteAujourdhui = 0;
                    vm.TotalAujourdhui = 0;
                    response.data.forEach(function (e, index) {
                        vm.TotalAujourdhui += parseFloat((e.especes + e.cheque + e.traite));
                        vm.TotalespecesAujourdhui += parseFloat(e.especes);
                        vm.TotalchequesAujourdhui += parseFloat(e.cheque);
                        vm.TotaltraiteAujourdhui += parseFloat(e.traite);


                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('paiements/creditByCommercialByDate')
            .then(function (response) {
                if (response.status == 200) {
                    vm.creditTodayForAll = 0;
                    vm.creditByCommercialByDate = response.data;
                    response.data.forEach(function (e, index) {
                        vm.creditTodayForAll += e.solde;
                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('paiements/getetatCredit/all')
            .then(function (response) {
                if (response.status == 200) {
                    vm.creditForAll = 0;

                    _.each(response.data, e => {

                        vm.creditForAll += e.solde;

                    });

                    $scope.$apply();
                } else { }
            })

        WS.get('paiements/getetatCreditByClients')
            .then(function (response) {
                if (response.status == 200) {

                    vm.getetatCreditByClients = response.data;
                    $scope.$apply();
                } else { }
            })



        WS.get('regions/count')
            .then(function (response) {
                if (response.status == 200) {

                    vm.nombreRegion = response.data;

                    $scope.$apply();
                } else { }
            })

            .then(null, function (error) {
                console.log(error);
            });

        WS.get('paiements/getetatCredit/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.creditthismonth = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        /**
         * disabled and replaced by /paiements/AllCreditForDashByUserCommande function
         */
        // WS.get('paiements/getetatCredit/year')
        //     .then(function(response) {
        //         if (response.status == 200) {
        //             vm.creditthisyear = response.data;


        //             $scope.$apply();
        //         } else {}
        //     })
        //     .then(null, function(error) {
        //         console.log(error);
        //     });

        WS.get('paiements/AllCreditForDashByUserCommande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.all_credit = response.data;
                    console.log('all_crédit : ', vm.all_credit);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.get('paiements/credits/byCommercial/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.credits_byCommercialMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('paiements/credits/byCommercial/year')
            .then(function (response) {
                if (response.status == 200) {
                    vm.credits_byCommercialYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.get('paiements/credits/byCommercial/date')
            .then(function (response) {
                if (response.status == 200) {
                    vm.credits_byCommercialDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('clients/recrutement/day')
            .then(function (response) {
                if (response.status == 200) {

                    vm.recrutementThisDay = 0;
                    response.data.forEach(function (e, index) {
                        vm.recrutementThisDay += parseInt(e.nbrclient);

                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('clients/recrutement')
            .then(function (response) {
                if (response.status == 200) {
                    vm.recrutementAll = 0;
                    response.data.forEach(function (e, index) {
                        vm.recrutementAll += parseInt(e.nbrclient);

                    });


                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('clients/recrutement/month')
            .then(function (response) {
                if (response.status == 200) {
                    vm.recrutementThisMonth = response.data.nbrclient;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('clients/byGouvernorat')
            .then(function (response) {
                if (response.status == 200) {
                    vm.clients_byGouvernorate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getCAByAttributeValue = function (code, liste, attribute, value) {

        if (!liste) return { nb: 0 };
        for (var i = 0; i < liste.length; i++) {
            if (liste[i][attribute] == value && liste[i]['code'] == code) {
                return liste[i];
            }
        }
        return { nb: 0 };
    }





    vm.getCAByRegion = function (code, liste, attribute) {
        if (!liste) return;


        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }

    vm.getvisitecounttoday = function (liste, value) {
        if (!liste) return;
        var nbr = 0;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['date'] == value) {
                nbr++;
            }

        }
        return nbr;

    }

    WS.get('paiements/getetatCredit')
        .then(function (response) {
            if (response.status == 200) {
                vm.AllCreditThisMonth = new Array(31).fill(0);
                _.each(response.data, e => {
                    var jour = parseInt(e.date.substr(8, 2));
                    vm.AllCreditThisMonth[jour - 1] += parseFloat(e.solde);
                });
                $scope.$apply();
            } else { }
        })

    WS.get('entetecommercial/analysescommande')
        .then(function (response) {
            if (response.status == 200) {
                vm.nbCommandes = 0;
                vm.nbCommandesThisMonth = 0;
                vm.nbBl = 0;
                vm.nbBlThisMonth = 0;
                vm.nbDevis = 0;
                vm.salesThisMonth = 0;
                //vm.salesThisYear = 0;
                vm.netThisMonth = 0;
                vm.moneyToday = 0;

                vm.allClients = [];
                vm.todayAllClients = [];

                vm.ventes_ht = new Array(31).fill(0);
                vm.ventes_net = new Array(31).fill(0);
                vm.commandes_ht = new Array(31).fill(0);
                response.data.forEach(function (e, index) {
                    var jour = parseInt(e.date.substr(8, 2));
                    var year = parseInt(e.date.substr(0, 4));

                    var mois = parseInt(e.date.substr(5, 2));
                    if (mois == (new Date()).getMonth() + 1) {
                        vm.allClients.push(e.client_code);
                        if (jour == (new Date()).getDate()) {
                            vm.todayAllClients.push(e.client_code);

                        }

                        if (e.type == 'bl') {

                            vm.ventes_ht[jour - 1] += e.montant_total_ht;
                            vm.ventes_net[jour - 1] += parseFloat(e.net_a_payer);



                            if (jour == (new Date()).getDate() && e.mode_paiement == "espece") {
                                vm.moneyToday += parseFloat(e.net_a_payer);
                            }
                            if (jour == (new Date()).getDate() && e.type == 'bl') {
                                vm.nbBl++;
                            }

                        }
                        if (e.type == 'commande') {
                            vm.salesThisMonth += parseFloat(e.net_a_payer);
                            vm.netThisMonth += parseFloat(e.net_a_payer);
                            vm.commandes_ht[jour - 1] += parseFloat(e.net_a_payer);
                            if (jour == (new Date()).getDate() && e.type == 'commande') {
                                vm.nbCommandes++;
                            }

                            vm.nbCommandesThisMonth++;

                        }
                        if (e.type == 'devis') {
                            vm.nbDevis++;
                        }
                    }

                });



                vm.salesThisMonth = vm.salesThisMonth + "";


                vm.netThisMonth = vm.netThisMonth + "";
                vm.distinctClients = [...new Set(vm.allClients)];
                vm.todayDistinctClients = [...new Set(vm.todayAllClients)];

                vm.lineData = {
                    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                    series: [
                        vm.ventes_net,

                        vm.AllCreditThisMonth,

                        vm.commandes_ht
                    ]

                };

                $scope.$apply();
            } else { }
        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.get('entetecommercial/getChiffre')
        .then(function (response) {
            if (response.status == 200) {
                vm.salesThisYear = response.data.bls[0].net_a_payer;
                vm.commandesThisYear = response.data.commandes[0].net_a_payer;

                $scope.$apply();
            } else { }
        });


    vm.chartVenteCmd = function () {
        WS.get('entetecommercial/analysescommande')
            .then(function (response) {
                if (response.status == 200) {
                    vm.nbCommandes = 0;
                    vm.nbBl = 0;
                    vm.nbBlThisMonth = 0;
                    vm.nbDevis = 0;
                    vm.salesThisMonth = 0;

                    vm.netThisMonth = 0;
                    vm.moneyToday = 0;

                    vm.allClients = [];
                    vm.todayAllClients = [];

                    vm.ventes_ht = new Array(31).fill(0);
                    vm.ventes_net = new Array(31).fill(0);
                    vm.commandes_ht = new Array(31).fill(0);
                    response.data.forEach(function (e, index) {
                        var jour = parseInt(e.date.substr(8, 2));
                        var year = parseInt(e.date.substr(0, 4));

                        var mois = parseInt(e.date.substr(5, 2));
                        if (mois == (new Date()).getMonth() + 1) {
                            vm.allClients.push(e.client_code);
                            if (jour == (new Date()).getDate()) {
                                vm.todayAllClients.push(e.client_code);

                            }

                            if (e.type == 'bl' || e.type == 'avoir') {

                                vm.ventes_ht[jour - 1] += e.montant_total_ht;
                                vm.ventes_net[jour - 1] += parseFloat(e.net_a_payer);

                                vm.salesThisMonth += parseFloat(e.net_a_payer);
                                vm.netThisMonth += parseFloat(e.net_a_payer);
                                if (jour == (new Date()).getDate() && e.mode_paiement == "espece") {
                                    vm.moneyToday += parseFloat(e.net_a_payer);
                                }
                                if (jour == (new Date()).getDate() && e.type == 'bl') {
                                    vm.nbBl++;
                                }
                                if (e.type == 'bl') {
                                    vm.nbBlThisMonth++;
                                }

                            }
                            if (e.type == 'commande') {
                                vm.commandes_ht[jour - 1] += parseFloat(e.net_a_payer);
                                vm.nbCommandes++;
                            }
                            if (e.type == 'devis') {
                                vm.nbDevis++;
                            }
                        }

                    });



                    vm.salesThisMonth = vm.salesThisMonth + "";

                    vm.netThisMonth = vm.netThisMonth + "";
                    vm.distinctClients = [...new Set(vm.allClients)];
                    vm.todayDistinctClients = [...new Set(vm.todayAllClients)];

                    vm.lineData = {
                        labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
                        series: [
                            vm.ventes_net,

                            vm.AllCreditThisMonth,

                            vm.commandes_ht
                        ]

                    };
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.lineOptions = {
        height: '200px',
        axisX: {
            labelInterpolationFnc: function (value) {
                return value;
            }
        }
    };


    // :::::::::::::::::::::::::::::::::::::::::::::::::::

    /*  vm.getbllignesbygamme = function(){
           WS.get('bl/lignes/byGamme')
               .then(function(response) {
                   if (response.status == 200) {
                       vm.bllignes_byGamme = response.data;
                       $scope.$apply();                   
                   } else {}
               })
               .then(null, function(error) {
                   console.log(error);
               });
      }
       
       vm.getbllignesbygammeyear = function(){
               WS.get('bl/lignes/byGammeYear')
               .then(function(response) {
                   if (response.status == 200) {
                       vm.bllignes_byGammeYear = response.data;
                      $scope.$apply();
                   } else {}
               })
               .then(null, function(error) {
                   console.log(error);
               });
       }*/

    vm.pieData0 = { series: [], labels: [] };
    WS.get('commandes/lignes/byGammeDay')
        .then(function (response) {
            vm.bllignes_byGammeDay = response.data;
            $.each(vm.bllignes_byGammeDay, function (index, val) {
                vm.pieData0.series.push(val.total);
                vm.pieData0.labels.push(val.gamme);
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.pieData1 = { series: [], labels: [] };
    WS.get('commandes/lignes/byGamme')
        .then(function (response) {
            vm.bllignes_byGamme = response.data;
            $.each(vm.bllignes_byGamme, function (index, val) {
                vm.pieData1.series.push(val.total);
                vm.pieData1.labels.push(val.gamme);
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.pieData2 = { series: [], labels: [], colors: [] };
    WS.get('commandes/lignes/byGammeYear')
        .then(function (response) {
            vm.bllignes_byGammeYear = response.data;
            $.each(vm.bllignes_byGammeYear, function (index, val) {
                vm.pieData2.series.push(val.total);
                vm.pieData2.labels.push(val.gamme);
            });
            $scope.$apply();

        })
        .then(null, function (error) {
            console.log(error);
        });

    vm.totalGammes = function (liste) {
        var nb = 0;
        for (var i = 0; i < liste.length; i++) {
            nb += liste[i] * 1;
        }
        return nb;
    }

    // donut chart
    vm.commun = {
        donut: true,
        segmentShowStroke: true,
        segmentStrokeColor: "#fff",
        segmentStrokeWidth: 2,
        animationSteps: 100,
        animationEasing: "easeOutBounce",
        animateRotate: true,
        animateScale: true,
        legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>",
        responsive: true,
    };
    vm.donutOptions = vm.commun;
    vm.donutOptions2 = vm.commun;

    vm.donutOptions = {
        donut: true,
        segmentShowStroke: true,
        segmentStrokeColor: "#fff",
        segmentStrokeWidth: 2,
        animationSteps: 100,
        animationEasing: "easeOutBounce",
        animateRotate: true,
        animateScale: true,
        legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>",
        responsive: true,
        labelInterpolationFnc: function (value, i) {
            // return value+" \t "+parseFloat(vm.pieData1.series[i]).toFixed(3);
            // do whatever math operation you want here
            return parseFloat((vm.pieData1.series[i] / vm.totalGammes(vm.pieData1.series)) * 100).toFixed(2) + "%";
        }
    };

    vm.donutOptions2 = {
        donut: true,
        segmentShowStroke: true,
        segmentStrokeColor: "#fff",
        segmentStrokeWidth: 2,
        animationSteps: 100,
        animationEasing: "easeOutBounce",
        animateRotate: true,
        animateScale: true,
        legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>",
        responsive: true,
        labelInterpolationFnc: function (value, i) {
            // return value+" \t "+parseFloat(vm.pieData2.series[i]).toFixed(3);
            // do whatever math operation you want here
            return ((vm.pieData2.series[i] / vm.totalGammes(vm.pieData2.series)) * 100).toFixed(2) + "%";
        }
    };

    vm.piegammeyear1 = function (temps, user_code = null) {
        if (user_code != null) {
            var url = 'commandes/lignes/byGammeYearCommande/' + temps + "/" + user_code;
        } else {
            var url = 'commandes/lignes/byGammeYearCommande/' + temps;
        }
        WS.get(url)
            .then(function (response) {
                var chart;
                var legend;
                var selected;

                var types = response.data;

                function generateChartData() {
                    var chartData = [];
                    for (var i = 0; i < types.length; i++) {
                        if (i == selected) {
                            for (var x = 0; x < types[i].subs.length; x++) {
                                chartData.push({
                                    type: types[i].subs[x].type,
                                    percent: types[i].subs[x].percent,
                                    color: types[i].color,
                                    pulled: true
                                });
                            }
                        } else {
                            chartData.push({
                                type: types[i].type,
                                percent: types[i].percent,
                                color: types[i].color,
                                id: i
                            });
                        }
                    }
                    return chartData;
                }

                AmCharts.makeChart("piegamme" + temps + "1", {
                    "type": "pie",
                    "theme": "light",

                    "dataProvider": generateChartData(),
                    "labelText": "[[title]]: [[value]]",
                    "balloonText": "[[title]]: [[value]]",
                    "titleField": "type",
                    "valueField": "percent",
                    "outlineColor": "#FFFFFF",
                    "outlineAlpha": 0.8,
                    "outlineThickness": 2,
                    "colorField": "color",
                    "pulledField": "pulled",
                    "listeners": [{
                        "event": "clickSlice",
                        "method": function (event) {
                            var chart = event.chart;
                            if (event.dataItem.dataContext.id != undefined) {
                                selected = event.dataItem.dataContext.id;
                            } else {
                                selected = undefined;
                            }
                            chart.dataProvider = generateChartData();
                            chart.validateData();
                        }
                    }],
                    "innerRadius": "65%",
                    "labelsEnabled": false,
                    "autoMargins": false,
                    "marginTop": 0,
                    "marginBottom": 0,
                    "marginLeft": 0,
                    "marginRight": 0,
                    "pullOutRadius": 0,
                    "labelRadius": 0,
                    "legend": {
                        "position": "bottom",
                        "marginRight": 0,
                        "align": "left",
                        "equalWidths": true,
                        "valueWidth": 35,
                        "horizontalGap": 5,
                        "marginLeft": 0,
                        "marginBottom": 0,
                        "marginTop": 0,
                        "autoMargins": false
                    },
                    "export": {
                        "enabled": true
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    // vm.getrapportvisites = function (code, date) {
    //     WS.getScache('visites/byCommercial' + '/' + code + '/' + date)
    //         .then(function (response) {
    //             if (response.status == 200) {
    //                 vm.rapportvisites = response.data.visites;
    //                 vm.centerrapvisite = response.data.center.center;
    //                 vm.kilometragerap = response.data.kilometrage;
    //                 $scope.$apply();
    //             } else { }
    //         })
    //         .then(null, function (error) {
    //             console.log(error);
    //         });
    // };

    vm.getValue = function (code, liste, attribute, value) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i][attribute] == value) {
                return liste[i];
            }
        }
    };

    vm.mapForRapporttournee = function () {
        $timeout(function () {
            NgMap.getMap({ id: 'mapforrapporttournee' }).then(function (response) {
                google.maps.event.trigger(response, 'resize');
                //response.setCenter({lat: Number(vm.getpays.latitude), lng: Number(vm.getpays.longitude)});
            });
        }, 7000);

    };
    vm.getrapportvisites = function (code, date) {
        WS.getScache('visites/byCommercial' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.rapportvisites = response.data.visites;
                    vm.centerrapvisite = response.data.center.center;
                    vm.centerrapmaps = response.data.center;
                    vm.kilometragerap = response.data.kilometrage;
                    vm.layer_depot = response.data.depot;
                    /**
                     * maps openstreetmaps
                     */
                    $('#maps_rapport_tournee').empty();
                    $('#maps_rapport_tournee').append('<div style="width: 100%; height: 560px;" id="maps_rapport_tournee_panel"></div>');
                    // var mymap = new L.Map('maps_rapport_tournee_panel',
                    //     {
                    //         fullscreenControl: true,
                    //         fullscreenControlOptions: {
                    //             position: 'topright'
                    //         }
                    //     })
                    //     .setView([vm.centerrapmaps.latitude, vm.centerrapmaps.longitude], 12);
                    var mymap = new L.Map('maps_rapport_tournee_panel', {
                        center: new L.LatLng(vm.centerrapmaps.latitude, vm.centerrapmaps.longitude),
                        zoom: 12,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: `Map data &copy; <a href="https://www.nomadis.com/">Nomadis</a>`
                    }).addTo(mymap);
                    //secteur 
                    var geojsonMarkerOptions = {
                        radius: vm.layer_depot.raduis,
                        //fillColor: "#ff7800",
                        //color: "#000",
                        // weight: 1,
                        // opacity: 0.1,
                        // fillOpacity: 0.6
                    };
                    //console.log('vm.layer_depot.layer', vm.layer_depot.layer);
                    var drawnItems = L.geoJson(JSON.parse(vm.layer_depot.layer), {
                        onEachFeature: function (feature, layer) {
                            // layer.on('click', function (e) {
                            //     e.target.editing.enable();
                            // });
                        },
                        pointToLayer: function (feature, latlng) {
                            return L.circle(latlng, geojsonMarkerOptions);
                        }
                    }).addTo(mymap);
                    mymap.addControl(new L.Control.Draw({
                        edit: {
                            featureGroup: drawnItems,
                            remove: false,
                            edit: false
                        },
                        draw: {
                            polyline: false,
                            polygon: {
                                allowIntersection: false, // Restricts shapes to simple polygons
                                showArea: true,
                            },
                            rectangle: false,
                            marker: false,
                            circlemarker: false,
                            circle: false,
                        }
                    }));
                    // Creates a red marker with the coffee icon
                    var markerClNonVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'red',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    var MarkerClientVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    var GreenMarkerClientVisiteIcon = L.ExtraMarkers.icon({
                        icon: 'fa-home',
                        markerColor: 'green',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });

                    _.each(vm.rapportvisites, (element, index) => {
                        // position visite

                        var markerClientVisite = L.marker([element.latitude, element.longitude], { icon: MarkerClientVisiteIcon }).addTo(mymap);
                        // markerClientVisite.bindTooltip(element.code, { permanent: true, direction: 'top' });

                        var infoClientVisite = $compile(`
                                                 <div id="bodyContent" style="width=400px;">
                                                      <div class="text-center">
                                                        <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                          Historique
                                                        </a>
                                                      </div>
                                                      <div><b>Code</b> : ${element.codeClient}</a></div>
                                                      <div><b>Client</b> : ${element.nomclient}</a></div>
                                                      <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                      <div><b>Temps de visite</b> : ${element.difference}</div>
                                                      <div><b>Distance de visite</b> : ${element.distance}</div>
                                                      <div><b>Commentaire: </b> : ${element.commentaire}</a></div>
                                                </div>
                                                `)($scope);
                        var popupClientVisite = L.popup({
                            maxWidth: 600,
                            minWidth: 300
                        }).setContent(infoClientVisite[0])
                        markerClientVisite.bindPopup(popupClientVisite);

                        // position client visite
                        // var markerClNonVisite = L.marker([element.c_latitude, element.c_longitude], { icon: GreenMarkerClientVisiteIcon }).addTo(mymap);
                        // var infoNonVisite = $compile(`
                        //                          <div id="bodyContent" style="width=400px;">
                        //                               <div class="text-center">
                        //                                 <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                        //                                   Historique
                        //                                 </a>
                        //                               </div>
                        //                               <div><b>Code</b> : ${element.codeClient}</a></div>
                        //                               <div><b>Client</b> : ${element.nomclient}</a></div>
                        //                               <div><b>Magasin</b> : ${element.magasin}</a></div>
                        //                               <div><b>Temps de visite</b> : ${element.difference}</div>
                        //                               <div><b>Distance de visite</b> : ${element.distance}</div>
                        //                               <div><b>Commentaire: </b> : ${element.commentaire}</a></div>
                        //                         </div>
                        //                         `)($scope);
                        // var popupNonVisite = L.popup({
                        //     maxWidth: 600,
                        //     minWidth: 300
                        // }).setContent(infoNonVisite[0])
                        // markerClNonVisite.bindPopup(popupNonVisite);
                    });
                    //console.log('vm.tournees_non_traite', vm.tournees_non_traite);
                    $scope.$watchCollection(
                        "vm.tournees_non_traite",
                        function (newValue, oldValue) {

                            _.each(newValue, (element, index) => {
                                // position client non visite
                                var markerClNonVisite = L.marker([element.latitude, element.longitude], { icon: markerClNonVisiteIcon }).addTo(mymap);
                                var infoNonVisite = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <div class="text-center">
                                                                <a ui-sref="app.clients.historiques({code : '${element.codeClient}'})" target="_blank">
                                                                  Historique
                                                                </a>
                                                              </div>
                                                              <div><b>Code</b> : ${element.client_code}</a></div>
                                                              <div><b>Client</b> : ${element.nomClient}</a></div>
                                                              <div><b>Magasin</b> : ${element.magasin}</a></div>
                                                        </div>
                                                        `)($scope);
                                var popupNonVisite = L.popup({
                                    maxWidth: 600,
                                    minWidth: 300
                                }).setContent(infoNonVisite[0])
                                markerClNonVisite.bindPopup(popupNonVisite);
                            });



                        }
                    );

                    setTimeout(function () {
                        mymap.invalidateSize();
                    }, 80);
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    vm.mapForRapporttournee = function () {
        $timeout(function () {
            NgMap.getMap({ id: 'mapforrapporttournee' }).then(function (response) {
                google.maps.event.trigger(response, 'resize');
                //response.setCenter({lat: Number(vm.getpays.latitude), lng: Number(vm.getpays.longitude)});
            });
        }, 7000);
    };

    vm.getmontantttc = function (code, date) {
        WS.get('entetecommercial/ttc' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montantttc = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getmontant_total_bl_FromEntete = function (code, date) {

        WS.get('bl/distinctmontant' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montant_total_ttcFromEntete = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getmontant_total_cmd_FromEntete = function (code, date) {

        WS.get('commandes/distinctmontant' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.montant_total_cmd_ttcFromEntete = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getrecouvrementttc = function (code, date) {
        WS.get('paiements/ttc' + '/' + code + '/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.recouvrementttc = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.differncetime = function (firsttime, lasttime) {
        var val1 = moment(firsttime, "HH:mm:ss");
        var val2 = moment(lasttime, "HH:mm:ss");
        //console.log(val1,val2);

        var hDiff = val2.diff(val1, 'hours');
        var mDiff = val2.diff(val1, 'minutes');
        var sDiff = val2.diff(val1, 'seconds');

        var secondDuration = sDiff % 60;
        var minuteDuration = mDiff % 60;
        //var minuteDuration = Math.floor(sDiff % 60);
        var hourDuration = Math.floor(mDiff / 60);


        return hourDuration + ':' + minuteDuration + ':' + secondDuration;
    };

    vm.timework = function (visites) {
        if (visites.length > 0) {
            var time = moment('00:00:00', 'HH:mm:ss');
            visites.forEach(function (element, index) {

                var duration = moment.duration({
                    seconds: moment(element.difference, "HH:mm:ss").format('ss'),
                    minutes: moment(element.difference, "HH:mm:ss").format('mm'),
                    hours: moment(element.difference, "HH:mm:ss").format('HH')
                });
                time.add(duration);
            });
            return time.format('HH:mm:ss');
        }
    };


    /**
     * Dev App
     * get ALL distinct route with tournee with all clients inside tournee
     */
    vm.getRouteTourneeClient = function () {
        WS.getData('tournees/getRouteTourneeClient', { type: 'commande' })
            .then(function (response) {
                vm.getroutetourneeclient = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    /**
     * Dev App
     * get All visites and compare with tourne client where date
     */
    vm.getAllVisitesforTourne = function () {
        WS.get('tournees/getAllVisitesforTourne')
            .then(function (response) {
                vm.getvisitesfortourne = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.checkifvalueexist = function (code, list) {
        var info = 0;
        if (list.length == 0) {
            return info;
        }
        angular.forEach(list, function (element, index) {
            if (code == element.client_code) {
                info = info + 1;
            }
        });
        return info;
    };

    vm.commercialByGamme = function () {
        WS.get('dashboard/commercialByGamme/commande')
            .then(function (response) {
                var commercials = [];
                var config = [];
                var formatconf = [];
                var getpros = [];
                var total = 0;
                response.data.forEach(function (element, index) {
                    var stock = {};
                    for (var i = 0; i < _.size(element) - 1; i++) {
                        var gamme = element[i].gamme.replace(/ /g, '_').replace(/'/g, '_');
                        stock[gamme] = Number(element[i].sum);
                        /**** new new  sum of sum hhhh ***/
                        total = total + Number(element[i].sum);
                        /**** new new  sum of sum hhhh ***/
                        getpros.push({ name: element[i].gamme });
                        if (i == 0) {
                            stock.commercial = element['commercial'];
                        }
                    }
                    stock.commercial = stock.commercial + '(' + total.toFixed(2) + ')';
                    total = 0;
                    commercials.push(stock);
                });
                vm.countcommercials = commercials.length;
                $scope.$apply();
                formatconf = _.uniq(getpros, 'name');
                formatconf.forEach(function (element, index) {
                    config.push({
                        "balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
                        "fillAlphas": 1,
                        "labelText": "[[value]]",
                        "lineAlpha": 1,
                        "title": element.name,
                        "type": "column",
                        "color": "#000000",
                        "valueField": element.name.replace(/ /g, '_').replace(/'/g, '_')
                    });
                });
                //pdvs = _.sortBy(pdvs, function(item) {  return (- _.size(item) - 1); });

                commercials.sort(function (a, b) {
                    return (_.size(b)) - (_.size(a));
                });
                var chart = AmCharts.makeChart("commercialByGamme", {
                    "type": "serial",
                    "theme": "light",
                    "responsive": {
                        "enabled": false
                    },
                    "autoMargins": true,
                    "marginTop": 0,
                    "marginBottom": 0,
                    "chartScrollbar": {
                        "gridAlpha": 0,
                        "color": "#888888",
                        "scrollbarHeight": 55,
                        "backgroundAlpha": 0,
                        "selectedBackgroundAlpha": 0.1,
                        "selectedBackgroundColor": "#888888",
                        "graphFillAlpha": 0,
                        "autoGridCount": true,
                        "selectedGraphFillAlpha": 0,
                        "graphLineAlpha": 1,
                        "graphLineColor": "#c2c2c2",
                        "selectedGraphLineColor": "#888888",
                        "selectedGraphLineAlpha": 1
                    },
                    "legend": {
                        "horizontalGap": 10,
                        "maxColumns": 50,
                        "position": "right",
                        "useGraphSettings": true,
                        "markerSize": 10
                    },
                    "dataProvider": commercials,
                    "valueAxes": [{
                        "stackType": "regular",
                        "axisAlpha": 0.9,
                        "gridAlpha": 0
                    }],
                    "graphs": config,
                    "rotate": true,
                    "categoryField": "commercial",
                    "categoryAxis": {
                        "gridPosition": "start",
                        "axisAlpha": 0,
                        "gridAlpha": 0,
                        "position": "left"
                    },
                    "export": {
                        "enabled": false
                    }
                });
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.venteParMarques = function () {
        WS.get('dashboard/venteParMarques/commande')
            .then(function (response) {
                var commercials = [];
                var config = [];
                var formatconf = [];
                var getpros = [];
                var total = 0;
                if (response.data.length > 0) {
                    response.data.forEach(function (element, index) {
                        var stock = {};
                        for (var i = 0; i < _.size(element) - 1; i++) {
                            var marque = element[i].marque.replace(/ /g, '_').replace(/'/g, '_');
                            stock[marque] = Number(element[i].sum);
                            /**** new new  sum of sum hhhh ***/
                            total = total + Number(element[i].sum);
                            /**** new new  sum of sum hhhh ***/
                            getpros.push({ name: element[i].marque });
                            if (i == 0) {
                                stock.commercial = element['commercial'];
                            }
                        }
                        stock.commercial = stock.commercial;
                        total = 0;
                        commercials.push(stock);
                    });
                    vm.commsMarques = commercials.length;
                    $scope.$apply();
                    formatconf = _.uniq(getpros, 'name');
                    formatconf.forEach(function (element, index) {
                        config.push({
                            "balloonText": "<b>[[title]]</b><br><span style='font-size:14px'>[[category]]: <b>[[value]]</b></span>",
                            "fillAlphas": 1,
                            "labelText": "[[value]]",
                            "lineAlpha": 1,
                            "title": element.name,
                            "type": "column",
                            //"lineColor" : element.color,
                            "color": "#000000",
                            "valueField": element.name.replace(/ /g, '_').replace(/'/g, '_')
                        });
                    });
                    //pdvs = _.sortBy(pdvs, function(item) {  return (- _.size(item) - 1); });

                    commercials.sort(function (a, b) {
                        return (_.size(b)) - (_.size(a));
                    });
                    var chart = AmCharts.makeChart("venteParMarques", {
                        "type": "serial",
                        "theme": "light",
                        "responsive": {
                            "enabled": false
                        },
                        "autoMargins": true,
                        "marginTop": 0,
                        "marginBottom": 0,
                        /*"chartScrollbar": {
                            "gridAlpha":0,
                            "color":"#888888",
                            "scrollbarHeight":55,
                            "backgroundAlpha":0,
                            "selectedBackgroundAlpha":0.1,
                            "selectedBackgroundColor":"#888888",
                            "graphFillAlpha":0,
                            "autoGridCount":true,
                            "selectedGraphFillAlpha":0,
                            "graphLineAlpha":1,
                            "graphLineColor":"#c2c2c2",
                            "selectedGraphLineColor":"#888888",
                            "selectedGraphLineAlpha":1
                        },*/
                        "legend": {
                            "horizontalGap": 10,
                            "maxColumns": 50,
                            "position": "bottom",
                            "useGraphSettings": true,
                            "markerSize": 10,
                            "autoMargins": false,
                        },
                        "dataProvider": commercials,
                        "valueAxes": [{
                            "stackType": "regular",
                            "axisAlpha": 1,
                            "gridAlpha": 0,
                            "fontSize": 9
                        }],
                        "graphs": config,
                        "rotate": true,
                        //"colorField": "color",
                        "categoryField": "commercial",
                        "categoryAxis": {
                            "gridPosition": "start",
                            "axisAlpha": 0,
                            "gridAlpha": 0,
                            "position": "left"
                        },
                        "export": {
                            "enabled": false
                        }
                    });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectPrevendeur = () => {
        WS.get('users/selectPrevendeur')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectedUsers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.refreshSynchronisation = (user_code)  => {
        WS.post('users/refreshSynchronisation', {user_code})
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succés!',
                        'Synchronisation modifiée avec succès',
                        'success'
                    );
                    _.each(vm.selectedUsers, (element, index) => {
                        if(user_code==element.code){
                            vm.selectedUsers[index].last_sync = null
                            vm.selectedUsers[index].last_sync_type = true
                        }
                    })
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getclientIntourne = function (code, liste) {

        if (!liste) return false;
        for (var i = 0; i < liste.length; i++) {
            if (liste[i]['client_code'] == code) {
                return true;
            }
        }
    };

    vm.getTournee = function (code) {
        WS.get('tournees/getRouteTourneeClientByCommercial/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tournees = response.data.tournees;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.getClientNonTraiter = function (code) {
        WS.get('tournees/getClientNonTraiter/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tournees_non_traite = response.data.tournees;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.tauxTournee = function (date = null) {
        if (date) {
            var url = 'entetecommercial/calcul/tauxTournee/' + date;
        } else {
            var url = 'entetecommercial/calcul/tauxTournee';
        }
        WS.get(url)
            .then(function (response) {
                if (response.status == 200) {
                    vm.tauxtournees = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.traitementTourne = function (user_code, tauxtournees) {
        if (!tauxtournees) return 0;
        for (var i = 0; i < tauxtournees.length; i++) {
            if (tauxtournees[i].route.user_code == user_code) {
                if (tauxtournees[i].tournees.length > 0) {
                    return tauxtournees[i].tournees[0].visite * 100 / tauxtournees[i].tournees[0].clients;
                } else {
                    return 0;
                }
            }
        }
    };



    vm.date_kpi_changed = (date) => {
        console.log(date);

        vm.tauxTournee(date);

        WS.getData('dashboard/VenteAndRecetteForKpi/date', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByDay = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.getData('dashboard/VenteAndRecetteForKpi/month', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.getData('dashboard/VenteAndRecetteForKpi/year', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.venteAndRecetteByYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        /**
         * chiffre commandes
         */
        WS.getData('bl/byCommercial/commande/date/null', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialDate = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.getData('bl/byCommercial/commande/month', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.getData('bl/byCommercial/commande/year', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('paiements/creditByCommercialByDate/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.creditTodayForAll = 0;
                    vm.creditByCommercialByDate = response.data;
                    response.data.forEach(function (e, index) {
                        vm.creditTodayForAll += e.solde;
                    });

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.get('paiements/recouvrementByCommercial/' + date)
            .then(function (response) {
                if (response.status == 200) {
                    vm.recouvrementByCommercial = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        /**
         * count commandes day / year / month
         */

        WS.getData('commandes/byCommercial/count/month', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.getData('commandes/byCommercial/count/year', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
        WS.getData('commandes/byCommercial/count/date', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.bl_byCommercialCountDate = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        /**
         * visites
         */


        WS.getData('visites/byCommercial/count/date', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountDate = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.getData('visites/byCommercial/count/month', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.getData('visites/byCommercial/count/year', { date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.visites_byCommercialCountYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        /**
         * clients
         */
        WS.getData('clients/getNbOfNewClient', { interval: 'day', date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialToday = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });


        WS.getData('clients/getNbOfNewClient', { interval: 'month', date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialThisMonth = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });

        WS.getData('clients/getNbOfNewClient', { interval: 'year', date })
            .then(function (response) {
                if (response.status == 200) {
                    vm.clientsbyCommercialThisYear = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            })

    }

    vm.getHaversine = (latitude_client, longitude_client, latitude, longitude) => {
        const client = { latitude: parseFloat(latitude_client), longitude: parseFloat(longitude_client) }
        const visite = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) }

        if(_.isNaN(client.latitude) || _.isNaN(client.latitude)){
            return 'PCI'
        }
        else if(_.isNaN(visite.latitude) || _.isNaN(visite.latitude)){
            return 'PVI'
        }else {
            var position = haversine(client, visite)
            if(position > 1000){
                position = position / 1000
                position =  position.toFixed(0) + ' Km';
            }else{
                position =  position.toFixed(0) + ' m';
  
            }
            return position;
        }
         //return distance en métre
    }


    vm.getObjectifDashboard = () => {
        WS.getData('objectifs/getObjectifDashboard')
            .then(function (response) {
                if (response.status == 200) {
                    var obj = response.data;
                    var arrayMonth = [
                        '', 'janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 
                        'juillet','aout','septembre','octobre','novembre','decembre'
                    ];

                    var num =  moment().format('M');

                    var currentMonth = arrayMonth[num];

                    vm.objectif_vente_month = obj['r_'+currentMonth] * 100 / obj[currentMonth];
                    vm.objectif_cmd_month = obj['c_'+currentMonth] * 100 / obj[currentMonth];

                    vm.objectif_cmd_month = vm.objectif_cmd_month.toFixed(0);
                    vm.objectif_vente_month = vm.objectif_vente_month.toFixed(0);
                    // year 
                    var total_months = obj.janvier + obj.fevrier + obj.mars + obj.avril + obj.mai + obj.juin  + 
                                        obj.juillet + obj.aout + obj.septembre + obj.octobre + obj.novembre + obj.decembre;

                    var total_c_months = obj.c_janvier + obj.c_fevrier + obj.c_mars + obj.c_avril + obj.c_mai + obj.c_juin  + 
                                        obj.c_juillet + obj.c_aout + obj.c_septembre + obj.c_octobre + obj.c_novembre + obj.c_decembre;
                    
                    var total_r_months = obj.r_janvier + obj.r_fevrier + obj.r_mars + obj.r_avril + obj.r_mai + obj.r_juin  + 
                                        obj.r_juillet + obj.r_aout + obj.r_septembre + obj.r_octobre + obj.r_novembre + obj.r_decembre;

                    console.log(total_months, total_c_months, total_r_months);

                    vm.objectif_vente_year = total_r_months * 100 / total_months;
                    vm.objectif_cmd_year = total_c_months * 100 / total_months;

                    vm.objectif_cmd_year = vm.objectif_cmd_year.toFixed(0);
                    vm.objectif_vente_year = vm.objectif_vente_year.toFixed(0);

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getObjectifDashboard();


    vm.pointageDebut = (date = null) => {
        WS.getData('dashboard/pointageDebut', { date})
            .then(function (response) {
                if (response.status == 200) {
                    vm.pointage_debut = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.pointageDebut();




}
export default {
    name: 'dashboardcommandeController',
    fn: dashboardcommandeController
};