function traces(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.traces = {};

    vm.get = function() {
        WS.get('traces', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.traces = response.data;
                    $scope.$apply();
                    $('.table_traces').DataTable({
                        "language": DatatableLang,
                        responsive: true
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getEquipements = function() {
        WS.get('equipements', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.equipements = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getdepots = function() {
        WS.get('depots/all', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.depots = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getusers = function() {
        WS.get('users', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.users = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(trace) {
        WS.put('traces/' + trace.id, trace)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'trace modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer ce trace!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('traces/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'trace Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer ce trace',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.ajouter = function(trace) {
        WS.post('traces', trace)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'trace ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'traces',
    fn: traces
};