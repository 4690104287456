function nbLettre($translate) {
    'ngInject';
    return function(obj) {
        if (obj != undefined) {
            const TabUnit = Array("",
                $translate.instant("un"),
                $translate.instant("deux"), $translate.instant("trois"),
                $translate.instant("quatre"), $translate.instant("cinq"),
                $translate.instant("six"), $translate.instant("sept"),
                $translate.instant("huit"), $translate.instant("neuf"),
                $translate.instant("dix"), $translate.instant("onze"),
                $translate.instant("douze"), $translate.instant("treize"),
                $translate.instant("quatorze"), $translate.instant("quinze"),
                $translate.instant("seize"), $translate.instant("dix-sept"),
                $translate.instant("dix-huit"), $translate.instant("dix-neuf")
            );
            const TabDiz = Array("", "",
                $translate.instant("vingt"), $translate.instant("trente"),
                $translate.instant("quarante"), $translate.instant("cinquante"),
                $translate.instant("soixante"), $translate.instant("soixante"),
                $translate.instant("quatre-vingt"), $translate.instant("quatre-vingt")
            );


            console.log($translate.proposedLanguage());

            obj = obj.toFixed(3);
            obj = obj.toString().replace(/,/g, '');
            const expload = obj.split(".");
            console.log(expload);
            var millimes = '';
            /**
             * ********************************************* block des millimes *******************************************
             * traite_1 : table des millimes
             */
            if (expload[expload.length - 1] != "000") {
                var trait_1 = expload[expload.length - 1].split("");
                console.log(trait_1);
                /**
                 * traite de 1 centaine millimes
                 */
                if (Number(trait_1[0]) > 1) {
                    millimes = TabUnit[trait_1[0]] + ' Cent';
                } else if (Number(trait_1[0]) == 1) {
                    millimes = 'Cent';

                }
                ////////// derniere etape de centaine translate it
                millimes = $translate.instant(millimes);
                /**
                 * traite de 2 dizaine millimes
                 */


                if (Number(trait_1[1]) == 0) {
                    millimes = millimes; //ne rien faire.
                } else if (Number(trait_1[1]) == 1) {
                    var temp_dizaine = Number(trait_1[1] + trait_1[2]);
                    var traite_dizaine = TabUnit[temp_dizaine];
                    millimes += ' ' + $translate.instant(traite_dizaine);
                    console.log('millimes :', millimes);
                }

            }




            return obj;
        }
    };

}

export default {
    name: 'nbLettre',
    fn: nbLettre
};