function assortimentsController(DatatableLang, $state, $scope, WS, $window, AppSettings) {
    'ngInject';
    const vm = this;
    vm.assortiments = [];

    vm.findAll = () => {
        WS.getData('assortiments/findAll',)
            .then(function (response) {
                if (response.status == 200) {
                    vm.assortiments = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

      vm.selectCategories = function () {
        WS.get('assortiments/selectCategories')
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectcategories = response.data;
                    vm.countByCategorie();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


      vm.checkIfSelected = (categorie_code, produit_code) => {
        var i = 0;
        var obj = {
            isExist: false,
            value: null
        }
        _.each(vm.conventions, (element, index) => {
            if (element.value_selected == produit_code && element.categorie_code == categorie_code) {
                i++;
                obj.value = element;
                obj.isExist = true;
            }
        });

        // if (i == 0) {
        //     return false;
        // } else {
        //     return true;
        // }
        return obj;
    };


    vm.storeConvention = function () {
        vm.show_loading = true;
        var conventions = _.reject(vm.conventions, d => d.new == false);
        var data = {
            date_debut: vm.date_debut,
            date_fin: vm.date_fin,
            type: vm.table_type,
            conventions: conventions
        };

        if (data.date_debut == "" || data.date_fin == "") {
            swal('Oups', 'Veuillez bien choisir date debut et date fin', 'warning');
        } else if (data.conventions.length == 0) {
            swal('Oups', 'Veuillez bien selectionner au moins une categorie', 'warning');
        } else {
            WS.post('conventions/storeConvention', data)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'Conventions ajoutée avec succée',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        }).catch(function () {
                            $window.location.reload();
                        });
                    }
                    vm.show_loading = false;
                })
                .then(null, function (error) {
                    console.log(error);
                });
        }
    };


    vm.checkproduct = (produit_code, categorie, value) => {
        value = (value==0) ? 1 : 0;
        var data = {
            produit_code,
            categorie,
            value
        };
        //console.log(data);
        WS.post('assortiments/checkproduct', data)
        .then(function (response) {
            if (response.status == 200) {
                const index = vm.assortiments.findIndex(item => item.code == produit_code);
                //console.log('index', index)
                vm.assortiments[index][categorie]=value;
                $scope.$apply();
            }
        })
        .then(null, function (error) {
            console.log(error);
        });
    };

    vm.categorieSelected = (categorie_code, action) => {
        /**
         * if( action == true) insert else delete by categorie_code
         */
        //console.log(categorie_code, action);

        if (action == true) {
            _.each(vm.defaultselected, (element, index) => {
                var i = 0;
                _.each(vm.conventions, (convention, indexConv) => {
                    if (convention.value_selected == element.code && convention.categorie_code == categorie_code) {
                        i++;
                    }
                });
                if (i == 0) {
                    vm.conventions.push({
                        value_selected: element.code, categorie_code, new: true
                    })
                }
            });
        } else {
            vm.conventions = _.reject(vm.conventions, d => d.categorie_code == categorie_code && d.new == true);

        }
        vm.countByCategorie();
        //console.log(action, vm.conventions);

    };
    vm.produitSelected = (produit_code, action) => {
        /**
         * if( action == true) insert else delete by produit_code
         */
        // console.log(produit_code, action);

        if (action == true) {
            _.each(vm.selectcategories, (categorie, indCategorie) => {
                var i = 0;

                _.each(vm.conventions, (convention, indexConv) => {

                    if (convention.value_selected == produit_code && convention.categorie_code == categorie.code) {
                        i++;
                    }
                });
                if (i == 0) {
                    vm.conventions.push({
                        value_selected: produit_code, categorie_code: categorie.code, new: true
                    })
                }
            });
        } else {
            vm.conventions = _.reject(vm.conventions, d => d.value_selected == produit_code && d.new == true);

        }

        vm.countByCategorie();
        //console.log(action, vm.conventions);

    };
    
}
export default {
    name: 'assortimentsController',
    fn: assortimentsController
};