function consignesController(DatatableLang, $state, $scope, WS, $window, AppSettings) {
    'ngInject';
    const vm = this;
   vm.get = function (){
       const data = {
           date_debut: vm.date_debut,
           date_fin: vm.date_fin,
           user_code: vm.user_code
       };
        WS.getData('consignes/findAll', data).then(
            (response) => {
                vm.consignes = response.data.consignes;
                vm.solde = response.data.solde;
                $scope.$apply();
            },
            (error)=>{
                console.log(error);
            }
        );
   };

   vm.clearGet = function(){
     vm.date_debut = '';
     vm.date_fin = '';
     vm.user_code = '';
     vm.get();
   };

   vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
}
export default {
    name: 'consignesController',
    fn: consignesController
};