function Socket($http, AppSettings, $state, $localStorage, $location, $rootScope) {
    'ngInject';

    var socket = io.connect(AppSettings.ADRESS_SERVER + ':' + AppSettings.BROADCAST_PORT);
    return {
        on: function (eventName, callback) {
            socket.on(eventName, function () {
                var args = arguments;
                $rootScope.$apply(function () {
                    callback.apply(socket, args);
                });
            });
        },
        emit: function (eventName, data, callback) {
            socket.emit(eventName, data, function () {
                var args = arguments;
                $rootScope.$apply(function () {
                    if (callback) {
                        callback.apply(socket, args);
                    }
                });
            })
        }
    };

}

export default {
    name: 'Socket',
    fn: Socket
};
