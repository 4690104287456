function smartAreaController($window) {
  'ngInject';
  const vm = this;

  vm.word_reserved = ['SELECT', 'SELER', 'SELEBRITE', 'FROM', 'WHERE', 'LEFT', 'JOIN', 'INNER', 'JOIN', 'ON', 'AND', 'OR'];


  vm.checkLastWord = () => {
    vm.smartArea = vm.smartArea.replace('↵', ' ');
    var tab_words = vm.smartArea.replace(/^\s+|\s+$/g, '').split(/\s+/);;
    tab_words = _.filter(tab_words, function (num) { return num != ''; });
    //console.log(tab_words);
    if (tab_words.length > 0) {
      var last_index_word = tab_words.length - 1;
      vm.list_of_reserved_word = filtreTexte(vm.word_reserved, tab_words[last_index_word]);
      console.log(vm.list_of_reserved_word);
      if (vm.list_of_reserved_word.length > 0) {
        vm.show_panel_reserved_words = true;
      } else {
        vm.show_panel_reserved_words = false;
      }
      appendDivTOCursor();
    }

  };


  function filtreTexte(arr, requete) {
    return arr.filter(function (el) {
      return el.toLowerCase().indexOf(requete.toLowerCase()) !== -1;
    })
  };



  function appendDivTOCursor() {

    $("#smartArea").keypress(function (e) {
      var mouseX = e.clientX;
      var mouseY = e.clientY;
      var startPosition = e.selectionStart;
      var endPosition = e.selectionEnd;
      console.log(e);
      console.log(startPosition, endPosition);

      // $('#panel_reserved_words').css({
      //   position: 'absolute',
      //   top: e.clientY + 'px',
      //   left: e.clientX + 'px'
      // });
      // e.target.append(appendText);
    });
  };

  vm.editorOptions = {
    // lineWrapping: true,
    // lineNumbers: true,
    // readOnly: 'nocursor',
    // mode: 'xml',
   // theme:'twilight',
    theme:'dracula',
    lineWrapping: true,
    lineNumbers: true,
    //mode: 'sql',
    lint: true,

    mode: "text/x-sql",
        extraKeys: {"Ctrl-Space": "autocomplete"}, // To invoke the auto complete
        //hint: $window.CodeMirror.hint.sql,
        autocapitalize : true,
        hintOptions: {
            tables: {
                "table1": [ "col_A", "col_B", "col_C" ],
                "table2": [ "other_columns1", "other_columns2" ]
            }
        }
  };



}
export default {
  name: 'smartAreaController',
  fn: smartAreaController
};