function jqvmaptunisieDirective() {

    return {
        restrict: 'EAC',
        scope: {
        	v1: '=v1',
        	v2: '=v2',
        	dn: '=dn',
            an: '=g1',
			bj: '=g2',
			ba: '=g3',
			bz: '=g4',
			gb: '=g5',
			gf: '=g6',
			je: '=g7',
			kr: '=g8',
			ks: '=g9',
			kb: '=g10',
			kf: '=g11',
			mh: '=g12',
			mn: '=g13',
			me: '=g14',
			ms: '=g15',
			nb: '=g16',
			sf: '=g17',
			sz: '=g18',
			sl: '=g19',
			ss: '=g20',
			ta: '=g21',
			to: '=g22',
			tu: '=g23',
			za: '=g24'
        },
        link: function(scope, element, attrs) {   
			//console.log(element) ;
            $(element).vectorMap({
                map: 'tunisia',
                onRegionClick: function(e, code, region) {
                    var message = 'You clicked "' + region + '" which has the code: ' + code.toUpperCase();
                }
            });

            var c1 = '#d70206';
            var c2 = '#ffc107';
            var c3 = '#8bc34a';

            function rgba(x){
            	if( x == null ) return;
            	if( scope.v1 != null && scope.v2 != null){
            		if( x < scope.v1 / 100 ){
            			return c1;
	            	}else if(x<scope.v2 / 100){
	            		return c2;
	            	}
	            	return c3;
            	}else{
	            	if( x > 0 ){
	            		return c3;
	            	}
	            	return c1;
            	}
	            return '#fff';
            }
            //$(element).find('svg').css('height', $(element).css('height') );
            function colorer(type){
        		$(element).find('[data-code="tn-an"]').attr('fill', rgba( scope.an, 'an' ) );
				$(element).find('[data-code="tn-bj"]').attr('fill', rgba( scope.bj, 'bj' ) );
				$(element).find('[data-code="tn-ba"]').attr('fill', rgba( scope.ba, 'ba' ) );
				$(element).find('[data-code="tn-bz"]').attr('fill', rgba( scope.bz, 'bz' ) );
				$(element).find('[data-code="tn-gb"]').attr('fill', rgba( scope.gb, 'gb' ) );
				$(element).find('[data-code="tn-gf"]').attr('fill', rgba( scope.gf, 'gf' ) );
				$(element).find('[data-code="tn-je"]').attr('fill', rgba( scope.je, 'je' ) );
				$(element).find('[data-code="tn-kr"]').attr('fill', rgba( scope.kr, 'kr' ) );
				$(element).find('[data-code="tn-ks"]').attr('fill', rgba( scope.ks, 'ks' ) );
				$(element).find('[data-code="tn-kb"]').attr('fill', rgba( scope.kb, 'kb' ) );
				$(element).find('[data-code="tn-kf"]').attr('fill', rgba( scope.kf, 'kf' ) );
				$(element).find('[data-code="tn-mh"]').attr('fill', rgba( scope.mh, 'mh' ) );
				$(element).find('[data-code="tn-mn"]').attr('fill', rgba( scope.mn, 'mn' ) );
				$(element).find('[data-code="tn-me"]').attr('fill', rgba( scope.me, 'me' ) );
				$(element).find('[data-code="tn-ms"]').attr('fill', rgba( scope.ms, 'ms' ) );
		        $(element).find('[data-code="tn-nb"]').attr('fill', rgba( scope.nb, 'nb' ) );
				$(element).find('[data-code="tn-sf"]').attr('fill', rgba( scope.sf, 'sf' ) );
				$(element).find('[data-code="tn-sz"]').attr('fill', rgba( scope.sz, 'sz' ) );
				$(element).find('[data-code="tn-sl"]').attr('fill', rgba( scope.sl, 'sl' ) );
				$(element).find('[data-code="tn-ss"]').attr('fill', rgba( scope.ss, 'ss' ) );
				$(element).find('[data-code="tn-ta"]').attr('fill', rgba( scope.ta, 'ta' ) );
				$(element).find('[data-code="tn-to"]').attr('fill', rgba( scope.to, 'to' ) );
				$(element).find('[data-code="tn-tu"]').attr('fill', rgba( scope.tu, 'tu' ) );
				$(element).find('[data-code="tn-za"]').attr('fill', rgba( scope.za, 'za' ) );
            }

            scope.$watch('dn',function(newval, oldval){
        		colorer( newval );
        	});
        	scope.$watch('v1',function(newval, oldval ){
            	if( newval == oldval ) return;
        		colorer( newval );
        	});
        	scope.$watch('v2',function(newval, oldval ){
            	if( newval == oldval ) return;
        		colorer( newval );
        	});
        }
    };
}

export default {
    name: 'jqvmaptunisie',
    fn: jqvmaptunisieDirective
};
