function caisseController($uibModal, $translate, $scope, WS, $window, AppSettings, $filter) {
    'ngInject';
    const vm = this;
    vm.regions = {};
    vm.current_date = moment().format('YYYY-MM-DD');
    vm.caisse_espece = 0;
    vm.caisse_cheque = 0;
    vm.caisse_credit = 0;
    vm.caisse_creditgms = 0;
    vm.caisse_credit_collectivite = 0;
    vm.caisse_credit_collectivite_depot = 0;
    vm.caisse_depense = 0;
    vm.caisse_carburant = 0;
    vm.caisse_entretien = 0;
    vm.caisse_echange_gms = 0;
    vm.caisse_gratuite = 0;
    vm.caisse_recouvrement = 0;
    vm.caisse_versement_banque=0;
    vm.caisse_banque=0;
    vm.caisse_remise=0;
    vm.caisse_avoir=0;
    vm.caisse_dlc=0;
    vm.commentaire='';
    vm.allsocietes = {};
    vm.caisse_ticket=0;
    vm.detect_action_disable=false;
    vm.cloture = 0;
    vm.modifrecette=1;


    vm.getMouvementsCaisse = function (date_debut,date_fin, commercial) {
        WS.get(`caisses/mouvement/${date_debut}/${date_fin}/${commercial}`)
            .then(function (response) {
                if (response.status == 200) {
                    vm.mouvements = response.data;
                    $scope.$apply();
                   
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.ajoutermouvement = function (mouvement) {
      
        WS.post('caisses/caisseextrait', mouvement)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succé'),
                        $translate.instant('Mouvement ajoutée avec succées'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getMouvementsAllCaisse = function () {
        WS.get('caisses/mouvement/all')
            .then(function (response) {
                if (response.status == 200) {
                    vm.mouvements = response.data;
                    $scope.$apply();
                   
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    
    vm.getSousSocietes = function () {
        WS.get('soussocietes/selectSoussocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.soussocietes = response.data;
                    if (vm.soussocietes.length == 1) {
                        vm.detail.soussociete_code = vm.soussocietes[0].code;
                    }
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    
    vm.extraitCaisse = () => {
        var data = {
            
            'date_debut': vm.date_debut,
            'date_fin': vm.date_fin,
            'commercial': vm.commercial,
        }
        WS.getData('caisses/extrait', data)
            .then(function (response) {
                if (response.status == 200) {
                    vm.extrait_caisse = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.exportCaisseSageX3 = (date_debut,date_fin, commercial) => {
        var user_code = commercial && commercial!= undefined && commercial!='' ? commercial : null
        var url=`caisses/exportCaisseSageX3/${date_debut}/${date_fin}/${user_code}`;
        $window.location.href = AppSettings.apiUrl + url;
    }

    vm.checkControlePassword = () => {
        WS.post('users/checkControlePassword', {password : vm.password})
            .then(function(response) {
                if (response.status == 200) {
                    if(response.data.success){
                        vm.cloture = 0;
                        vm.modifrecette=0;
                    }else{
                        swal(
                            $translate.instant('Oups!'),
                            $translate.instant('Mot de passe non valide!'),
                            'warning'
                        )
                    }
                    $scope.$apply();
                  
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });

    }

    
    vm.getCaisses = function() {
        WS.get('caisses/top50')
            .then(function(response) {
                if (response.status == 200) {
                    vm.caisses = response.data;
                    $scope.$apply();
                    /*$('.table').DataTable({
                        "language": DatatableLang,
                        order : ['3', 'desc']
                    });*/
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getFirstSousSociete = function() {
        WS.get('soussocietes/first')
            .then(function(response) {
                vm.societe = response.data;
               
                    $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.modifierCaisse = function(caisse) {
        console.log(caisse);
        caisse.recette = Number(caisse.recette);
        caisse.recette = Number((caisse.recette).toFixed(3));
        caisse.recette = caisse.recette.toString().replace(/,/g, '');

        caisse.journee = Number(vm.caisse_espece) +
                        Number(vm.caisse_cheque) +
                        Number(vm.caisse_credit) +
                        Number(vm.creditgms) +
                        Number(vm.credit_collectivite) +
                        Number(vm.credit_collectivite_depot) +
                        Number(vm.caisse_depense) +
                        Number(vm.caisse_carburant) +
                        Number(vm.caisse_entretien) +
                        Number(vm.caisse_echange_gms) +
                        Number(vm.caisse_gratuite) +
                        Number(vm.caisse_versement_banque) +
                        Number(vm.caisse_remise) +
                        Number(vm.total_tarifs) 
                        - Number(vm.caisse_recouvrement) +
                        Number(vm.caisse_ticket) +
                        Number(vm.caisse_avoir) +
                        Number(vm.caisse_dlc);

        caisse.ecart = Number(caisse.recette) + Number(caisse.valeur_dlc_casse) - caisse.journee;

        caisse.espece = vm.caisse_espece;
        caisse.cheque = vm.caisse_cheque;
        caisse.ticket = vm.caisse_ticket;
        caisse.credit = vm.caisse_credit;
        caisse.creditgms = vm.creditgms;
        caisse.credit_collectivite = vm.credit_collectivite;
        caisse.credit_collectivite_depot = vm.credit_collectivite_depot;
        caisse.depense = vm.caisse_depense;
        caisse.carburant = vm.caisse_carburant;
        caisse.entretien = vm.caisse_entretien;
        caisse.echange_gms = vm.caisse_echange_gms;
        caisse.gratuite = vm.caisse_gratuite;
        caisse.recouvrement = vm.caisse_recouvrement;
        caisse.versement_banque = vm.caisse_versement_banque;
        caisse.banque = vm.caisse_banque;
        caisse.avoir = vm.caisse_avoir;
        caisse.dlc = vm.caisse_dlc;
        caisse.casse = vm.caisse_casse;
        caisse.remise = vm.caisse_remise;
        caisse.commentaire = vm.commentaire;
        caisse.tarifs = vm.total_tarifs;
        
            // console.log(caisse);
        //console.log(caisse);
        WS.post('caisses', caisse)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Caisse Modifiée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', $translate.instant(response.data.message), 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getCaisseByDate = function(date_debut = null, date_fin = null, commercial = null) {
        var url = 'caisses/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.caisses = response.data;
                    
                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.getCumulEcart = (date_debut = null, date_fin = null, code) => {
        WS.get('caisses/cumul/' + code + "/" + date_debut + "/" + date_fin)
            .then(function(response) {
                vm.cumul = response.data;

                jQuery.noConflict();
                $('#cumulModal').modal('show');
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.exportCaisseByDate = function(date_debut = null, date_fin = null, commercial = null, gouvernorat = null) {
        var url = 'caisses/bydate/' + date_debut + "/" + date_fin + "/" + commercial;
        WS.get(url).then(function(response) {
            var filename = $translate.instant("Caisse By commercial") + '-' + date_debut + '-' + date_fin;

            var file = {
                sheetid: $translate.instant("Caisse By commercial") + '-' + date_debut + '-' + date_fin,
                headers: true,
                caption: {
                    title: $translate.instant("Caisse By commercial") + '-' + date_debut + '-' + date_fin,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [

                    { columnid: 'commercial', title: 'Commercial' },
                    { columnid: 'date', title: 'Date' },
                    { columnid: 'espece', title: 'Espece' },
                    { columnid: 'cheque', title: 'Cheque' },
                    { columnid: 'credit', title: 'Credit' },
                    { columnid: 'depense', title: 'Depense' },
                    { columnid: 'carburant', title: 'Carburant' },
                    { columnid: 'entretiren', title: 'Entretien' },
                    { columnid: 'gratuite', title: 'Gratuite' },
                    { columnid: 'recouvrement', title: 'Recouvrement' },
                    { columnid: 'journee', title: 'Total' },
                    { columnid: 'recette', title: 'Recette' },
                    { columnid: 'ecart', title: 'ecart' },
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, response.data]);
        }, function(error) {
            console.log(error);
        });
    };

    vm.exportCaisseByDateE = function(tableId, date_debut = null, date_fin = null, commercial = null) {
        const data = {
            date_debut,
            date_fin,
            commercial
        };
        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function(s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;
        var title = data.date_debut + '_||_' + data.date_fin + '_||_' + data.commercial;;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);

    };

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    
    vm.getRecetteByComm = function(date, commercial) {
        WS.get('caisses/recette/' + date + '/' + commercial, vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.recette = 0;
                    var espece = Number(response.data.espece);
                    var cheque = Number(response.data.cheque);
                    var traite = Number(response.data.traite);
                    var avoir = Number(response.data.avoir);
                    var recette = (espece + cheque + traite) + avoir;
                    vm.recette = recette;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getTotalRecette = function(list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['montant']);
        }
        return total;
    }

      vm.getTotalBycolonne = function(list, type) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i][type]);
        }
        return total;
    }

    vm.getTotalEcart = function(list) {
        if (!list) return 0;
        var total = 0;
        for (var i = 0; i < list.length; i++) {
            total = total + Number(list[i]['ecart']);
        }
        return total;
    };

    vm.clotureCaisse = function(id){
        swal({
            title: $translate.instant("Cloture"),
            text: $translate.instant("Valider Caisse"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: true
        }).then(function() {

            WS.put('caisses/clotureCaisse/'+id, {cloture : 1 })
                .then(function(response) {
                    if(response.data.success==true){
                        swal(
                            $translate.instant(`Succé`),
                            $translate.instant(`Caisse validé avec succé`),
                            'success'
                        ).then(function() {
                            $window.location.reload();
                        });
                    }
                   
                })
                .then(null, function(error) {
                    console.log(error);
                });
        }).catch(function(){
            console.log('cancelled!');
        });
    };

    vm.deleteCaisse = function(id){
        swal({
            title: $translate.instant('Caisse'),
            text: $translate.instant("voulez vous vraiment supprimer cette caisse!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('caisses/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Caisse supprimée avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('vous ne pouvez pas supprimer cette caisse'),
                        'warning'
                    );
                }
            );
        });
    };


    vm.printElementTable = function( body) {

        var mywindow = window.open('', 'PRINT');

        mywindow.document.write(`<html>
                                    <head>
                                    <title> ${document.title} </title>
                                    <style>
                                        .noprint{display: none;}.text-center{text-align:center}
                                        th{border:1px solid #ddd!important;}
                                        td{border:1px solid #ddd!important;}
                                    </style>
                                `);
        mywindow.document.write('</head><body >');
        //mywindow.document.write(document.getElementById(header).innerHTML);
        mywindow.document.write(document.getElementById(body).innerHTML);
        //mywindow.document.write(document.getElementById(footer).innerHTML);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }


    vm.selectSoussocietes = function () {
        WS.get('soussocietes/selectSoussocietes').then(
            function (response) {
                vm.soussocietes = response.data;
                _.each(vm.soussocietes , element => {
                    vm.allsocietes[element.code] = element.nom;
                })

                if(vm.soussocietes.length == 1){
                    vm.soussociete_code = vm.soussocietes[0].code;
                }
                
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    };
    /**
     * @edsc etat caisse /palette
     * achat et chargement
     */

    vm.getEtatCaisse = (date_debut, date_fin, commercial_code, soussociete_code, type = 'quantite') => {
        var data = {
            date_debut, date_fin, commercial_code, soussociete_code, type
        };
        WS.getData('caisses/getEtatCaisse', data)
            .then(function(response) {
                if (response.status == 200) {
                    vm.caisse_vente = response.data.vente;

                    vm.total_nbr_caisse_initial = 0;
                    vm.total_nbr_caisse_pm_initial = 0;
                    vm.total_nbr_palette_initial = 0;
                    
                    vm.total_chargement_caisse = 0;
                    vm.total_retour_caisse = 0;
                    vm.total_dlc_caisse = 0;
                    vm.total_casse_caisse = 0;

                    vm.total_chargement_caisse_pm = 0;
                    vm.total_retour_caisse_pm = 0;
                    vm.total_dlc_caisse_pm = 0;
                    vm.total_casse_caisse_pm = 0;

                    vm.total_chargement_palette = 0;
                    vm.total_retour_palette = 0;
                    vm.total_dlc_palette = 0;
                    vm.total_casse_palette = 0;

                    _.each(vm.caisse_vente, (element, index) => {

                        vm.total_nbr_caisse_initial += element.nbr_caisse;
                        vm.total_nbr_caisse_pm_initial += element.nbr_caisse_pm;
                        vm.total_nbr_palette_initial += element.nbr_palette;

                        vm.total_chargement_caisse += element.nbr_caisse_chargement;
                        vm.total_retour_caisse += element.nbr_caisse_retour;
                        vm.total_dlc_caisse += element.nbr_caisse_dlc;
                        vm.total_casse_caisse += element.nbr_caisse_casse;

                        vm.total_chargement_caisse_pm += element.nbr_caisse_pm_chargement;
                        vm.total_retour_caisse_pm += element.nbr_caisse_pm_retour;
                        vm.total_dlc_caisse_pm += element.nbr_caisse_pm_dlc;
                        vm.total_casse_caisse_pm += element.nbr_caisse_pm_casse;

                        vm.total_chargement_palette += element.nbr_palette_chargement;
                        vm.total_retour_palette += element.nbr_palette_retour;
                        vm.total_dlc_palette += element.nbr_palette_dlc;
                        vm.total_casse_palette += element.nbr_palette_casse;

                        if(element.list_chargements){
                            vm.caisse_vente[index].chargements= element.list_chargements.split(";");
                        }else{
                            vm.caisse_vente[index].chargements= [];
                        }

                        if(element.list_retours){
                            vm.caisse_vente[index].retours= element.list_retours.split(";");
                        }else{
                            vm.caisse_vente[index].retours= [];
                        }

                        if(element.list_dlc){
                            vm.caisse_vente[index].dlc= element.list_dlc.split(";");
                        }else{
                            vm.caisse_vente[index].dlc= [];
                        }

                        if(element.list_casse){
                            vm.caisse_vente[index].casse= element.list_casse.split(";");
                        }else{
                            vm.caisse_vente[index].casse= [];
                        }
                    })
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.openModalAddCaisseVente = () => {
        vm.modalInstance = $uibModal.open({
            animation: true,
            templateUrl: 'panelAddStockCaisse',
            size: 'md',
            backdrop: true,
            windowClass : 'z-index-sec-modal',
            controller: function($scope, $translate) {
 
                $scope.soussocietes = vm.soussocietes;
                $scope.selectusers = vm.selectusers;
                $scope.date = vm.date_debut;
                $scope.commercial = vm.commercial;
                $scope.soussociete_code = vm.soussociete_code;

                $scope.addNewEtatVente = function() {
                    var data = {
                        commercial_code : $scope.commercial,
                        soussociete_code : $scope.soussociete_code,
                        date : $scope.date,
                        nbr_caisse: $scope.nbr_caisse,
                        nbr_palette: $scope.nbr_palette 
                    };
                    console.log(data);
                    WS.post('caisses/addNewEtatVente', data).then(
                        function(response) {
                            if (response.data.success) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Action effectuée avec succès'),
                                    'success'
                                ).then(function() {
                                    vm.modalInstance.close();
                                }).catch(function() {
                                    //vm.modalInstance.close();
                                });
                            } else {
                                swal(
                                    $translate.instant('Attention'),
                                    $translate.instant(response.data.message),
                                    'warning'
                                );
                            }

                        },
                        function() {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('erreur de chargement des données!!'),
                                'warning'
                            );
                        }
                    );
                }
            }
        });
    }

    vm.list_factures = [];
    vm.list_factures_details = [];

    vm.getListGms = (caisse_code, type_facture) => {

        vm.list_factures = [];
        vm.list_factures_details = [];
        WS.getData('caisses/getListGms', {
            caisse_code, type_facture
        }).then(
            function (response) {
                
                vm.list_factures = response.data.list_factures;
                vm.list_factures_details = response.data.list_factures_details;
                
                $scope.$apply();
            },
            function (error) {
                console.log(error);
            });
    }


    vm.exportTableHtml = function (tableId, title = 'Rapport') {



        var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64 = function (s) { return $window.btoa(unescape(encodeURIComponent(s))); },
            format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };

        var table = $(tableId),
            ctx = { worksheet: 'WireWorkbenchDataExport', table: table.html() },
            href = uri + base64(format(template, ctx));

        //
        var download_link = document.createElement('a');
        download_link.href = href;

        download_link.download = title + ".xls";

        document.body.appendChild(download_link);
        download_link.click();
        document.body.removeChild(download_link);


    };
}
export default {
    name: 'caisseController',
    fn: caisseController
};