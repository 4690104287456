function fournisseurController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.fournisseurs = {};

    vm.get = function() {
        WS.get('fournisseurs', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.fournisseurs = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.update = function(fournisseur) {
        //console.log(fournisseur);
        WS.put('fournisseurs/' + fournisseur.id, fournisseur)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'fournisseur modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getFournisseurById = function() {
        //console.log(fournisseur);
        WS.getData('fournisseurs/' + $state.params.id)
            .then(function(response) {
                if (response.status == 200) {
                    vm.detail = response.data
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer ce fournisseur!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('fournisseurs/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'fournisseur Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer ce fournisseur',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.ajouter = function(fournisseur) {
        WS.post('fournisseurs', fournisseur)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'fournisseur ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'fournisseurController',
    fn: fournisseurController
};