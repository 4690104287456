function chargeurDirective($compile, $state, WS, $window) {
    'ngInject';
    return {
        restrict: 'E',
        scope:true,
        compile: function (telems, tattrs) {
            return {
                pre : function(scope, ielems, iattrs){
                    var familles;
                    // get commercial
                    WS.get('users/query/grade="LIV"')
                        .then(function(response) {
                            if (response.status == 200) {
                                scope.commercials = response.data;
                                scope.$apply();
                            } else {
                                console.log('cannot get the list of commercial chargeurDirective');
                            }
                        })
                        .then(null, function(error) {
                            console.log(error);
                        }
                    );

                        WS.get('gammes')
                        .then(function(response) {
                            if (response.status == 200) {
                                scope.gammes = response.data;
                                scope.$apply();
                            } else {
                                console.log('cannot get the list of gammes chargeurDirective');
                            }
                        })
                        .then(null, function(error) {
                            console.log(error);
                        }
                    );
                         WS.get('sousfamilles')
                        .then(function(response) {
                            if (response.status == 200) {
                                scope.sousfamilles = response.data;

                                scope.$apply();
                            } else {
                                console.log('cannot get the list of sousfamilles chargeurDirective');
                            }
                        })
                        .then(null, function(error) {
                            console.log(error);
                        }
                    );
  
                        function getfamilles(){
                            WS.get('familles')
                                .then(function(response) {
                                    if (response.status == 200) {
                                        familles = response.data;
                                       
                                        scope.$apply();
                                    } else {
                                        console.log('cannot get the list of familles chargeurDirective');
                                    }
                                })
                                .then(null, function(error) {
                                    console.log(error);
                                }
                            );
                        } 
                        
  
  
                     //get produits
                     WS.get('produits/names').then(
                        function(response) {
                            if (response.status == 200) {
                                scope.produits = response.data;
                                scope.$apply();
                          var  content = ``;
                            content += `<form  ng-submit="store(chargement)"">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                     <select class="form-control" required ng-model="chargement.ch.gamme">
                                            <option value="">Choisissez Gamme...</option>`;
                                        angular.forEach(scope.gammes , function(value, key){
                                            content += '<option value="'+value.code+'">'+value.libelle+'</option>';
                                        });
                                content +=`</select>
                                </div>
                               <div class="col-md-3 text-center">
                                     <select class="form-control" ng-init="getfamilles()" required ng-model="chargement.ch.famille">
                                            <option value="">Choisissez famille...</option>`;
                                        angular.forEach(familles , function(value, key){
                                            content += '<option value="'+value.code+'">'+value.libelle+'</option>';
                                        });
                                content +=`</select>
                                </div>
                                
                               <div class="col-md-3 text-center">
                                     <select class="form-control"  required ng-model="chargement.ch.sousfamille">
                                            <option value="">Choisissez sousfamilles...</option>`;
                                        angular.forEach(scope.sousfamilles , function(value, key){
                                            content += '<option value="'+value.code+'">'+value.libelle+'</option>';
                                        });
                                content +=`</select>
                                </div>
                                <div class="col-md-3">
                                    <button class="btn btn-info pull-right" type="submit"
                                     style="margin-right: 15px;">Enregistrer</button>
                                </div>
                            </div>
                            <table id="example" class="display table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                         <th>Code a barre</th>
                                          <th>Produit</th>
                                        <th> Quantite</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Code</th>
                                         <th>Code a barre</th>
                                        <th>Produit</th>
                                        <th> Quantite</th>   
                                    </tr>
                                </tfoot>
                                <tbody>`;
                                content += `<tr>
                                    <td></td>
                                    <td colspan="2">
                                        <select class="form-control" required ng-model="chargement.ch.commercial">
                                            <option value="">Choisissez commercials...</option>`;
                                        angular.forEach(scope.commercials , function(value, key){
                                            content += '<option value="'+value.id+'">'+value.nom+'</option>';
                                        });
                                content +=`</select>
                                    </td>
                                    <td></td>
                                </tr>`;
                                angular.forEach(scope.produits, function(value, key){
                                    content+= '<tr><td>'+ value.code
                                        +'<input type="hidden" '+
                                               'ng-init="chargement.ch.ch'+ key +'.produit_code='+value.code+'"'+ 
                                               'ng-model="chargement.ch.ch'+ key +'.produit_code">'+
                                        '</td>'+
                                        '<td>'+ value.code_a_barre +'</td>'+
                                        '<td>'+ value.libelle +'</td>'+
                                        '<td>'+
                                            '<input type="text" placeholder="0"'+ 
                                            ' ng-model="chargement.ch.ch'+ key +'.quantite" class="form-control">'+ 
                                        '</td></tr>' ;
                                });
                                content +=`</tbody>
                            </table>
                            <button class="btn btn-info pull-right" type="submit">Enregistrer</button>
                            </form>`;
                                var childNode = $compile(content)(scope);
                                telems.append(childNode);
                            } else {
                                console.log('cannot get the list of products !! error in directive chargement.js chargeur');
                            }
                        },function(error) {
                            console.log(error);
                        }
                    );
                },
                post : function (scope, ielems, iattrs) {
                    scope.store = function(chargement){
                        WS.post('mouvements/chargementStore', chargement)
                            .then(function(response) {
                                if (response.status == 200) {
                                    swal(
                                      'Succé',
                                      'chargement ajoutée avec succées',
                                      'success'
                                    ).then(function(){
                                        $window.location.reload();
                                    });

                                } else {}
                            })
                            .then(null, function(error) {
                                console.log(error);
                            }
                        );
                    }     
                }
            }
        }
    };
}

export default {
    name: 'chargeur',
    fn: chargeurDirective
};
