function recouvrementController($translate, $state, $scope, WS, $window, AppSettings, $uibModal) {
    'ngInject';
    const vm = this;
    vm.regions = {};
    vm.keymaps = AppSettings.keymaps;
    vm.currentLang = $translate.proposedLanguage() || $translate.use();

    
    vm.get = function(date_debut = null, date_fin = null, commercial = null) {
        vm.user_code = $state.params.user_code;
        if (vm.user_code) {
            var url = 'recouvrements/get/null/null/' + vm.user_code;
        } else {
            if (date_debut && date_fin) {
                var url = 'recouvrements/get/' + date_debut + '/' + date_fin;
                if (commercial) {
                    var url = url + '/' + commercial;
                }
            } else {
                var url = 'recouvrements/get/null/null';
                if (commercial) {
                    var url = url + '/' + commercial;
                }
            }
        }


        WS.get(url)
            .then(function(response) {
                if (response.status == 200) {
                    vm.totalmontant=0;
                    vm.recs = response.data;
                    response.data.forEach(function (e, index) {
                        vm.totalmontant += parseFloat(Math.abs(e.montant));
                    });
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.selectUsers = function() {
        WS.get('users/selectUsers')
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getModePaiement = function(code) {
        WS.get('recouvrements/getModePaiement/' + code)
            .then(function(response) {
                if (response.status == 200) {
                    vm.paiements = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.getDetailsbyCode = function(code) {
        var url = 'recouvrements/code/' + $state.params.id;
        WS.get(url)
            .then(function(response) {
                vm.details = response.data;
                $scope.$apply();
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportData = function(date_debut, date_fin, commercial) {
        //console.log(date_debut, date_fin);
        if (date_debut != "" && date_fin != "" && date_debut != undefined && date_fin != undefined) {
            var url = 'recouvrements/exportDataRecouvrement/' + date_debut + '/' + date_fin;
            if (commercial) {
                var url = url + '/' + commercial;
            }

        } else {
            var url = 'recouvrements/exportDataRecouvrement/null/null';
            if (commercial) {
                var url = url + '/' + commercial;
            }
        }
        WS.get(url)
            .then(function(response) {
                if (response.data.length > 0) {
                    var date_debut = response.data[response.data.length - 1].date;
                    var date_fin = response.data[0].date;
                    var filename = 'Recouvrement-' + date_debut + '-_-_-' + date_fin;

                    var stylecsv = {
                        sheetid: filename,
                        headers: true,
                        caption: {
                            title: filename,
                            style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                        },
                        //style:'background:#fff',
                        column: {
                            style: 'font-size:20px;background:#d1d1d1;'
                        },
                        columns: [
                            { columnid: 'code', title: 'Code' },
                            { columnid: 'vendeur', title: 'Vendeur' },
                            { columnid: 'depot', title: 'Camion' },
                            { columnid: 'soussociete', title: 'Distributeur' },
                            { columnid: 'client', title: 'Client' },
                            { columnid: 'montant', title: 'Montant' },
                            { columnid: 'date', title: 'Date' },

                            { columnid: 'espece', title: 'Espece' },

                            { columnid: 'cheque', title: 'Cheque' },
                            { columnid: 'num_cheque', title: 'Num_cheque' },
                            { columnid: 'banque_cheque', title: 'Banque_cheque' },
                            { columnid: 'date_echeance_cheque', title: 'Date_echeance_cheque' },

                            { columnid: 'traite', title: 'Traite' },
                            { columnid: 'num_traite', title: 'Num_traite' },
                            { columnid: 'banque_traite', title: 'Traite_cheque' },
                            { columnid: 'date_echeance_traite', title: 'Date_echeance_traite' },
                            { columnid: 'credit', title: 'Credit' }
                        ],
                        row: {
                            style: function(sheet, row, rowidx) {
                                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                            }
                        }
                    };
                    alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
                    alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);

                } else {
                    alert('Aucune ligne a exporter!');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };




    function roundToThree(num) {
        return +(Math.round(num + "e+3") + "e-3");
    };


    /*
    '*********** 
    ' Devise=0   aucune 
    '       =1   Dinar  
    '       =2   Dollar $ 
    ' Langue=0   Français 
    '       =1   Belgique 
    '       =2   Suisse 
    '*********** 
    ' Conversion limitée à 999 999 999 999 999 ou 9 999 999 999 999,99 
    ' si le nombre contient plus de 2 décimales, il est arrondit à 2 décimales 
    */
    vm.ConvNumberLetter_fr = function(Nombre, bCheckFloat) {
        Nombre = (Nombre) ? Nombre.toFixed(3) : Nombre;
        var strNombre = new String(Nombre);
        var TabNombre = new Array();
        var strLetter = new String();
        if (isNaN(parseFloat(Nombre))) return "";
        if (bCheckFloat) {
            TabNombre = strNombre.split(".");
            if (TabNombre.length > 2 || TabNombre.length <= 0) return "";
            for (var i = 0; i < TabNombre.length; i++) {
                if (i == 0)
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 1, 0);
                else
                if (TabNombre[i] != "000") {
                    strLetter = strLetter + ConvNumberLetter(parseFloat(TabNombre[i]), 0, 0) + 'Millimes';
                } else {
                    strLetter = strLetter;
                }
            }
            return strLetter;
        } else {
            strLetter = ConvNumberLetter(Nombre, 1, 0);
            return strLetter;
        }
    }

    function ConvNumberLetter(Nombre, Devise, Langue) {
        var dblEnt, byDec;
        var bNegatif;
        var strDev = new String();
        var strCentimes = new String();

        if (Nombre < 0) {
            bNegatif = true;
            Nombre = Math.abs(Nombre);
        }
        dblEnt = parseInt(Nombre);
        byDec = parseInt((Nombre - dblEnt) * 100);
        if (byDec == 0) {
            if (dblEnt > 999999999999999) {
                return "#TropGrand";
            }
        } else {
            if (dblEnt > 9999999999999.99) {
                return "#TropGrand";
            }
        }
        switch (Devise) {
            case 0:
                if (byDec > 0) strDev = " virgule";
                break;
            case 1:
                strDev = " Dinar";
                if (byDec > 0) strCentimes = strCentimes + " Cents";
                break;
            case 2:
                strDev = " Dollar";
                if (byDec > 0) strCentimes = strCentimes + " Cent";
                break;
        }
        if (dblEnt > 1 && Devise != 0) strDev = strDev + "s et";
        if (dblEnt == 0 && Devise != 0) strDev = "";
        var NumberLetter = ConvNumEnt(parseFloat(dblEnt), Langue) + strDev + " " + ConvNumDizaine(byDec, Langue) + strCentimes;
        return NumberLetter;
    }

    function ConvNumEnt(Nombre, Langue) {
        var byNum, iTmp, dblReste;
        var StrTmp = new String();
        var NumEnt;
        iTmp = Nombre - (parseInt(Nombre / 1000) * 1000);
        NumEnt = ConvNumCent(parseInt(iTmp), Langue);
        dblReste = parseInt(Nombre / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = "mille ";
                break;
            default:
                StrTmp = StrTmp + " mille ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " million ";
                break;
            default:
                StrTmp = StrTmp + " millions ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " milliard ";
                break;
            default:
                StrTmp = StrTmp + " milliards ";
        }
        NumEnt = StrTmp + NumEnt;
        dblReste = parseInt(dblReste / 1000);
        iTmp = dblReste - (parseInt(dblReste / 1000) * 1000);
        StrTmp = ConvNumCent(parseInt(iTmp), Langue);
        switch (iTmp) {
            case 0:
                break;
            case 1:
                StrTmp = StrTmp + " billion ";
                break;
            default:
                StrTmp = StrTmp + " billions ";
        }
        NumEnt = StrTmp + NumEnt;
        return NumEnt;
    }

    function ConvNumDizaine(Nombre, Langue) {
        var TabUnit, TabDiz;
        var byUnit, byDiz;
        var strLiaison = new String();

        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept",
            "huit", "neuf", "dix", "onze", "douze", "treize", "quatorze", "quinze",
            "seize", "dix-sept", "dix-huit", "dix-neuf");
        TabDiz = Array("", "", "vingt", "trente", "quarante", "cinquante",
            "soixante", "soixante", "quatre-vingt", "quatre-vingt");
        if (Langue == 1) {
            TabDiz[7] = "septante";
            TabDiz[9] = "nonante";
        } else if (Langue == 2) {
            TabDiz[7] = "septante";
            TabDiz[8] = "huitante";
            TabDiz[9] = "nonante";
        }
        byDiz = parseInt(Nombre / 10);
        byUnit = Nombre - (byDiz * 10);
        strLiaison = " "; //"-"
        if (byUnit == 1) strLiaison = " et ";
        switch (byDiz) {
            case 0:
                strLiaison = "";
                break;
            case 1:
                byUnit = byUnit + 10;
                strLiaison = "";
                break;
            case 7:
                if (Langue == 0) byUnit = byUnit + 10;
                break;
            case 8:
                if (Langue != 2) strLiaison = " "; //"-"
                break;
            case 9:
                if (Langue == 0) {
                    byUnit = byUnit + 10;
                    strLiaison = " "; //"-"
                }
                break;
        }
        var NumDizaine = TabDiz[byDiz];
        if (byDiz == 8 && Langue != 2 && byUnit == 0) NumDizaine = NumDizaine + "s";
        if (TabUnit[byUnit] != "") {
            NumDizaine = NumDizaine + strLiaison + TabUnit[byUnit];
        } else {
            NumDizaine = NumDizaine;
        }
        return NumDizaine;
    }

    function ConvNumCent(Nombre, Langue) {
        var TabUnit;
        var byCent, byReste;
        var strReste = new String();
        var NumCent;
        TabUnit = Array("", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf", "dix");

        byCent = parseInt(Nombre / 100);
        byReste = Nombre - (byCent * 100);
        strReste = ConvNumDizaine(byReste, Langue)
        switch (byCent) {
            case 0:
                NumCent = strReste;
                break;
            case 1:
                if (byReste == 0)
                    NumCent = "cent";
                else
                    NumCent = "cent " + strReste;
                break;
            default:
                if (byReste == 0)
                    NumCent = TabUnit[byCent] + " cents";
                else
                    NumCent = TabUnit[byCent] + " cent " + strReste;
        }
        return NumCent;
    }

    vm.deleteBl_all = function (code, user) {
        //console.log(code);
        //console.log(user);
        swal({
            title: $translate.instant(`Suppression`),
           // text: $translate.instant('code bl') + ` : `,
            html: `${$translate.instant('Vos étes sur le point de supprimer le bl ainsi que leurs mouvements, visites et paiements')}
                    <h4 class="text-center">${code}</h4>`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui, supprimer!'),
            cancelButtonText: $translate.instant('Non, Annuler!'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            $uibModal.open({
                animation: true,
                templateUrl: 'VerificationOfPassword',
                size: 'md',
                backdrop: true,
                controller: function ($scope, $translate) {
                    $scope.message_password = $translate.instant('confirmer le mot de passe');
                    $scope.current_numero = code;

                    $scope.deleteBl = function () {
                        if ($scope.set_password != '' && $scope.set_password != undefined) {
                            var data = {
                                numero: $scope.current_numero,
                                password: $scope.set_password,
                                type : 'recouvrement'
                            };
                            //console.log(data);
                            WS.post('entetecommercial/deleteFromDash', data).then(
                                function (response) {
                                    if (response.data.success) {
                                        swal(
                                            $translate.instant('Succès'),
                                            $translate.instant('Supprimée'),
                                            'success'
                                        ).then(function () {
                                            $window.location.reload();
                                        }).catch(function () {
                                            $window.location.reload();
                                        });;
                                    } else {
                                        swal(
                                            $translate.instant('Attention'),
                                            $translate.instant(response.data.message),
                                            'warning'
                                        );
                                    }
                                },
                                function () {
                                    swal(
                                        $translate.instant('Attention'),
                                        $translate.instant('erreur de chargement des données'),
                                        'warning'
                                    );
                                }
                            );
                        } else {
                            swal(
                                $translate.instant('Attention'),
                                $translate.instant('Mot de passe Obligatoire'),
                                'warning'
                            );
                        }
                    }
                }
            });

        });

    };

}
export default {
    name: 'recouvrementController',
    fn: recouvrementController
};