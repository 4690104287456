function releveprixController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.releveprix = {};
    vm.date = moment().format('YYYY-MM-DD');
    vm.user_code = '';
    vm.findAll = function() {
        WS.getData('releve_prix/findAll', {date : vm.date , user_code : vm.user_code})
            .then(function(response) {
                if (response.status == 200) {
                    vm.releveprix = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.findProds = function(client_code, user_code) {
        WS.getData('releve_prix/findProds', {date : vm.date, user_code, client_code})
            .then(function(response) {
                if (response.status == 200) {
                    vm.list_prods = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.selectUsers = function() {
        WS.get('users/selectUsers', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.exportRelevePrix = (tableId) => {
        var uri='data:application/vnd.ms-excel;base64,',
        template='<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
        base64=function(s){return $window.btoa(unescape(encodeURIComponent(s)));},
        format=function(s,c){return s.replace(/{(\w+)}/g,function(m,p){return c[p];})};

                var table=$(tableId),
                ctx={worksheet:'WireWorkbenchDataExport',table:table.html()},
                href=uri+base64(format(template,ctx));

                //
                var download_link = document.createElement('a');
                download_link.href = href;
                var user = (vm.user_code) ? vm.user_code : "All";
                var title= "Merchandising-"+user +'-'+vm.date;                
                 download_link.download = title + ".xls";
                
                document.body.appendChild(download_link);
                download_link.click();
                document.body.removeChild(download_link);
    };
}
export default {
    name: 'releveprixController',
    fn: releveprixController
};