function mouvement_fournisseursController(DatatableLang, $state, $scope, WS, $window, $translate) {
    'ngInject';
    const vm = this;
    vm.per_page = 50;
    vm.extrait_per_page=50;
    vm.filter = {};
    vm.filter_extrait = {};
    vm.selectFournisseurs = function () {
      WS.get("fournisseurs/selectFournisseursWithCode")
        .then(function (response) {
          if (response.status == 200) {
            vm.select_frs = response.data;
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
    };

    vm.selectSoussocietesOnly = function () {
      WS.get("soussocietes/selectSoussocietes").then(
        function (response) {
          vm.soussocietes = response.data;
  
          $scope.$apply();
        },
        function (error) {
          console.log(error);
        }
      );
    };

    vm.getMvtFournisseurs = () => {
        WS.getData("mouvement_fournisseurs/getMvtFournisseurs")
        .then(function (response) {
          if (response.status == 200) {
            vm.mvt_fournisseurs = response.data;
            vm.total_achat = 0;
            vm.total_paiement = 0;
            vm.total_stock = 0;
            vm.total_a_terme = 0;
            vm.total_encours = 0;
            vm.total_solde = 0;
            vm.nbr_facture = 0;
            vm.nbr_cmd = 0;
            vm.nbr_avoir = 0;
            _.each(vm.mvt_fournisseurs, element => {
              vm.total_achat += Number(element.achat)
              vm.total_paiement += Number(element.paiement);
              vm.total_stock += Number(element.stock)
              vm.total_a_terme += Number(element.a_terme_cheque)

              vm.total_encours +=  Number(element.achat) - Number(element.paiement) 
                                 - Number(element.montant_rs) + Number(element.a_terme_cheque) 
                                 + Number(element.a_terme_traite)

              vm.total_solde += Number(element.achat) - Number(element.paiement) - Number(element.montant_rs) - Number(element.casse) - Number(element.dlc) 
                                - Number(element.avoir_financier) -Number(element.ristourne_logistique) - Number(element.ristourne_commerciale)
                                - Number(element.valeur_casse) - Number(element.valeur_dlc) - Number(element.valeur_retour);

              vm.nbr_facture += Number(element.nbr_facture);
              vm.nbr_cmd += Number(element.nbr_cmd);
              vm.nbr_avoir += Number(element.nbr_avoir);


            })
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
      }



      vm.getMvtFournisseurs2 = (page = 1, per_page = 50) => {

        vm.filter.per_page = per_page
        vm.filter.page = page
        WS.getData("mouvement_fournisseurs/getMvtFournisseurs2", vm.filter)
        .then(function (response) {
          if (response.status == 200) {
            vm.mvt_fournisseurs2 = response.data.data;
            vm.current_page = response.data.current_page;
            vm.previous = vm.current_page - 1;
            vm.next = vm.current_page + 1;
            vm.from = response.data.form;
            vm.last_page = response.data.last_page;
            vm.next_page_url = response.data.next_page_url;
            vm.per_page = Number(response.data.per_page);
            vm.prev_page_url = response.data.prev_page_url;
            vm.to = response.data.to;
            vm.total = response.data.total;
            vm.paginate = [];
            for (var i = 1; i <= vm.last_page; i++) {
              vm.paginate.push(i);
            }
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
      }


      vm.exprtMvtFournisseurs2 = () => {

        WS.getData("mouvement_fournisseurs/exprtMvtFournisseurs2", vm.filter)
        .then(function (response) {
          if (response.status == 200) {
            var date_debut = vm.filter.date_debut ? vm.filter.date_debut  + '-_-_-' : '';
            var date_fin = vm.filter.date_fin ? vm.filter.date_fin + '-_-_-' : '' ;
            var fournisseur_code = vm.filter.fournisseur_code ? '-' + vm.filter.fournisseur_code + '-_-_-' : '';
            var type = vm.filter.type ? vm.filter.type+ '-_-_-'  : '';
            var ref_facture = vm.filter.ref_facture ? vm.filter.ref_facture : '';
            var filename = 'MvtFournisseurs' + fournisseur_code + type + date_debut + date_fin  + ref_facture;
           
           //Fournisseur 	Sous société 	Date 	Ref Facture 	Type d'opération 	Montant 	Type 	Numero piece 	Date Echeance 	Commentaire
            var stylecsv = {
                sheetid: filename,
                headers: true,
                // caption: {
                //     title: filename,
                //     style: 'font-size: 50px; color:blue;' // Sorry, styles do not works
                // },
                //style:'background:#fff',
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    { columnid: 'fournisseur', title: 'Fournisseur' },
                    { columnid: 'soussociete', title: 'Distributeur' },
                    { columnid: 'date', title: 'Date' },
                    { columnid: 'reference', title: 'Ref Facture' },
                    { columnid: 'entete_achat_code', title: 'Code Facture' },
                    { columnid: 'type', title: 'Type d\'opération' },
                    { columnid: 'montant', title: 'Montant' },
                    { columnid: 'type_montant', title: 'Type' },
                    { columnid: 'num_cheque', title: 'Numero Cheque' },
                    { columnid: 'date_echeance_cheque', title: 'Date Echeance Cheque' },
                    { columnid: 'num_traite', title: 'Numero Traite' },
                    { columnid: 'date_echeance_traite', title: 'Date Echeance Traite' },
                    { columnid: 'commentaire', title: 'Commentaire' }
                ],
                row: {
                    style: function (sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [stylecsv, response.data]);
            //alasql('SELECT * INTO CSV("' + filename + '.csv",?) FROM ?', [stylecsv, response.data]);
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
      }
      
      vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
          return false;
        } else {
          return true;
        }
      };


      vm.extraitFournisseurs = (page = 1, per_page = 50) => {

        vm.filter_extrait.per_page = per_page
        vm.filter_extrait.page = page
        WS.getData("mouvement_fournisseurs/extraitFournisseurs", vm.filter_extrait)
        .then(function (response) {
          if (response.status == 200) {
            vm.extrait_fournisseurs = response.data.data;
            vm.extrait_current_page = response.data.current_page;
            vm.extrait_previous = vm.extrait_current_page - 1;
            vm.extrait_next = vm.extrait_current_page + 1;
            vm.extrait_from = response.data.form;
            vm.extrait_last_page = response.data.last_page;
            vm.extrait_next_page_url = response.data.next_page_url;
            vm.extrait_per_page = Number(response.data.per_page);
            vm.extrait_prev_page_url = response.data.prev_page_url;
            vm.extrait_to = response.data.to;
            vm.extrait_total = response.data.total;
            vm.extrait_paginate = [];
            for (var i = 1; i <= vm.extrait_last_page; i++) {
              vm.extrait_paginate.push(i);
            }
            
            $scope.$apply();
          } else {
          }
        })
        .then(null, function (error) {
          console.log(error);
        });
      }

      vm.addMouvementFournisseur = (mouvement) => {
        WS.post("mouvement_fournisseurs/addMouvementFournisseur", mouvement).then(
          function (response) {
            if(response.data.success){

              vm.mvt_fournisseurs2.unshift(response.data.mouvement);
              $scope.$apply();
              swal(
                $translate.instant("Succés"),
                $translate.instant("Mouvement ajouté avec succés"),
                "success"
              );
            }
   
          },
          function (error) {
            console.log(error);
          }
        );
      }

}
export default {
    name: 'mouvement_fournisseursController',
    fn: mouvement_fournisseursController
};