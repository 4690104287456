function authorizationDirective($localStorage, WS) {
    'ngInject';
    return {
        restrict: 'A',
        scope: true,
        compile: function (element, attrs) {
            //console.log(attrs.authorization);
            //&& $localStorage.role.code != 'superviseur'
            if ($localStorage.role.code != 'admin' ) {
                const action = attrs.authorization;
                WS.get('roles/authorization_roles')
                    .then(
                        response => {
                            const authorized_actions = response.data;
                            var i = 0;
                            _.each(authorized_actions, (db_action, index) => {
                                while (db_action == action) {
                                    i++
                                    break;
                                }
                            })
                            if (i == 0) {
                                element.hide(0);
                                $( "a[authorization='"+action+"']").hide();
                                $( "div[authorization='"+action+"']").hide();
                                $( "span[authorization='"+action+"']").hide();
                                $( "button[authorization='"+action+"']").hide();
                                
                                $( "a[authorization='"+action+"']").remove();
                                $( "div[authorization='"+action+"']").remove();
                                $( "span[authorization='"+action+"']").remove();
                                $( "button[authorization='"+action+"']").remove();
                                element.remove();
                               //console.log(authorized_actions, action + ' => hided', action, element);
                            }
                            // //const ifExist = await _.contains(authorized_actions, action);
                            // console.log(authorized_actions, action, ifExist);
                            // if (!ifExist) {
                            //     element.hide(0);
                            // }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            }
        }
    };
}

export default {
    name: 'authorization',
    fn: authorizationDirective
};