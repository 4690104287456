function equipementsclients(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.equipements = {};
    vm.per_page = '15';
    vm.get = function() {
        WS.get('equipement_pdvs', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.equipements = response.data;
                    $scope.$apply();
                    $('.table_equipementclients').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.selectClients = function() {
        WS.get('clients/selectClientsWithCode').then(
            function(response) {
                vm.selectclients = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            }
        );
    };

    vm.update = function(equipement) {
        WS.put('equipement_pdvs/' + equipement.id, equipement)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succé',
                        'equipement modifiée avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };


    vm.delete = function(id, description) {
        swal({
            title: description,
            text: "voulez vous vraiment supprimer cette equipementClient!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('equipement_pdvs/' + id).then(
                function() {
                    swal(
                        'Succé',
                        'equipementClient supprimée avec succées.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette equipementClient!!',
                        'warning'
                    );
                }
            );
        });
    };

    vm.ajouter = function(equipement) {
        console.log(equipement);
        WS.post('equipement_pdvs', equipement)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succé',
                        'equipementClient ajoutée avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };



    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function (current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }


}
export default {
    name: 'equipementsclients',
    fn: equipementsclients
};