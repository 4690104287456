function CommaToDecimal() {

  	return function(value) {
        if(value){

        	return value.toString().replace(',', ' ') ; //? parseFloat(value).toFixed(3).toString().replace('.', ',') : null;
        }
        return '0';
    };


}

export default {
  name: 'CommaToDecimal',
  fn: CommaToDecimal
};

 