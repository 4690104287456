function paginateDirective($compile, $state, WS, $window) {
    'ngInject';
    return {
        restrict: 'E',
        scope:true,
        compile: function (telems, tattrs) {
            return {
                pre : function(scope, ielems, iattrs){
                    
                },
                post : function (scope, ielems, iattrs) {
                   
                }
            }
        }
    };
}

export default {
    name: 'paginate',
    fn: paginateDirective
};
