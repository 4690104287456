function DatatableLang($translate, datatable_fr, datatable_ar) {
    'ngInject';
    const currentLang = $translate.proposedLanguage();
    let lang_datatable;
    if (currentLang == "ar_AR") {
        lang_datatable = datatable_ar;
    } else if (currentLang == "fr_FR") {
        lang_datatable = datatable_fr;
    }
    return lang_datatable;
}

export default {
    name: 'DatatableLang',
    fn: DatatableLang
};