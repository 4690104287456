function sousfamilleController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.sousfamilles = {};

    vm.get = function() {
        WS.get('sousfamilles', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.sousfamilles = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    
    vm.getFamilles = function() {
        WS.get('familles', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.familles = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(sousfamille) {
        WS.put('sousfamilles/' + sousfamille.id, sousfamille)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'sousfamille modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette sousfamille!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('sousfamilles/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'sousfamille Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette sousfamille',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.ajouter = function(sousfamille) {
        WS.post('sousfamilles', sousfamille)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Sous Famille ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Error', response.data.message,
                        'error'
                    )
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'sousfamilleController',
    fn: sousfamilleController
};