function espaceChiffre() {

  	return function(value) {
        if(value){
        	
        	return number_format($value, 0, ' ', ' ');
        }
        return '0';
    };

}
export default {
  name: 'espaceChiffre',
  fn: espaceChiffre
};

 