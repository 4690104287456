function regionController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.regions = {};

    vm.get = function() {
        WS.get('regions', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(region) {
        WS.put('regions/' + region.id, region)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Region modifiée avec Succès',
                        'success'
                    )
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette region!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('regions/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'region Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette region',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    };

    vm.ajouter = function(region) {
        WS.post('regions', region)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Region ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'regionController',
    fn: regionController
};