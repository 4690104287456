function todoController($localStorage, $state, $scope, WS, $window, $compile, $parse) {
    'ngInject';
    const vm = this;
    vm.countblock = 1;
    vm.todo = {};
    vm.taches = [];
    vm.getTodos = function(){
        WS.get('todos').then(
            function(response){
                vm.todos = response.data;
                $scope.$apply();
            },function(error){
                console.log(error);
            }
        );
    };

    vm.getDetails = function(){
        WS.get('todos/'+ $state.params.todo_code ).then(
            function(response){
                vm.details = response.data.details;
                vm.todo = response.data.todo;
                vm.center = response.data.center;
                $scope.$apply();
            },function(error){
                console.log(error);
            }
        );
    };

    vm.selectUsers = function(){
        WS.get('users/selectUsers').then(
            function(response){
                vm.users = response.data;
            },function(error){
                console.log(error);
            }
        );
    };

    vm.selectClientsWithCode = function(){
        WS.get('clients/selectClientsWithCode').then(
            function(response){
                vm.clients = response.data;
            },function(error){
                console.log(error);
            }
        );
    };


    vm.addBlock = function(index){
        var newBlock = $compile(`<div class="row" id="block-`+ index +`">
                    <hr  style="border-top: 5px solid #9c9a9a!important;">
                    <div class="col-md-4"><input type="text" class="form-control" ng-model="vm.taches[`+ index +`].destination" placeholder="destination"></div>
                    <div class="col-md-4"><input type="text" class="form-control" ng-model="vm.taches[`+ index +`].title" placeholder="Titre"></div>
                    <div class="col-md-4">
                        <datepicker date-format="yyyy-MM-dd" selector="form-control" class="pull-left" style="width: 90%">
                          <div class="input-group">
                                <input class="form-control" ng-model="vm.taches[`+ index +`].date_before" placeholder="Date"/>
                                <span class="input-group-addon" style="cursor: pointer">
                                      <i class="fa fa-lg fa-calendar"></i>
                                </span>
                          </div>
                        </datepicker>
                        <button class="btn btn-xs btn-danger" type="button" ng-click="vm.removeBlock(`+index+`)"  style="margin-top: 5px;margin-left: 6px;">
                            <i class="fa fa-minus"></i>
                        </button>
                    </div>

                    <div class="col-md-12" style="margin-top: 7px; margin-bottom: 7px;width: 96%;margin-left: 3px;">
                      <textarea  class="form-control" ng-model="vm.taches[`+ index +`].description" placeholder="Description"></textarea>
                    </div>

                    <div class="col-md-3"><input type="time" class="form-control" ng-model="vm.taches[`+ index +`].time_before" placeholder="Temps"></div>
                    <div class="col-md-3"><input type="time" class="form-control" ng-model="vm.taches[`+ index +`].duree" placeholder="Duree"></div>
                    <div class="col-md-3">
                        <input type="file" class="form-control" 
                                ng-init="vm.taches[`+ index +`].image_before=null" 
                                ng-model="vm.taches[`+ index +`].image_before"  
                                accept="image/*"
                                onchange="angular.element(this).scope().uploadedFile(this, `+ index +`);">
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" class="form-control pull-left" ng-model="vm.taches[`+ index +`].required_img_after" 
                               style="width: 24px;margin-top: 0px;margin-left: 25px;">
                               <p style="margin-top: 6px;margin-left: 56px;">Image Obligatoire</p>
                    </div>
              </div>`)($scope);
        vm.countblock = index +1;
        $('#paneltaches').append(newBlock);
    };

    vm.removeBlock = function(index){
        if(vm.taches != undefined && vm.taches[index]){
            var mod = _.omit($parse("vm.taches")($scope), index);
            $parse("vm.taches").assign($scope, mod);
        }
        $('#block-'+index).remove();
    };

    vm.storeTodo = function(){
        WS.post('todos', vm.todo).then(function(response){
            angular.forEach(vm.taches, function(element,index){
                element.todo_code = response.data.todo_code;
                WS.postwithimg('todos/storeDetailsTodo', element).then(function(response){
                    
                }, function(error){
                    console.log(error);
                });
            });

        }, function(error){
            console.log(error);
        }).then(function(){
          //  $window.location.reload();
        });
    };

    vm.selectListClientByPaginate = (page = 1, per_page = 15) => {
        var data = {
            page,
            per_page,
            searchbox: vm.searchbox
        };
        WS.getData('clients/selectListClientByPaginate', data)
            .then((response) => {
                vm.list_clients = response.data.data;
                vm.current_page = response.data.current_page;
                vm.previous = vm.current_page - 1;
                vm.next = vm.current_page + 1;
                vm.from = response.data.form;
                vm.last_page = response.data.last_page;
                vm.next_page_url = response.data.next_page_url;
                vm.per_page = response.data.per_page;
                vm.prev_page_url = response.data.prev_page_url;
                vm.to = response.data.to;
                vm.total = response.data.total;
                vm.paginate = [];
                for (var i = 1; i <= vm.last_page; i++) {
                    vm.paginate.push(i);
                }
                //console.log(vm.list_clients);
                $scope.$apply();
            }, (error) => {

            })
    };
    vm.checkpaginate = function(current, page, lastpage) {
        if (page > current + 3 || page < current - 3) {
            return false;
        } else {
            return true;
        }
    }

    $scope.uploadedFile = function(element, index) {
        //console.log(element, index);
        var reader = new FileReader();
            reader.onload = function(event) {
              $scope.$apply(function() {
                vm.taches[index].image_before = element.files[0];
              });
            }
        reader.readAsDataURL(element.files[0]);
        $scope.$apply();
    };
}
export default {
    name: 'todoController',
    fn: todoController
};
