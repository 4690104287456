function equipement(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.equipements = {};

    vm.get = function() {
        WS.get('equipements', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.equipements = response.data;
                    $scope.$apply();
                    $('.table_equipement').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getTrace = function() {
        WS.get('traces/coresspondance')
            .then(function(response) {
                if (response.status == 200) {
                    vm.traces = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.getCommercialByEquipement = function(code, liste) {


        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i]['equipement_code'] == code) {
                return liste[i];
            }

        }
    }




    vm.update = function(zone) {
        WS.put('equipements/' + zone.id, zone)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succé',
                        'equipement modifiée avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.delete = function(id) {

        swal({
            title: 'equipement',
            text: "voulez vous vraiment supprimer cette equipement!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('equipements/' + id).then(
                function() {
                    swal(
                        'Succé',
                        'equipement supprimée avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention',
                        'vous ne pouvez pas supprimer cette equipement!',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    };

    vm.ajouter = function(zone) {

        WS.post('equipements', zone)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succé',
                        'equipement ajoutée avec succées',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'equipement',
    fn: equipement
};