function jqvmapDirective() {

    return {
        restrict: 'EAC',
        scope: {
        	v1: '=v1',
        	v2: '=v2',

        	dn: '=dn',
            an: '=g1',
			bj: '=g2',
			ba: '=g3',
			bz: '=g4',
			gb: '=g5',
			gf: '=g6',
			je: '=g7',
			kr: '=g8',
			ks: '=g9',
			kb: '=g10',
			kf: '=g11',
			mh: '=g12',
			mn: '=g13',
			me: '=g14',
			ms: '=g15',
			nb: '=g16',
			sf: '=g17',
			sz: '=g18',
			sl: '=g19',
			ss: '=g20',
			ta: '=g21',
			to: '=g22',
			tu: '=g23',
			za: '=g24',
			xa: '=g25',
			xb: '=g26',
			xc: '=g27',
			xd: '=g28',
			xe: '=g29',
			xf: '=g30',
			xg: '=g31',
			xh: '=g32',
			xi: '=g33',
			xj: '=g34',
			xk: '=g35',
			xl: '=g36',
			xm: '=g37',
			xn: '=g38',
			xo: '=g39',
			xp: '=g40',
			xq: '=g41',
			xr: '=g42',
			xs: '=g43',
			xt: '=g44',
			xu: '=g45',
			xv: '=g46',
			xx: '=g47',
			xy: '=g48'
        },
        link: function(scope, element, attrs) {    

            $(element).vectorMap({
                map: 'dz_fr',
                onRegionClick: function(e, code, region) {
                    var message = 'You clicked "' + region + '" which has the code: ' + code.toUpperCase();
                }
            });

            var c1 = '#d70206';
            var c2 = '#ffc107';
            var c3 = '#8bc34a';

            function rgba(x){
            	if( x == null ) return;
            	if( scope.v1 != null && scope.v2 != null){
            		if( x < scope.v1 / 100 ){
            			return c1;
	            	}else if(x<scope.v2 / 100){
	            		return c2;
	            	}
	            	return c3;
            	}else{
	            	if( x > 0 ){
	            		return c3;
	            	}
	            	return c1;
            	}
	            return '#fff';
            }
            //$(element).find('svg').css('height', $(element).css('height') );
            function colorer(type){
        		$(element).find('[data-code="1"]').attr('fill', rgba( scope.an, 'an' ) );
				$(element).find('[data-code="2"]').attr('fill', rgba( scope.bj, 'bj' ) );
				$(element).find('[data-code="3"]').attr('fill', rgba( scope.ba, 'ba' ) );
				$(element).find('[data-code="4"]').attr('fill', rgba( scope.bz, 'bz' ) );
				$(element).find('[data-code="5"]').attr('fill', rgba( scope.gb, 'gb' ) );
				$(element).find('[data-code="6"]').attr('fill', rgba( scope.gf, 'gf' ) );
				$(element).find('[data-code="7"]').attr('fill', rgba( scope.je, 'je' ) );
				$(element).find('[data-code="8"]').attr('fill', rgba( scope.kr, 'kr' ) );
				$(element).find('[data-code="9"]').attr('fill', rgba( scope.ks, 'ks' ) );
				$(element).find('[data-code="10"]').attr('fill', rgba( scope.kb, 'kb' ) );
				$(element).find('[data-code="11"]').attr('fill', rgba( scope.kf, 'kf' ) );
				$(element).find('[data-code="12"]').attr('fill', rgba( scope.mh, 'mh' ) );
				$(element).find('[data-code="13"]').attr('fill', rgba( scope.mn, 'mn' ) );
				$(element).find('[data-code="14"]').attr('fill', rgba( scope.me, 'me' ) );
				$(element).find('[data-code="15"]').attr('fill', rgba( scope.ms, 'ms' ) );
		        $(element).find('[data-code="16"]').attr('fill', rgba( scope.nb, 'nb' ) );
				$(element).find('[data-code="17"]').attr('fill', rgba( scope.sf, 'sf' ) );
				$(element).find('[data-code="18"]').attr('fill', rgba( scope.sz, 'sz' ) );
				$(element).find('[data-code="19"]').attr('fill', rgba( scope.sl, 'sl' ) );
				$(element).find('[data-code="20"]').attr('fill', rgba( scope.ss, 'ss' ) );
				$(element).find('[data-code="21"]').attr('fill', rgba( scope.ta, 'ta' ) );
				$(element).find('[data-code="22"]').attr('fill', rgba( scope.to, 'to' ) );
				$(element).find('[data-code="23"]').attr('fill', rgba( scope.tu, 'tu' ) );
				$(element).find('[data-code="24"]').attr('fill', rgba( scope.za, 'za' ) );
				
				$(element).find('[data-code="25"]').attr('fill', rgba( scope.za, 'xa' ) );
				$(element).find('[data-code="26"]').attr('fill', rgba( scope.za, 'xb' ) );
				$(element).find('[data-code="27"]').attr('fill', rgba( scope.za, 'xc' ) );
				$(element).find('[data-code="28"]').attr('fill', rgba( scope.za, 'xd' ) );
				$(element).find('[data-code="29"]').attr('fill', rgba( scope.za, 'xe' ) );
				$(element).find('[data-code="30"]').attr('fill', rgba( scope.za, 'xf' ) );
				$(element).find('[data-code="31"]').attr('fill', rgba( scope.za, 'xg' ) );
				$(element).find('[data-code="32"]').attr('fill', rgba( scope.za, 'kh' ) );
				$(element).find('[data-code="33"]').attr('fill', rgba( scope.za, 'xi' ) );
				$(element).find('[data-code="34"]').attr('fill', rgba( scope.za, 'xj' ) );
				$(element).find('[data-code="35"]').attr('fill', rgba( scope.za, 'xk' ) );
				$(element).find('[data-code="36"]').attr('fill', rgba( scope.za, 'xl' ) );
				$(element).find('[data-code="37"]').attr('fill', rgba( scope.za, 'xm' ) );

				$(element).find('[data-code="38"]').attr('fill', rgba( scope.za, 'xn' ) );
				$(element).find('[data-code="39"]').attr('fill', rgba( scope.za, 'xo' ) );
				$(element).find('[data-code="40"]').attr('fill', rgba( scope.za, 'xp' ) );
				$(element).find('[data-code="41"]').attr('fill', rgba( scope.za, 'xq' ) );
				$(element).find('[data-code="42"]').attr('fill', rgba( scope.za, 'xr' ) );
				$(element).find('[data-code="43"]').attr('fill', rgba( scope.za, 'xs' ) );
				$(element).find('[data-code="44"]').attr('fill', rgba( scope.za, 'xt' ) );
				$(element).find('[data-code="45"]').attr('fill', rgba( scope.za, 'xu' ) );
				$(element).find('[data-code="46"]').attr('fill', rgba( scope.za, 'xv' ) );
				$(element).find('[data-code="47"]').attr('fill', rgba( scope.za, 'xx' ) );
				$(element).find('[data-code="48"]').attr('fill', rgba( scope.za, 'xy' ) );

            }

            scope.$watch('dn',function(newval, oldval){
        		colorer( newval );
        	});
        	scope.$watch('v1',function(newval, oldval ){
            	if( newval == oldval ) return;
        		colorer( newval );
        	});
        	scope.$watch('v2',function(newval, oldval ){
            	if( newval == oldval ) return;
        		colorer( newval );
        	});
        }
    };
}

export default {
    name: 'jqvmap',
    fn: jqvmapDirective
};
