function analysesController($localStorage, $state, $scope, WS, $location, $anchorScroll) {
    'ngInject';
    const vm = this;
    vm.type = 'bl';
    vm.valeur1 = 30;
    vm.valeur2 = 60;
    //vm.gouvernorats = ['','ARIANA','BEJA','BEN AROUS','BIZERTE','GABES','GAFSA','JENDOUBA','KAIROUAN','KASSERINE','KEBILI','KEF','MAHDIA','MANOUBA','MEDENINE','MONASTIR','NABEUL','SFAX','SIDI BOUZID','SILIANA','SOUSSE','TATAOUINE','TOZEUR','TUNIS','ZAGHOUAN']; 
    WS.getScache('pays').then(function(response){
        vm.getpays = response.data;
        $scope.$apply();
    },function(error){
        console.log(error);
    });
    vm.getGouvernorats = function() {
        WS.get('gouvernorats')
            .then(function(response) {
                if (response.status == 200) {
                    var resultat = [''];
                    response.data.forEach(function(element, index){
                        resultat.push(element.libelle);
                    });
                    vm.gouvernorats = resultat;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getGouvernorats();
   vm.gototop = function(){
      $location.hash('analisestop');
      $anchorScroll();
   };

    // line chart
    WS.get('entetecommercial/analyses')
        .then(function(response) {
            if (response.status == 200) {
                vm.nbCommandes = 0;
                vm.nbBl = 0;
                vm.nbDevis = 0;

                vm.ventes_ht = new Array(31).fill(0);
                vm.commandes_ht = new Array(31).fill(0);
                response.data.forEach(function(e, index) {
                    var jour = parseInt(e.date.substr(8, 2));
                    if (e.type == 'bl') {
                        vm.ventes_ht[jour - 1] += e.net_a_payer;
                        vm.nbBl++;
                    }
                    if (e.type == 'commande') {
                        vm.commandes_ht[jour - 1] += e.net_a_payer;
                        vm.nbCommandes++;
                    }
                    if (e.type == 'devis') {
                        vm.nbDevis++;
                    }
                });
                vm.lineData = {
                    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
                    series: [
                        vm.ventes_ht, [],
                        vm.commandes_ht
                    ]
                };

                $scope.$apply();
            } else {}
        })
        .then(null, function(error) {
            console.log(error);
        });

    var getAnalyses = function() {
        WS.get('entetecommercial/analyses/by-months/month/' + vm.type)
            .then(function(response) {
                if (response.status == 200) {
                    vm.analyses = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });

        WS.get('entetecommercial/analyses/by-months/week/' + vm.type)
            .then(function(response) {
                if (response.status == 200) {
                    vm.analyses_week = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
        
        WS.get('entetecommercial/analyses/by-months/day/' + vm.type)
            .then(function(response) {
                if (response.status == 200) {
                    vm.analyses_day = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    getAnalyses();
    $scope.$watch('vm.type', function(newval, oldval){
        if(newval == oldval) return;
        getAnalyses();
    })

    vm.getMonthValue = function(month) {
        var nb = 0;
        $.each(vm.analyses, function(index, val) {
            if (val.month == month) {
                nb = val.montanttotalht;
            }
        });
        return parseFloat(nb).toFixed(3);
    }
    vm.getTotalValue = function() {
        var nb = 0;
        $.each(vm.analyses, function(index, val) {
            nb += val.montanttotalht;
        });
        return parseFloat(nb).toFixed(3);
    }
    vm.getWeekValue = function(week) {
        var nb = 0;
        $.each(vm.analyses_week, function(index, val) {
            if (val.week == week) {
                nb = val.montanttotalht;
            }
        });
        return parseFloat(nb).toFixed(3);
    }
    vm.getByDayValue = function(day) {
        var nb = 0;
        $.each(vm.analyses_day, function(index, val) {
            if (val.day == day) {
                nb = val.montanttotalht;
            }
        });
        return parseFloat(nb).toFixed(3);
    }
    $(document).ready(function() {
        var currentMonth = (new Date()).getMonth();
        $('.table-months').find('tr')
                          .find('td:nth('+currentMonth+'), th:nth('+currentMonth+')')
                          .css({
                            'background' : 'rgb(139, 195, 74)',
                            'color'      : 'white',
                            'text-align' : 'center'
                          });
    });
    vm.lineOptions = {
        height: '200px',
        axisX: {
            labelInterpolationFnc: function(value) {
                return value;
            }
        }
    };

    vm.regions = {};
    vm.gammes = {};
    vm.familles = {};
    vm.societes = {};
    vm.totalBlGouvernorate = [];
    vm.totalBlGouvernorateSociete = [];
    vm.getSocietes = function() {
        WS.get('societes')
            .then(function(response) {
                if (response.status == 200) {
                    vm.societes = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getRegions = function() {
        WS.get('regions')
            .then(function(response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getGammes = function() {
        WS.get('gammes')
            .then(function(response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                    vm.clearEmpty();
                    
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getFamilles = function() {
        WS.get('familles')
            .then(function(response) {
                if (response.status == 200) {
                    vm.familles = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getTotalBlGroupByGouvenorate = function(gamme, map_id) {
        WS.get('bl/lignes/byGamme/'+ gamme +'/gouvernorates')
            .then(function(response) {
                if (response.status == 200) {
                    vm.totalBlGouvernorate[map_id] = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getTotalBlGroupByGouvenorateBySociete = function(societe) {
        WS.get('bl/lignes/bySociete/'+ societe +'/gouvernorates')
            .then(function(response) {
                if (response.status == 200) {
                    vm.totalBlGouvernorateSociete = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getByGouvernorateNumber = function(list, num){
        if( !list ) return;
        for (var i = 0; i<list.length ; i++) {
            if(list[i].gouvernorat == num){
                return list[i].dn;
            }
        }
        return 0;
    }

    $scope.$watch('vm.gamme1', function(newval, oldval){
        if(newval == oldval) return;
        vm.getTotalBlGroupByGouvenorate( newval, 1);
    })
    $scope.$watch('vm.gamme2', function(newval, oldval){
        if(newval == oldval) return;
        vm.getTotalBlGroupByGouvenorate( newval, 2);
    })
    $scope.$watch('vm.gamme3', function(newval, oldval){
        if(newval == oldval) return;
        vm.getTotalBlGroupByGouvenorate( newval, 3);
    })

    $scope.$watch('vm.societe', function(newval, oldval){
        if(newval == oldval) return;
        vm.getTotalBlGroupByGouvenorateBySociete( newval);
    })

    vm.getRegions();
    vm.getGammes();
    vm.getSocietes();
    // vm.getFamilles();
    vm.analysesFilter=[];

    vm.getAnalysesFilter = function(type,produit_code, sousfamille_code, gamme_code, region_code){
        WS.get('entetecommercial/analyses/filter/'+type+'/'+produit_code+'/'+sousfamille_code+'/'+region_code)
            .then(function(response) {
                if (response.status == 200) {
                    vm.analysesFilter[type+"_"+produit_code+"_"+sousfamille_code+'_'+region_code] = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getColumnValue = function(list, column, value) {
        var nb = 0;
        $.each(list, function(index, val) {
            if (val[column] == value) {
                nb = val.montanttotalht;
            }
        });
        return parseFloat(nb).toFixed(3);
    }
    vm.clearEmpty = function() {
        jQuery(document).ready(function($) {
            $('.sousfamille').each(function(index, el) {
                if( $(this).find('.produit').length == 0){
                    $(this).remove();
                }
            });

            $('.famille').each(function(index, el) {
                if( $(this).find('.sousfamille').length == 0){
                    $(this).remove();
                }
            });


            $('.gamme').each(function(index, el) {
                if( $(this).find('.famille').length == 0){
                    $(this).remove();
                }
            });

            $('.region').each(function(index, el) {
                if( $(this).find('.gamme').length == 0){
                    $(this).remove();
                }
            });


        });
    };


}
export default {
    name: 'analysesController',
    fn: analysesController
};
