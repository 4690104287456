function OnRun($rootScope, AppSettings, $state, $localStorage, WS, $locale, $http) {
    'ngInject';


    $locale.NUMBER_FORMATS.GROUP_SEP = "";
    $locale.NUMBER_FORMATS.DECIMAL_SEP = ".";


    if (AppSettings.authType=="TOKEN") {

        // Function to get a cookie by name
        function getCookie(name) {
            var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
             
            if (match) {
                return match[2];
            } else {
                return null;
            }
        }


        var url_sanctum = AppSettings.apiUrl
            url_sanctum = url_sanctum.replace('api/v2/', '');
            $http({
                method: 'HEAD',
                url: `${url_sanctum}sanctum/csrf-cookie`,
                cache: false,
                headers: {
                    'withCredentials': true
                }
            }).then(
                function successCallback(response) {

                    // Retrieve CSRF token from cookies
                    var csrfToken = getCookie('XSRF-TOKEN');
                    
                    if (csrfToken) {
                        // Store CSRF token in localStorage
                        $localStorage['XSRF-TOKEN'] = csrfToken;
                        console.log('CSRF token generated and stored in localStorage');

                    } else {
                        console.log('CSRF token not found in headers or cookies');
                    }
                },
                function errorCallback(response) {
                    console.log('csrf_token generated err')
                },
            );
    }
    var sidebarAndContentHeight = function () {
        var content = $('.page-inner'),
            sidebar = $('.page-sidebar'),
            body = $('body'),
            height,
            footerHeight = $('.page-footer').outerHeight(),
            pageContentHeight = $('.page-content').height();

        content.attr('style', 'min-height:' + sidebar.height() + 'px !important');

        if (body.hasClass('page-sidebar-fixed')) {
            height = sidebar.height() + footerHeight;
        } else {
            height = sidebar.height() + footerHeight;
            if (height < $(window).height()) {
                height = $(window).height();
            }
        }

        if (height >= content.height()) {
            content.attr('style', 'min-height:' + height + 'px !important');
        }
    };
     var currentState = $state;
    // change page title based on state
    $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        $rootScope.pageTitle = '';
        if (toState.title) {
            $rootScope.pageTitle += toState.title;
            $rootScope.pageTitle += ' \u2014 ';
        }
        if (toState.bodyClass == 'page-login') {
            $('body').attr('class', '');
        }
        if (toState.bodyClass) {
            $('body').addClass(toState.bodyClass);
        }
        setTimeout(function () {
            sidebarAndContentHeight();

        }, 10);
        $rootScope.pageTitle += AppSettings.appTitle;
    });


    //permissions :
    if (!$localStorage.role && !$localStorage.user) {
        $state.go('access.login', { reload: true });
        return;
    }

    /**
     * Permissions
     */

    $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        //console.log('stateChangeSuccess', toState); && $localStorage.role.code != "superviseur"
        if ($localStorage.role.code != "admin") {
            if (toState.url != "/login") {
                //console.log(toState);
                const currentState = toState.name.split('.');
                let action;
                if (currentState.length == 3) {
                    action = currentState[2].toUpperCase() + '_' + currentState[1].toUpperCase();
                } else {
                    action = currentState[1].toUpperCase();
                }
                console.log('action :=> ', action);
                if (action != 'LOGOUT') {
                    WS.get('roles/authorization_roles').then(
                        (response) => {

                            const authorized_actions = response.data;

                            const ifExist = _.contains(authorized_actions, action);
                            //console.log(authorized_actions, action);
                            //console.log(ifExist);

                            if (!ifExist) {
                                $state.go("app.404.index", { reload: true });
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                }

            }
        }
    });
    // $rootScope.$on('$stateChangeStart', (event, toState) => {
    //     //console.log('stateChangeStart', toState);&& $localStorage.role.code != "superviseur"
    //     if ($localStorage.role.code != "admin") {
    //         if (toState.url != "/login") {
    //             const currentState = toState.name.split('.');
    //             let action;
    //             if (currentState.length == 3) {
    //                 action = currentState[2].toUpperCase() + '_' + currentState[1].toUpperCase();
    //             } else {
    //                 action = currentState[1].toUpperCase();
    //             }
    //             console.log('action :=> ', action);
    //             if (action != 'LOGOUT') {
    //                 WS.get('roles/authorization_roles').then(
    //                     (response) => {

    //                         const authorized_actions = response.data;

    //                         const ifExist = _.contains(authorized_actions, action);
    //                         //console.log(authorized_actions, action);
    //                         //console.log(ifExist);
    //                         if (!ifExist) {
    //                             $state.go("app.404.index", { reload: true });
    //                         }
    //                     },
    //                     (error) => {
    //                         console.log(error);
    //                     }
    //                 )
    //             }
    //         }
    //     }
    // });


    /************************
     * support nomadis info *
     ************************/
    $(document).scroll(function () {
        var value_scroll = $(window).scrollTop();
        if (value_scroll == 0) {
            $(".nomasupp").css("top", "45px");
        } else {

            $(".nomasupp").css("top", "0px");
            $(".nomasupp").css("position", "fixed");
        }
    });



    /**
     * socket IO
     */

    if (AppSettings.USING_SOCKET_AND_REDIS) {
        sock.on('users.logout', function (response) {
            var response = JSON.parse(response);
            var user = response.data.actionData;
            console.log('output : ', user);
            if ($localStorage.user.code == user.code) {
                $localStorage.$reset();
                $http.get(AppSettings.apiUrl + 'logout').then((response) => { }, ((err) => {
                    console.log(err);
                }));
                $state.go("access.login", { reload: true });
                $state.go('access.login', {}, {
                    reload: true
                });

            }
        });
    }



    // var audio = document.createElement('audio');
    // var source = document.createElement('source');
    // var media = document.getElementById('messenger');
    // media.appendChild(audio);
    // audio.appendChild(source);
    // source.setAttribute('src', '/assets/ringtones/messenger.mp3');
    // source.setAttribute('type', 'audio/mpeg');
    // audio.setAttribute('controls', 'controls');

    // audio.play();

}
export default OnRun;