function equipements_merchsController($translate, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.equipements = {};

    vm.storeEquipement = () => {
        WS.post('equipements_merchs/storeEquipement', vm.new).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Equipement ajouté avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    }).catch(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    vm.updateEquipement = () => {
        WS.post('equipements_merchs/updateEquipement', vm.update).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Equipement modifié avec Succès'),
                        'success'
                    ).then(function() {
                        //$window.location.reload();
                    }).catch(function() {
                        //$window.location.reload();
                    });
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    vm.getEquipements = () => {
        WS.getData('equipements_merchs/getEquipements').then(
            response => {
                vm.equipements_merchs = response.data;
                $scope.$apply();
            },
            error => {

            }
        )
    }

    vm.getDetailsEquipement = (equipement_code) => {
        WS.getData('equipements_merchs/getDetailsEquipement', { equipement_code}).then(
            response => {
                vm.details_equipements = response.data;
                $scope.$apply();
            },
            error => {

            }
        )
    };

    

    vm.storeDetailsEquipement = () => {
        vm.newdetails.equipement_merch_code = vm.update.code;
        WS.post('equipements_merchs/storeDetailsEquipement', vm.newdetails).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('details Equipement ajouté avec Succès'),
                        'success'
                    ).then(function() {
                        
                    }).catch(function() {
                    });
                    vm.details_equipements.push(vm.newdetails);
                    // vm.newdetails.code = '';
                    // vm.newdetails.libelle = '';
                    $scope.$apply();
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    vm.storeDetailsEquipement = () => {
        vm.newdetails.equipement_merch_code = vm.update.code;
        WS.post('equipements_merchs/storeDetailsEquipement', vm.newdetails).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('details Equipement ajouté avec Succès'),
                        'success'
                    ).then(function() {
                        
                    }).catch(function() {
                    });
                    vm.details_equipements.push(vm.newdetails);
                    // vm.newdetails.code = '';
                    // vm.newdetails.libelle = '';
                    $scope.$apply();
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    vm.removeDetails = (code) => {
        WS.post('equipements_merchs/removeDetails', {code}).then(
            response => {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('details Equipement supprimé avec Succès'),
                        'success'
                    ).then(function() {
                        
                    }).catch(function() {
                    });
                    vm.details_equipements = vm.details_equipements.filter(element => element.code != code);
                    // vm.newdetails.code = '';
                    // vm.newdetails.libelle = '';
                    $scope.$apply();
                } else {
                    swal(
                        $translate.instant('Oups'),
                        $translate.instant(response.data.message),
                        'warning'
                    )
                }
            },
            error => {

            }
        )
    }


    vm.select_type_clients = () => {
        WS.getData('type_clients').then(
            response => {
                vm.type_clients = response.data;
                $scope.$apply();
            },
            error => {

            }
        )
    }
}
export default {
    name: 'equipements_merchsController',
    fn: equipements_merchsController
};