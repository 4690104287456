function secteurController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.secteurs = {};

    vm.get = function() {
        WS.get('secteurs', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.secteurs = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.getDetails = function() {
        WS.get('secteurs/' + $state.params.id)
            .then(function(response) {
                if (response.status == 200) {
                    vm.secteursdetail = response.data;

                    $scope.$apply();
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getZones = function() {
        WS.get('zones', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.zones = response.data;

                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.getOneSecteur = function(secteur) {

        WS.get('secteurs/' + secteur)
            .then(function(response) {
                if (response.status == 200) {
                    $.each(vm.secteurs, function(key, value) {
                        if (value.id == secteur) {
                            vm.secteurs[key] = response.data;
                            $scope.$apply();
                        }
                    });
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(secteur) {
        console.log(secteur);
        WS.put('secteurs/' + secteur.id, secteur)
            .then(function(response) {
                if (response.status == 200) {
                    //vm.getOneSecteur(secteur.id);
                    swal(
                        'Succès',
                        'Secteur modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer ce Secteur!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('secteurs/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Secteur Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer ce Secteur',
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    }

    vm.ajouter = function(secteur) {
        WS.post('secteurs', secteur)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Secteur ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'secteurController',
    fn: secteurController
};