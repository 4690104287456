function WS($http, AppSettings,$state,$localStorage,$location) {
    'ngInject';

    const service = {};

    service.getScache = function(route) {
        let url = AppSettings.apiUrl + route;
        return new Promise((resolve, reject) => {
            $http.get(url, { cache: false }).then(function(data) {
                resolve(data);
            }, function(err, status) {
                reject(err, status);
            });
        });
    };
    service.get = function(route, data, page, cache = true) {
        let url = AppSettings.apiUrl + route;
        if(page){
            url += '?page=' + page;
        }
        if (data) {
            url += '/' + data;
        }
        if (!cache) {
            url += '?' + Date.now();
        }
        return new Promise((resolve, reject) => {
            $http.get(url, { cache: cache }).then(function(data) {
                resolve(data);
            }, function(err) {
                reject(err, status);
            });
        });
    };


    service.getData = function(route, params) {
        let url = AppSettings.apiUrl + route;
        return new Promise((resolve, reject) => {
            $http.get(url, {params : params,cache: false }).then(function(data) {
                resolve(data);
            }, function(err) {
                reject(err, status);
            });
        });
    }; 


    service.getDataBuffer = function(route, params) {
        let url = AppSettings.apiUrl + route;
        return new Promise((resolve, reject) => {
            $http.get(url, { params: params, cache: false, responseType: 'arraybuffer' })
                .then(function (data) {
                    resolve(data);
                })
                .catch(function (err) {
                    reject(err, status);
                });
        });
    };



    service.post = function(route, data) {
        let url = AppSettings.apiUrl + route;
        return new Promise((resolve, reject) => {
            $http({
                method: 'POST',
                url: url,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                data: $.param(data)
            }).then(function(data) {
                resolve(data);
            }, function(err, status) {
                reject(err, status);
            });
        });
    };

    service.postwithimg = function(route, object, method = "POST"){
        return new Promise((resolve, reject) => {
                $http({
                  method  : "POST",
                  url     : AppSettings.apiUrl + route,
                  processData: false,
                  contentType: false,
                  transformRequest: function (data) {
                      console.log(data);
                      var formData = new FormData();
                      if( method == "PUT"){
                        formData.append('_method', 'PUT');
                      }
                      $.each( data , function( key, value ) {
                        /*if($.isArray(value)){
                            angular.forEach(value, function(element, index){
                                var file = {};
                                file.lastModifiedDate = element.image_before.lastModifiedDate;
                                file.name = element.image_before.name;
                                file.size = element.image_before.size;
                                file.type = element.image_before.type;
                                file.webkitRelativePath = element.image_before.webkitRelativePath;
                                value[index].image_before = file;
                            });
                            formData.append(key , JSON.stringify(value));
                        }else{
                            formData.append(key , value); 
                        }*/ 
                        formData.append(key , value);                        
                            
                    });
                      return formData;  
                  },  
                  data : object,
                  headers: {
                         'Content-Type': undefined,
                         'accept': 'application/json charset=utf-8'
                  }
               }).then(function(data) {
                   console.log(data);
                    resolve(data);
                }, function(err, status) {
                    reject(err, status);
                });
        });  
    };

    service.put = function(route, data) {
        let url = AppSettings.apiUrl + route;
        // if (data) {
        //     url += '/' + data._id;
        // }

        return new Promise((resolve, reject) => {
            $http({
                method: 'PUT',
                url: url,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                data: $.param(data)
            }).then(function(data) {
                resolve(data);
            }, function(err, status) {
                reject(err, status);
            });;
        });
    };

    service.delete = function(route, data) {
        let url = AppSettings.apiUrl + route;
        if (data) {
            url += '/' + data._id;
        }

        return new Promise((resolve, reject) => {
            $http.delete(url).then(function(data) {
                resolve(data);
            }, function(err, status) {
                reject(err, status);
            });
        });
    };

    return service;

}

export default {
    name: 'WS',
    fn: WS
};
