function menuVenteDirective($translate) {

    return {
        restrict: 'EAC',
        templateUrl: '../views/partials/menu-vente.html',
        scope: {},
        link: (scope, element) => {}
    };
}

export default {
    name: 'menuVenteDirective',
    fn: menuVenteDirective
};