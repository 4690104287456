function gouvernoratController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.gouvernorats = {};
    vm.files = [];

    vm.get = function() {
        WS.get('gouvernorats', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.gouvernorats = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(gouvernorat) {
        console.log(gouvernorat);
        WS.put('gouvernorats/' + gouvernorat.id, gouvernorat)
            .then(function(response) {
                if (response.status == 200) {
                    //vm.getOnegouvernorat(gouvernorat.id);
                    swal(
                        'Succès',
                        'gouvernorat modifiée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        WS.delete('gouvernorats/' + id)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'gouvernorat supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette gouvernorat!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('gouvernorats/' + id).then(
                function() {
                    swal(
                        'Supprimée!',
                        'gouvernorat Supprimée.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette gouvernorat',
                        'warning'
                    );
                }
            );
        });
    };

    vm.ajouter = function(gouvernorat) {
        WS.post('gouvernorats', gouvernorat)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'gouvernorat ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    $scope.moveCSV = function(element) {
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.csvfile.file = event.target.result;
            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    };

    vm.savecsv = function() {
        vm.csvfile.file = vm.files[0];
        WS.postwithimg('gouvernorats/saveCsv', vm.csvfile, "POST")
            .then(function(response) {
                if (response.data.success == true) {
                    vm.traitform = false;
                    vm.csvfile = {}
                    $scope.$apply();
                    swal(
                        'Succès',
                        response.data.message,
                        'success'
                    ).then(function() {
                        WS.getScache('gouvernorats')
                            .then(function(response) {
                                if (response.status == 200) {
                                    vm.gouvernorats = response.data;
                                    $scope.$apply();
                                } else {}
                            })
                            .then(null, function(error) {
                                console.log(error);
                            });
                    });
                } else {
                    vm.traitform = false;
                    vm.csvfile = {}
                    $scope.$apply();
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            }, function(error) {
                console.log(error);
            });
    };

    vm.downloadCsvModel = function() {
        var filename = 'gouvernorat';
        alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [csvModel, []]);
    };

    var csvModel = {
        sheetid: 'gouvernorat',
        headers: true,
        column: {
            style: 'font-size:20px;background:#d1d1d1;'
        },
        columns: [
            { columnid: 'code', title: 'Code' },
            { columnid: 'libelle', title: 'Libelle' },
            { columnid: 'nbr_pdv', title: 'nbr_pdv' }
        ],
        rows: {
            style: function(sheet, row, rowidx) {
                return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
            }
        }
    };
}
export default {
    name: 'gouvernoratController',
    fn: gouvernoratController
};