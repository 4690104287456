function deviceController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.devices = {};

    vm.get = function() {
        WS.get('devices', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.devices = response.data;
                    $scope.$apply();
                    $('.table_device').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(device) {

        WS.put('devices/' + device.id, device)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'device modifié avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'device',
            text: "voulez vous vraiment supprimer cette device!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('devices/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'device supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette device!!',
                        'warning'
                    );
                }
            );
        });
    }



    vm.ajouter = function(device) {
        WS.post('devices', device)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'device ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



    vm.store = function() {
        vm.detail.fichier = vm.files[0];
        console.log(vm.detail.fichier);
        WS.postwithimg('devices/import', vm.detail, 'POST')
            .then(function(data) {
                if (data.status == 200) {
                    swal(
                        'Succès',
                        'Device ' + msg + ' avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {}
            }, function(error) {
                console.log(error);
            });
    };

    $scope.uploadedFile = function(element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function(event) {
            vm.detail.fichier = event.target.result

            $scope.$apply(function() {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    }

}
export default {
    name: 'deviceController',
    fn: deviceController
};