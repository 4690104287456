function googlemapsDirective($compile, NgMap) {
    'ngInject';
    return {
        restrict: 'E',
        compile: function (telems, tattrs) {
            return function (scope, ielems, iattrs) {
                var center    = iattrs.center;
                var langitude = iattrs.langitude;
                var altitude  = iattrs.altitude;    
                var id        = iattrs.id;
                var childNode = $compile(
                    '<ng-map id="map'+id+'" default-style="false" style="height:500px;" zoom="11" center="[40.74, -74.18]">'+
                        '<marker position="[40.74, -74.18]" title="how" animation="Animation.BOUNCE"></marker>'+
                    '</ng-map>')(scope);
                ielems.append(childNode);
                $('#idgooglemaps'+id).on('show.bs.modal', function (e) {
                       NgMap.getMap().then(function(map) {
                            var center = map.getCenter();
                            google.maps.event.trigger(map, "resize");
                            map.setCenter(center);
                        });
                    });
                $('#idgooglemaps'+id).on('shown.bs.modal', function (e) {
                       NgMap.getMap().then(function(map) {
                            var center = map.getCenter();
                            google.maps.event.trigger(map, "resize");
                            map.setCenter(center);
                        });
                    });
            }
        }
    };
}

export default {
    name: 'googlemaps',
    fn: googlemapsDirective
};
