function rolesController(DatatableLang, $translate, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.roles = {};

    vm.get = function() {
        WS.get('roles')
            .then(function(response) {
                if (response.status == 200) {
                    vm.roles = response.data;
                    $scope.$apply();
                    $('.table').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.update = function(role) {
        WS.put('roles/' + role.id, role)
            .then(function(response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('role modifié avec Succès'),
                        'success'
                    )
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer ce role!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: $translate.instant('Oui'),
            cancelButtonText: $translate.instant('Non'),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('roles/' + id).then(
                function() {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('role Supprimé avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer ce role'),
                        'warning'
                    );
                }
            );
        }, function(error) {
            console.log(error);
        });
    };

    vm.ajouter = function(role) {
        WS.post('roles', role)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('role ajouté avec Succès'),
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal($translate.instant('Erreur'), $translate.instant(response.data.message), 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
}
export default {
    name: 'rolesController',
    fn: rolesController
};