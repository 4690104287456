function marqueController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.marques = {};
    vm.marques_remise = {};
    vm.get = function() {
        WS.get('marques', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.marques = response.data;
                    $scope.$apply();
                    $('.table_marques').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });

    }
    vm.update = function(marque) {
        WS.put('marques/' + marque.id, marque)
            .then(function(response) {
                if (response.status == 200) {


                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette marque!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('marques/' + id).then(
                function() {
                    swal(
                        'Supprimée!',
                        'marque Supprimée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette marque',
                        'warning'
                    );
                }
            );
        });
    };

    vm.ajouter = function(marque) {
        WS.post('marques', marque)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'marque ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else {
                    swal(
                        'Error', response.data.message,
                        'error'
                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



}
export default {
    name: 'marqueController',
    fn: marqueController
};