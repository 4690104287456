function groupeController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.groupes = {};

    vm.get = function() {
        WS.get('groupes', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.groupes = response.data;
                    $scope.$apply();
                    $('.table_groupes').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.selectProduit = function() {
        WS.get('produits/selectProduit').then(
            function(response) {
                vm.selectproduit = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    }

    vm.getAffectaions = function() {
        WS.get('groupes/affectations', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.affectations = response.data;
                    $scope.$apply();
                    $('.table_affectations').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }


    vm.getProduitsByGroupe = function(code) {
        WS.get('groupes/produits/' + code).then(
            function(response) {
                vm.getproduits = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.update = function(groupe) {
        WS.put('groupes/' + groupe.id, groupe)
            .then(function(response) {
                if (response.status == 200) {

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.updateAffectations = function(groupe) {
        WS.put('groupes/affectations/' + groupe.id, groupe)
            .then(function(response) {
                if (response.status == 200) {

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.delete = function(id) {
        swal({
            title: 'groupe',
            text: "voulez vous vraiment supprimer ce groupe!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('groupes/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'groupe supprimé avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer ce groupe!!',
                        'warning'
                    );
                }
            );
        });
    }

    vm.deleteAffectation = function(id) {
        swal({
            title: 'Affectation',
            text: "voulez vous vraiment supprimer cette Affectation!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('groupes/affectations/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Affectation supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette Affectation!!',
                        'warning'
                    );
                }
            );
        });
    }


    vm.ajouterAffectations = function(groupe) {
        WS.post('groupes/affectations', groupe)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'affectations ajouté avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

    vm.ajouter = function(groupe) {
        WS.post('groupes', groupe)
            .then(function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'groupe ajouté avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    }

}
export default {
    name: 'groupeController',
    fn: groupeController
};